<template>
  <div>
    <v-btn
      :readonly="isGuest"
      outlined
      class="search-condition-btn px-1"
      :class="{ 'search-condition-selected-btn': selectedItemIds.length === 0 }"
      elevation="0"
      small
      @click="clickAll()"
    >
      {{ $t('all') }}
    </v-btn>
    <v-btn
      v-for="item in items"
      :key="item.label"
      :readonly="isGuest"
      disabled="disabled"
      outlined
      class="search-condition-btn px-1 mr-1"
      :class="{
        'search-condition-selected-btn': selectedItemIds.includes(item.id),
      }"
      elevation="0"
      small
      @click="clickItem(item.id)"
    >
      {{ item.label }}
    </v-btn>
    <GuestAlertDialog :message="guestAlertMessage" ref="guestAlert" />
  </div>
</template>

<script>
import GuestAlertDialog from "~/components/pages/dialog/GuestAlertDialog.vue";
export default {
  components: {
    GuestAlertDialog,
  },
  data() {
    return {
      dialog: false,
      isGuest: false,
      items: [],
      selectedItemIds: [],
      guestAlertMessage: '',
    };
  },
  props: {
    values: {
      type: Array, // [{id: 1, label: '千葉'}]
      default: [],
    },
    selectedValues: {
      type: Array,
      default: [],
    },
  },
  watch: {
    selectedValues(val) {
      this.selectedItemIds = val;
    },
  },
  created() {
    this.isGuest = !this.$store.state.auth.loggedIn;
    this.items = this.values;
    this.selectedItemIds = this.selectedValues;
  },
  methods: {
    clickAll() {
      if (this.isGuest) {
        this.isGuestDialog(this.$t('login_text_2'));
        return;
      }
      this.selectedItemIds = [];
      this.$emit('action', this.selectedItemIds);
    },
    clickItem(itemId) {
      if (this.isGuest) {
        this.isGuestDialog(this.$t('login_text_2'));
        return;
      }
      if (_.includes(this.selectedItemIds, itemId)) {
        this.selectedItemIds = this.selectedItemIds.filter(
          (_itemId) => _itemId !== itemId
        );
      } else {
        this.selectedItemIds.push(itemId);
        // すべてのボタンが選択されたら「すべて」が選択される
        if (this.selectedItemIds.length === this.items.length)
          this.selectedItemIds = [];
      }
      this.$emit('action', this.selectedItemIds);
    },
  },
};
</script>
