<template>
  <SearchConditionTagsContainer>
    <!-- 期間 --->
    <TagTermType
      v-if="selectedTermType"
      :termBtnData="termTypeItems"
      :termType="selectedTermType"
      :loading="loading"
      @updateTermType="changeTermType"
    />
  </SearchConditionTagsContainer>
</template>

<script>
import _ from 'lodash';
export default {
  data() {
    return {
    }
  },
  computed: {
    termTypeItems: function() {
      return [
        { id: 1, label: this.$t('weekly') },
        { id: 2, label: this.$t('monthly') },
        { id: 3, label: this.$t('yesterday') },
        { id: 4, label: this.$t('cumulative_total') },
      ];
    },
  },
  created() {
    
  },
  props: {
    selectedTermType: {
      type: Number
    },
    loading: {
      type: Boolean
    }
  },
  methods: {
    changeTermType(termType) {
      this.$emit('selectTerm', termType);
      this.$emit('updateSearch');
    }
  }
}
</script>