<template>
  <v-dialog v-model="castProfileDialog" max-width="500" fullscreen persistent no-click-animation transition="fade-transition" :retain-focus="false">
    <v-card>
      <div class="mx-auto" style="max-width:500px;">
        <CommonHeader style="position:sticky;top:0;z-index:10;" />
        <CastProfile v-if="this.$store.state.bindCast.cast.length != 0" style="height:100%;padding-bottom:0px !important;margin-bottom:0px !important;background-color:var(--main-bg-color) !important;padding-top: 40px !important;" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
const moment = require("moment");
import CommonHeader from '~/components/layouts/CommonHeader.vue';
import CastProfile from '~/pages/cast/_id.vue';
import _ from 'lodash'

export default {
  components: {
    CommonHeader,
    CastProfile,
  },
  computed: {
    castProfileDialog() {
      return this.$store.state.castDialog.selectedCastProfileDialog;
    }
  }
}
</script>
