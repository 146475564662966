<template>
  <div id="point-container" class="pa-2" style="position: relative;">
    <div class="point-title mb-5">ポイント還元中</div>
    <!-- 各種ポイント設定 -->
    <div class="mb-5">
      <!-- キャスト -->
      <div
        v-if="Object.keys(castPointSetting).length"
        class="point-setting pa-3 mb-5 pb-2"
        :key="index"
        v-for="(castPoint, index) in castPointSetting"
      >
        <div class="d-flex align-center mb-4">
          <div class="point-set-code mr-5 py-1">キャスト</div>
          <div class="point-set-description">{{ castPoint.site_cast_name }}さんを指名のお客様に</div>
        </div>
        <div class="d-flex align-center mb-4">
          <div class="mr-4 point-set-code-icon-box">
            <IconCast2 class="point-set-code-icon"/>
          </div>
          <IconPoint class="mr-3 icon-point" style="fill: #E4C73F;" />
          <div class="point-present-2">ポイントプレゼント！<IconRadiation class="icon-radiation" /></div>
        </div>
        <div
          v-if="castPoint.as_designate_flag == 0"
          class="point-concurrent-use pa-1 pb-0"
        >
          ※本指名（2回目以降のご指名）は対象外
        </div>
        <div class="point-concurrent-use pa-1">
          {{ concurrentUseText(castPoint.concurrent_use_flag) }}
        </div>
        <div class="target-description">
          <div
            :key="index+'_'+index2"
            v-for="(castData, index2) in castPoint.settings"
          >
            <div
              class="d-flex align-center py-1"
              style="border-bottom: 1px solid #FFF;"
            >
              <div class="mr-8" style="width: 100%;">
                【対象】{{ $moment(index2).format('M/D') }}のご利用
              </div>
            </div>
            <div
              :key="index2+'_'+index3"
              v-for="(cast, index3) in castData"
            >
              <div
                class="d-flex align-center py-1"
                style="border-bottom: 1px solid #FFF;"
              >
                <div class="mr-8" style="width: 60%;">
                  　{{ cast.course_name }}
                </div>
                <div class="d-flex align-center" style="width: 40%;">
                  <IconPoint class="mr-2 icon-point-small" style="fill: #E4C73F;" />
                  <div>{{ pointDisplay(cast.point) }}P</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 新規 -->
      <div class="point-setting pa-3 mb-5" v-if="firstTimeShopUsePointSetting.length" :class="{ 'pb-2': !firstTimeShopUsePointSetting[0].end_date }">
        <div class="d-flex align-center mb-4">
          <div class="point-set-code mr-5 pa-1">新規</div>
          <div class="point-set-description">当店を初めてご利用のお客様に</div>
        </div>
        <div class="d-flex align-center mb-4">
          <div class="mr-4 point-set-code-icon-box">
            <IconNew2 class="point-set-code-icon"/>
          </div>
          <IconPoint class="mr-3 icon-point" style="fill: #E4C73F;" />
          <div class="point-present-2">ポイントプレゼント！<IconRadiation class="icon-radiation" /></div>
        </div>
        <div
          v-if="(JSON.parse(firstTimeShopUsePointSetting[0].giving_point_conditions)).as_designate_point == 1"
          class="point-concurrent-use pa-1 pb-0"
        >
          ※本指名（2回目以降のご指名）は対象外
        </div>
        <div class="point-concurrent-use pa-1">
          {{ concurrentUseText(firstTimeShopUsePointSetting[0].concurrent_use_flag) }}
        </div>
        <div class="target-description">
          <div
            :key="firstTime.id"
            v-for="firstTime in firstTimeShopUsePointSetting"
            class="d-flex align-center py-1"
            style="border-bottom: 1px solid #FFF;"
            v-if="firstTime.course_name"
          >
            <div class="mr-8" style="width: 60%;">
              【対象】{{ firstTime.course_name }}
            </div>
            <div class="d-flex align-center" style="width: 40%;">
              <IconPoint class="mr-2 icon-point-small" style="fill: #E4C73F;" />
              <div>{{ pointDisplay(firstTime.point) }}P</div>
            </div>
          </div>
        </div>
        <div class="point-expiry-date pa-1" v-if="endDateDisplayFlag(firstTimeShopUsePointSetting[0].end_date)">
          このキャンペーンは{{ endDateDisplay(firstTimeShopUsePointSetting[0].end_date) }}までにご利用いただいた場合のみ 還元されます。ご予約日ではございませんのでご注意ください
        </div>
      </div>
      <!-- フリー -->
      <div class="point-setting pa-3 mb-5" v-if="newFreeReservePointSetting.length" :class="{ 'pb-2': !newFreeReservePointSetting[0].end_date }">
        <div class="d-flex align-center mb-4">
          <div class="point-set-code mr-5 py-1">フリー</div>
          <div class="point-set-description">指名なしでご利用のお客様に</div>
        </div>
        <div class="d-flex align-center mb-4">
          <div class="mr-4 point-set-code-icon-box">
            <IconFree class="point-set-code-icon"/>
          </div>
          <IconPoint class="mr-3 icon-point" style="fill: #E4C73F;" />
          <div class="point-present-2">ポイントプレゼント！<IconRadiation class="icon-radiation" /></div>
        </div>
        <div class="point-concurrent-use pa-1">
          {{ concurrentUseText(newFreeReservePointSetting[0].concurrent_use_flag) }}
        </div>
        <div class="target-description">
          <div
            :key="newFreeReserve.id"
            v-for="newFreeReserve in newFreeReservePointSetting"
            class="d-flex align-center py-1"
            style="border-bottom: 1px solid #FFF;"
            v-if="newFreeReserve.course_name"
          >
            <div class="mr-8" style="width: 60%;">
              【対象】{{ newFreeReserve.course_name }}
            </div>
            <div class="d-flex align-center" style="width: 40%;">
              <IconPoint class="mr-2 icon-point-small" style="fill: #E4C73F;" />
              <div>{{ pointDisplay(newFreeReserve.point) }}P</div>
            </div>
          </div>
        </div>
        <div class="point-expiry-date pa-1" v-if="endDateDisplayFlag(newFreeReservePointSetting[0].end_date)">
          このキャンペーンは{{ endDateDisplay(newFreeReservePointSetting[0].end_date) }}までにご利用いただいた場合のみ 還元されます。ご予約日ではございませんのでご注意ください
        </div>
      </div>
      <!-- 早割 -->
      <div class="point-setting pa-3 mb-5" v-if="newDaysAgoPointSetting.length" :class="{ 'pb-2': !newDaysAgoPointSetting[0].end_date }">
        <div class="d-flex align-center mb-4">
          <div class="point-set-code mr-5 pa-1">早割</div>
          <div class="point-set-description">{{ parseGivingPointConditon(newDaysAgoPointSetting[0]).day }}日前までにご予約のお客様に</div>
        </div>
        <div class="d-flex align-center mb-4">
          <div class="mr-4 point-set-code-icon-box">
            <IconBefore class="point-set-code-icon"/>
          </div>
          <IconPoint class="mr-3 icon-point" style="fill: #E4C73F;" />
          <div class="point-present-2">ポイントプレゼント！<IconRadiation class="icon-radiation" /></div>
        </div>
        <div
          v-if="(JSON.parse(newDaysAgoPointSetting[0].giving_point_conditions)).as_designate_point == 1"
          class="point-concurrent-use pa-1 pb-0"
        >
          ※本指名（2回目以降のご指名）は対象外
        </div>
        <div class="point-concurrent-use pa-1">
          {{ concurrentUseText(newDaysAgoPointSetting[0].concurrent_use_flag) }}
        </div>
        <div class="target-description">
          <div
            :key="newDaysAgo.id"
            v-for="newDaysAgo in newDaysAgoPointSetting"
            class="d-flex align-center py-1"
            style="border-bottom: 1px solid #FFF;"
            v-if="newDaysAgo.course_name"
          >
            <div class="mr-8" style="width: 60%;">
              【対象】{{ newDaysAgo.course_name }}
            </div>
            <div class="d-flex align-center" style="width: 40%;">
              <IconPoint class="mr-2 icon-point-small" style="fill: #E4C73F;" />
              <div>{{ pointDisplay(newDaysAgo.point) }}P</div>
            </div>
          </div>
        </div>
        <div class="point-expiry-date pa-1" v-if="endDateDisplayFlag(newDaysAgoPointSetting[0].end_date)">
          このキャンペーンは{{ endDateDisplay(newDaysAgoPointSetting[0].end_date) }}までにご利用いただいた場合のみ 還元されます。ご予約日ではございませんのでご注意ください
        </div>
      </div>
      <!-- コース -->
      <div class="point-setting pa-3 mb-5" v-if="coursePointSetting.length" :class="{ 'pb-2': !coursePointSetting[0].end_date }">
        <div class="d-flex align-center mb-4">
          <div class="point-set-code mr-5 py-1">コース</div>
          <div class="point-set-description">特定のコースをご利用のお客様に</div>
        </div>
        <div class="d-flex align-center mb-4">
          <div class="mr-4 point-set-code-icon-box">
            <IconTime class="point-set-code-icon"/>
          </div>
          <IconPoint class="mr-3 icon-point" style="fill: #E4C73F;" />
          <div class="point-present-2">ポイントプレゼント！<IconRadiation class="icon-radiation" /></div>
        </div>
        <div
          v-if="(JSON.parse(coursePointSetting[0].giving_point_conditions)).as_designate_point == 1"
          class="point-concurrent-use pa-1 pb-0"
        >
          ※本指名（2回目以降のご指名）は対象外
        </div>
        <div class="point-concurrent-use pa-1">
          {{ concurrentUseText(coursePointSetting[0].concurrent_use_flag) }}
        </div>
        <div class="target-description">
          <div
            :key="course.id"
            v-for="course in coursePointSetting"
            class="d-flex align-center py-1"
            style="border-bottom: 1px solid #FFF;"
            v-if="course.course_name"
          >
            <div class="mr-8" style="width: 60%;">
              【対象】{{ course.course_name }}
            </div>
            <div class="d-flex align-center" style="width: 40%;">
              <IconPoint class="mr-2 icon-point-small" style="fill: #E4C73F;" />
              <div>{{ pointDisplay(course.point) }}P</div>
            </div>
          </div>
        </div>
        <div class="point-expiry-date pa-1" v-if="endDateDisplayFlag(coursePointSetting[0].end_date)">
          このキャンペーンは{{ endDateDisplay(coursePointSetting[0].end_date) }}までにご利用いただいた場合のみ 還元されます。ご予約日ではございませんのでご注意ください
        </div>
      </div>
      <!-- 新人 -->
      <div class="point-setting pa-3 mb-5" v-if="newCastPointSetting.length" :class="{ 'pb-2': !newCastPointSetting[0].end_date }">
        <div class="d-flex align-center mb-4">
          <div class="point-set-code mr-5 pa-1">新人</div>
          <div class="point-set-description">新人の指名でご利用のお客様に</div>
        </div>
        <div class="d-flex align-center mb-4">
          <div class="mr-4 point-set-code-icon-box">
            <IconNew3 class="point-set-code-icon"/>
          </div>
          <IconPoint class="mr-3 icon-point" style="fill: #E4C73F;" />
          <div class="point-present-2">ポイントプレゼント！<IconRadiation class="icon-radiation" /></div>
        </div>
        <div
          v-if="(JSON.parse(newCastPointSetting[0].giving_point_conditions)).as_designate_point == 1"
          class="point-concurrent-use pa-1 pb-0"
        >
          ※本指名（2回目以降のご指名）は対象外
        </div>
        <div class="point-concurrent-use pa-1 pt-0">
          {{ concurrentUseText(newCastPointSetting[0].concurrent_use_flag) }}
        </div>
        <div class="target-description">
          <div
            class="d-flex align-center py-1"
            style="border-bottom: 1px solid #FFF;"
            v-if="(JSON.parse(newCastPointSetting[0].giving_point_conditions)).count > 0"
          >
            <div class="mr-8" style="width: 100%;">
              【対象】入店から{{ (JSON.parse(newCastPointSetting[0].giving_point_conditions)).count }}回勤務までのキャスト
            </div>
          </div>
          <div
            class="d-flex align-center py-1"
            style="border-bottom: 1px solid #FFF;"
            v-if="(JSON.parse(newCastPointSetting[0].giving_point_conditions)).day > 0"
          >
            <div class="mr-8" style="width: 100%;">
              【対象】入店日から{{ (JSON.parse(newCastPointSetting[0].giving_point_conditions)).day }}日以内のキャスト
            </div>
          </div>
          <div
            :key="newCast.id"
            v-for="newCast in newCastPointSetting"
            class="d-flex align-center py-1"
            style="border-bottom: 1px solid #FFF;"
            v-if="newCast.course_name"
          >
            <div class="mr-8" style="width: 60%;">
              　{{ newCast.course_name }}
            </div>
            <div class="d-flex align-center" style="width: 40%;">
              <IconPoint class="mr-2 icon-point-small" style="fill: #E4C73F;" />
              <div>{{ pointDisplay(newCast.point) }}P</div>
            </div>
          </div>
        </div>
        <div class="point-expiry-date pa-1" v-if="endDateDisplayFlag(newCastPointSetting[0].end_date)">
          このキャンペーンは{{ endDateDisplay(newCastPointSetting[0].end_date) }}までにご利用いただいた場合のみ 還元されます。ご予約日ではございませんのでご注意ください
        </div>
      </div>
      <!-- 日時 -->
      <div class="point-setting pa-3 mb-5" v-if="newTimeRangePointSetting.length" :class="{ 'pb-2': !newTimeRangePointSetting[0].end_date }">
        <div class="d-flex align-center mb-4">
          <div class="point-set-code mr-5 pa-1">日時</div>
          <div class="point-set-description">特定の日時にご利用のお客様に</div>
        </div>
        <div class="d-flex align-center mb-4">
          <div class="mr-4 point-set-code-icon-box">
            <IconWatch class="point-set-code-icon"/>
          </div>
          <IconPoint class="mr-3 icon-point" style="fill: #E4C73F;" />
          <div class="point-present-2">ポイントプレゼント！<IconRadiation class="icon-radiation" /></div>
        </div>
        <div
          v-if="(JSON.parse(newTimeRangePointSetting[0].giving_point_conditions)).as_designate_point == 1"
          class="point-concurrent-use pa-1 pb-0"
        >
          ※本指名（2回目以降のご指名）は対象外
        </div>
        <div class="point-concurrent-use pa-1">
          {{ concurrentUseText(newTimeRangePointSetting[0].concurrent_use_flag) }}
        </div>
        <div class="target-description">
          <div
            :key="newTimeRange.id"
            v-for="(newTimeRange, index) in newTimeRangePointSetting"
            v-if="newTimeRange.point && parseGivingPointConditon(newTimeRange).startTime && parseGivingPointConditon(newTimeRange).endTime"
          >
            <div
              class="d-flex align-center py-1"
              style="border-bottom: 1px solid #FFF;"
              v-if="!newTimeRangePointSetting[index-1] || parseGivingPointConditon(newTimeRange).time_range_num != parseGivingPointConditon(newTimeRangePointSetting[index-1]).time_range_num"
            >
              <div class="mr-8" style="width: 100%;">
                【対象】{{ newTimeRange.point_valid_date }}　
                {{ parseGivingPointConditon(newTimeRange).startTime }}～{{ parseGivingPointConditon(newTimeRange).endTime }}のご利用
              </div>
            </div>
            <div
              class="d-flex align-center py-1"
              style="border-bottom: 1px solid #FFF;"
            >
              <div class="mr-8" style="width: 60%;">
                　{{ newTimeRange.course_name }}
              </div>
              <div class="d-flex align-center" style="width: 40%;">
                <IconPoint class="mr-2 icon-point-small" style="fill: #E4C73F;" />
                <div>{{ pointDisplay(newTimeRange.point) }}P</div>
              </div>
            </div>
          </div>
        </div>
        <div class="point-expiry-date pa-1" v-if="endDateDisplayFlag(newTimeRangePointSetting[0].end_date)">
          このキャンペーンは{{ endDateDisplay(newTimeRangePointSetting[0].end_date) }}までにご利用いただいた場合のみ 還元されます。ご予約日ではございませんのでご注意ください
        </div>
      </div>
      <!-- オプション -->
      <div class="point-setting pa-3" v-if="optionPointSetting.length" :class="{ 'pb-2': !optionPointSetting[0].end_date }">
        <div class="d-flex align-center mb-4">
          <div class="point-set-code mr-5 pa-1">OP</div>
          <div class="point-set-description">特定のオプションをご利用のお客様に</div>
        </div>
        <div class="d-flex align-center mb-4">
          <div class="mr-4 point-set-code-icon-box">
            <IconOption class="point-set-code-icon"/>
          </div>
          <IconPoint class="mr-3 icon-point" style="fill: #E4C73F;" />
          <div class="point-present-2">ポイントプレゼント！<IconRadiation class="icon-radiation" /></div>
        </div>
        <div class="point-concurrent-use pa-1">
          {{ concurrentUseText(optionPointSetting[0].concurrent_use_flag) }}
        </div>
        <div class="target-description pt-1 pb-1">
          <div>【対象】{{ serviceNames }}</div>
          <div class="pl-1">還元されるポイントはご予約画面にてご確認ください</div>
        </div>
        <div class="point-expiry-date pa-1" v-if="endDateDisplayFlag(optionPointSetting[0].end_date)">
          このキャンペーンは{{ endDateDisplay(optionPointSetting[0].end_date) }}までにご利用いただいた場合のみ 還元されます。ご予約日ではございませんのでご注意ください
        </div>
      </div>
    </div>
    <!-- ポイント注意書き -->
    <div class="point-notice pa-2">
      <div
        class="text-center mt-1"
        style="color: var(--main-color);font-weight: bold;font-size: var(--read-font-size-m);"
      >
        ポイントについて
      </div>
      <div class="mb-3" style="color: var(--main-color);font-weight: bold;font-size: var(--read-font-size-s);">
        ご利用金額の1%がポイントとして還元されます。また、特定の条件を満たした場合にポイントが還元されます。付与されたポイントは1ポイント1円としてご利用いただけます。
      </div>
      <div class="mb-1">※Tokyo Escort OTOMEからご予約された場合に限り還元されます</div>
      <div class="mb-1">※ご利用日の翌朝9時に還元されます</div>
      <div class="mb-1">※Tokyo Escort OTOME加盟店全店舗でTokyo Escort OTOMEからのご予約に限りご利用いただけます</div>
      <div class="mb-1">※還元日から365日を過ぎると消滅します</div>
      <div class="mb-1">※一度に利用できる上限はございませんが、現金決済につきましては1,000P単位でのご利用となります</div>
      <div>※ポイント還元時、利用時ともに他の割引等と併用はできません</div>
    </div>
  </div>
</template>

<script>
import IconPoint from '~/assets/img/icon-point.svg';
import IconOption from '~/assets/img/icon-option.svg';
import IconFree from '~/assets/img/icon-free.svg';
import IconNew2 from '~/assets/img/icon-new2.svg';
import IconNew3 from '~/assets/img/icon-new3.svg';
import IconTime from '~/assets/img/icon-time.svg';
import IconWatch from '~/assets/img/icon-watch.svg';
import IconBefore from '~/assets/img/icon-before.svg';
import IconRadiation from '~/assets/img/radiation.svg';
import IconCast2 from '~/assets/img/icon-cast2.svg';

export default {
  components: {
    IconPoint,
    IconOption,
    IconFree,
    IconNew2,
    IconNew3,
    IconTime,
    IconWatch,
    IconBefore,
    IconRadiation,
    IconCast2
  },
  data() {
    return {
      firstTimeShopUsePointSetting: [], // 新規
      coursePointSetting: [], // ロング
      newCastPointSetting: [], // 新人
      newDaysAgoPointSetting: [], // 早割
      newFreeReservePointSetting: [], // フリー
      newTimeRangePointSetting: [], // 日時
      optionPointSetting: [], // オプション
      castPointSetting: [], // キャスト
    }
  },
  props: {
    pointSettings: {
      type: Array,
      required: true
    }
  },
  computed: {
    serviceNames() {
      const pointSetting = this.pointSettings.filter((setting) => {
        return setting.point_set_code === 'option'
      });
      return pointSetting[0].serviceNames.join('／');
    },
    newFaceDescription() {
      const descriptions = [];
      if ( Number(this.parseGivingPointConditon(this.newCastPointSetting[0]).day)) {
        descriptions.push(`【対象】入店日から${this.parseGivingPointConditon(this.newCastPointSetting[0]).day}日以内`);
      }
      if ( Number(this.parseGivingPointConditon(this.newCastPointSetting[0]).count)) {
        let description = `入店から${this.parseGivingPointConditon(this.newCastPointSetting[0]).count}回勤務までのキャスト`;
        if (!Number(this.parseGivingPointConditon(this.newCastPointSetting[0]).day)) description = '【対象】' + description;
        descriptions.push(description);
      }
      return descriptions.join(' ／ ');
    }
  },
  created() {
    // ポイント設定セットから各種ポイント設定を設定
    this.initPointSettingData();
  },
  methods: {
    initPointSettingData() {
      this.firstTimeShopUsePointSetting = this.filterPointSetting('first_time_shop_use');
      this.coursePointSetting = this.filterPointSetting('course');
      this.newCastPointSetting = this.filterPointSetting('new_cast');
      this.newDaysAgoPointSetting = this.filterPointSetting('new_days_ago');
      this.newFreeReservePointSetting = this.filterPointSetting('new_free_reserve');
      this.newTimeRangePointSetting = this.filterPointSetting('new_time_range');
      this.optionPointSetting = this.filterPointSetting('option');
      this.castPointSetting = this.filterPointSetting('cast');
    },
    filterPointSetting(pointSetCode) {
      const pointSetting = this.pointSettings.filter((setting) => {
        return setting.point_set_code === pointSetCode;
      })[0];
      if (pointSetCode != 'cast') {
        return pointSetting.site_member_point_settings.length ? pointSetting.site_member_point_settings : [];
      } else {
        return pointSetting.point_cast_settings.length ? pointSetting.point_cast_settings : [];
      }
    },
    pointDisplay(point) {
      if (!point) return 0;
      return parseInt(point).toLocaleString();
    },
    endDateDisplayFlag(endDate) {
      if (this.$moment().format('YYYY-MM-DD') > this.$moment(endDate).subtract(1, 'months').format('YYYY-MM-DD')) {
        return true;
      } else {
        return false;
      }
    },
    endDateDisplay(endDate) {
      const date = new Date(endDate);
      return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
    },
    concurrentUseText(flag) {
      if (flag == 0) {
        return `※合算不可（一番高く設定されているポイントが還元されます）`;
      } else {
        return `※合算可（他の「合算可」のポイントと合算されます）`;
      }
    },
    parseGivingPointConditon(pointSetting) {
      return JSON.parse(pointSetting.giving_point_conditions);
    },
  }
}
</script>

<style lang="scss" scoped>
#point-container {
  box-sizing: border-box;
  .point-title {
    height: 57px;
    text-align: center;
    line-height: 57px;
    background: var(--main-color);
    color: #FFF;
    font-size: 16px;
    font-weight: bold;
  }
  .point-setting {
    max-width: 500px;
    background: var(--color-4);
    border-radius: 10px;
    .point-set-code {
      font-size: 10px;
      color: #FFF;
      text-align: center;
      border-radius: 5px;
      background: var(--sub-color);
      font-weight: bold;
      width: 45px;
    }
    .point-set-description {
      font-size: 14px;
      font-weight: bold;
      color: var(--color-2);
    }
    .point-set-code-icon-box {
      width: 40px;
      height: 36px;
      position: relative;
      .point-set-code-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .icon-point {
      width: 36px;
      height: 36px;
    }
    .icon-point-small {
      width: 11px;
      height: 11px;
    }
    .point {
      font-size: 36px;
      font-weight: bold;
      color: var(--main-color);
      min-width: 106px;
      text-align: center;
    }
    .point-present {
      position: relative;
      font-size: 12px;
      font-weight: bold;
      color: var(--main-color);
      .icon-radiation {
        position: absolute;
        top: -12px;
        right: 8px;
      }
    }
    .point-present-2 {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      color: var(--main-color);
      .icon-radiation {
        position: absolute;
        top: -14px;
        right: -10px;
      }
    }
    .target-description {
      background: var(--sub-color);
      font-size: 10px;
      font-weight: bold;
      color: #FFF;
    }
    .point-expiry-date {
      background: var(--color-5);
      font-size: 10px;
      font-weight: bold;
      color: var(--color-2);
    }
    .point-concurrent-use {
      font-size: 10px;
      font-weight: bold;
      color: var(--sub-color);
    }
  }
  .point-notice {
    border: 1px solid var(--main-color);
    font-size: 12px;
    font-weight: bold;
    line-height: 1.25rem;
    color: var(--color-2);
  }
}
</style>