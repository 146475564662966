<template>
  <div v-if="castScheduleLoading" class="pr-4">
    <loading-image align="center" />
  </div>
  <v-card-text v-else>
    <div v-if="this.scheduleData.length === 0" style="text-align: center">
      <span>{{ $t('no_attendance') }}</span>
    </div>
    <div v-else style="position: relative">
      <div v-if="isGuest" class="schedule-mask">
        <p>
          {{ $t('login_text_9') }}<br />
          {{ $t('text_v5tz') }}<br /><br />
          <v-btn
            block
            color="primary"
            v-on:click="toLogin()"
            elevation="0"
            height="45"
            style="font-weight: bold"
          >
            <v-icon class="pr-2">mdi-lock</v-icon>{{ $t('login_register') }}
          </v-btn>
        </p>
      </div>
      <v-simple-table
        dense
        fixed-header
        :height="height"
        width="100%"
        class="prof-sche__table"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th
                :class="fixedLineClass"
                style="width: 16%; z-index: 9 !important"
              >
                {{ $t('date') }}
              </th>
              <th
                v-for="(day, index) in days"
                :key="index"
                style="width: 12%"
                :class="fixedLineClass"
              >
                {{ day | dayText }}
              </th>
            </tr>
          </thead>
          <tbody class="tbody-fixed-line">
            <tr>
              <th :class="fixedLineClass" style="z-index: 3 !important">
                {{ $t('reporting_for_work') }}
              </th>
              <td
                v-for="(day, key) in days"
                :key="key"
                :class="fixedLineClass"
                style="background-color: #fff"
              >
                <span v-if="isAttenance(day) === true">
                  {{ scheduleData[day]['start_datetime'] | formatTime }}
                  <div>↓</div>
                  {{ scheduleData[day]['real_end_datetime'] | formatTime }}
                </span>
              </td>
            </tr>
            <!-- <tr :class="isGuest ? 'disabled' : ''">
              <th :class="fixedColumnClass">キャンセル通知</th>
              <td v-for="(day, index) in days" :key="index">
                <span v-if="isAttenance(day) === true">
                  <span v-if="isReservationWaitlist(day) === false">
                    <v-btn
                      text
                      :class="reservationWaitBtnColor(day)"
                      v-on:click="reservationWait(day)"
                    >
                      申込
                    </v-btn>
                  </span>
                  <span v-if="isReservationWaitlist(day) === true">
                    <v-btn
                      text
                      class="cast-prof__reservation-waitlist-btn disabled"
                      disabled
                    >
                      申込中
                    </v-btn>
                  </span>
                </span>
                <span v-else>
                  <span></span>
                </span>
              </td>
            </tr> -->
            <tr
              v-for="(time, i) in times"
              :key="`day-${i}`"
              :class="isGuest ? 'disabled' : ''"
            >
              <th :class="fixedColumnClass">{{ time }}</th>
              <td v-for="(day, k) in days" :key="`time-${k}`">
                <span v-show="false">
                  {{ (result = checkFreeFrame(day, time)) }}
                </span>
                <span v-if="result.status === 1">
                  <ReserveButton
                    :castId="castInfo.cast_id"
                    :corporationId="castInfo.corporation_id"
                    :day="day"
                    :time="time"
                  ></ReserveButton>
                </span>
                <span v-else-if="result.status === 2">
                  <v-tooltip right max-width="260px" min-width="260px">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        text
                        icon
                        :class="reserveBtnColor(day, time)"
                        v-on="on"
                      >
                        <v-icon
                          style="padding-right: 1px; color: #fff !important"
                          >mdi-triangle-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <DispatchableAreasToolTip
                      :dispatchableAreas="result.address"
                    />
                  </v-tooltip>
                </span>
                <span v-else>
                  <v-icon small>mdi-window-close</v-icon>
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <!----- reservationWaitlistDialog START ----->
    <!--<v-dialog
      v-model="reservationWaitlistDialog"
      max-width="400"
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card
        v-if="days.length > 0 && castInfo"
        class="pa-3 text-center"
        style="font-weight: bold"
      >
        <div class="pa-3 text-center">キャンセル通知申し込み</div>
        <div class="pa-3 text-left">
          キャスト：{{ castInfo.site_cast_name }}さん<br />
          希望日　：{{ reservationWaitlistDate }}<br /><br />
          <v-textarea
            outlined
            auto-grow
            hide-details
            class="reservation_wait_request_area"
            rows="3"
            label="ご要望"
            placeholder="①ご案内時間②ご利用場所③プレイ時間をご入力ください。（入力必須）"
            v-model="reservationWaitlistRequest"
          />
        </div>
        <v-radio-group class="my-0" v-model="reservationWaitlistContactWays">
          <v-radio
            v-for="item in contactWays"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            style="font-weight: bold"
            class="px-3"
          ></v-radio>
          <div
            class="px-3"
            style="
              font-size: var(--read-font-size-s);
              font-weight: bold;
              color: var(--main-color);
            "
          >
            ※連絡を拒否したい時間がある場合はご要望欄にご入力ください
          </div>
        </v-radio-group>
        <div class="my-2 text-center">
          <v-btn
            class="my-2 px-4"
            color="primary"
            style="font-size: var(--read-font-size-m)"
            v-on:click="saveReservationWaitlist()"
            :disabled="disabledNextBtn"
          >
            送信する
          </v-btn>
        </div>
        <div class="px-3">
          <v-btn
            block
            small
            elevation="0"
            v-on:click="reservationWaitlistDialog = !reservationWaitlistDialog"
            style="color: var(--main-color); font-weight: bold"
            >{{ $t('close') }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>-->
    <!----- reservationWaitlistDialog END ----->
    <!----- reservationWaitlistMessageDialog START ----->
    <!--<v-dialog
      v-model="reservationWaitlistMessageDialog"
      max-width="400"
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card
        v-if="this.scheduleData.length > 0"
        class="pa-3 pt-0"
        style="font-weight: bold"
      >
        <div v-if="reservationWaitlistStatusCode == 200" class="pa-3">
          キャンセル通知申し込みを受け付けました。<br /><br />
          申し込み内容の確認や変更、キャンセルはキャンセル通知申し込み一覧ページからご確認いただけます。<br /><br />
          なお、キャンセル通知はキャンセルが出た際のご予約を保証するものではございません。通知後、すでに予約が埋まってしまっている場合もございますので予めご了承ください。
        </div>
        <div v-else class="pa-3">
          キャンセル通知申し込みの保存に失敗しました。<br /><br />
          お手数ですが再度お試しください。
        </div>
        <div class="px-3">
          <v-btn
            block
            small
            elevation="0"
            v-on:click="
              reservationWaitlistMessageDialog = !reservationWaitlistMessageDialog
            "
            style="color: var(--main-color); font-weight: bold"
            >{{ $t('close') }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>-->
    <!----- reservationWaitlistMessageDialog END ----->
    <!----- ReserveAlertDialog START ----->
    <ReserveAlertDialog />
    <!----- ReserveAlertDialog END ----->
  </v-card-text>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import ReserveButton from '~/components/pages/cast/ReserveButton.vue';
import ReserveAlertDialog from '~/components/pages/dialog/ReserveAlertDialog.vue';
import GuestAlertDialog from '~/components/pages/dialog/GuestAlertDialog.vue';
import DispatchableAreasToolTip from '~/components/pages/cast/DispatchableAreasToolTip.vue';

export default {
  name: 'ReserveSchedule',
  props: [
    'castInfo',
    'freeTimeFrames',
    'availableAreas',
    'scheduleInfo',
    'reservationWaitlist',
    'reserveUrl',
    'castScheduleLoading',
    'shopAreaIds',
    'ngAreaFlag', // 自宅NG,自宅サブエリアNG,ホテルNG
    'ngReservableFlag',
    'defaultAddressNameArr',
    'height',
  ],
  components: {
    ReserveButton,
    ReserveAlertDialog,
    GuestAlertDialog,
    DispatchableAreasToolTip,
  },
  data() {
    return {
      isGuest: !this.$store.state.auth.loggedIn,
      checkResrvableDate: '',
      //reservationWaitlistDialog: false,
      //reservationWaitlistMessageDialog: false,
      //reservationWaitlistDate: '',
      //reservationWaitlistRequest: '',
      //reservationWaitlistContactWays: '',
      //reservationWaitlistStatusCode: '',
      //contactWays: [
      //  { label: '電話で連絡を希望する', value: 1 },
      //  { label: 'SMSで連絡を希望する', value: 2 },
      //  { label: 'メールで連絡を希望する', value: 3 },
      //  { label: 'チャットで連絡を希望する', value: 4 },
      //],
    };
  },
  computed: {
    scheduleData: function () {
      if (_.isEmpty(this.scheduleInfo.schdData)) {
        return [];
      } else {
        return this.scheduleInfo.schdData;
      }
    },
    /*
    disabledNextBtn: function() {
      return this.reservationWaitlistContactWays &&
        this.reservationWaitlistRequest
        ? false
        : true;
    },
    */
    fixedLineClass: function () {
      if (
        this.$ua._parsed.os == 'iPhone' &&
        Number(this.$ua._parsed.version.split('.')[0]) < 15
      ) {
        return 'fixed-line2';
      } else {
        return 'fixed-line';
      }
    },
    fixedColumnClass: function () {
      if (
        this.$ua._parsed.os == 'iPhone' &&
        Number(this.$ua._parsed.version.split('.')[0]) < 15
      ) {
        return 'fixed-column2';
      } else {
        return 'fixed-column';
      }
    },
    /**
     * 日付を取得
     */
    days: function () {
      const h = moment().hour();
      const today =
        h < 6
          ? moment().subtract(1, 'days').format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD');
      let days = [];
      for (let i = 0; i < 14; i++) {
        days[i] = moment(today).add(i, 'days').format('YYYY-MM-DD');
      }
      return days;
    },
    times: function () {
      if (this.scheduleData.length === 0) {
        return;
      }
      const array = Array.prototype.concat.apply(
        [],
        [...Array(24).keys()].map((v) => {
          return [
            String(v).padStart(2, '0') + ':' + '00',
            String(v).padStart(2, '0') + ':' + '10',
            String(v).padStart(2, '0') + ':' + '20',
            String(v).padStart(2, '0') + ':' + '30',
            String(v).padStart(2, '0') + ':' + '40',
            String(v).padStart(2, '0') + ':' + '50',
          ];
        })
      );
      const minTime = this.scheduleInfo['minTime'];
      const maxTime = this.scheduleInfo['maxTime'];
      const minHH = minTime.slice(0, 2);
      const maxHH = maxTime.slice(0, 2);
      if (Number(minHH) <= Number(maxHH)) {
        const times = array.filter((hm) => {
          if (minTime <= hm && hm <= maxTime) {
            return hm;
          }
        });
        return times;
      } else {
        const t1 = array.filter((hm) => {
          if (minTime <= hm) {
            return hm;
          }
        });
        const t2 = array.filter((hm) => {
          if (hm <= maxTime) {
            return hm;
          }
        });
        const times = t1.concat(t2);
        return times;
      }
    },
  },
  created: function () {},
  mounted: function () {},
  methods: {
    /*
    isReservationWaitRequestable: function(date, limitDays = 6) {
      const diffDays =
        moment().format('HH') < 6
          ? moment(date).diff(moment().add(-1, 'days'), 'days') + 1
          : moment(date).diff(moment(), 'days') + 1;
      return diffDays <= limitDays;
    },
    */
    /*
    reservationWait: function(date) {
      if (!this.isReservationWaitRequestable(date)) {
        this.$store.dispatch('call/reserveAlertDialog', true);
        return;
      }
      this.reservationWaitlistDate = moment(date).format('Y/M/D');
      this.reservationWaitlistDialog = true;
    },
    */
    /*
    reservationWaitBtnColor: function(date) {
      return this.isReservationWaitRequestable(date)
        ? 'cast-prof__reservation-waitlist-btn'
        : 'cast-prof__reservation-waitlist-gray-btn';
    },
    */
    /*
    saveReservationWaitlist() {
      this.$store.dispatch('loading/post', true);
      this.reservationWaitlistDialog = false;
      const postData = {
        email: this.$store.state.auth.user.email,
        corporation_id: this.castInfo.corporation_id,
        cast_id: this.castInfo.cast_id,
        date: this.reservationWaitlistDate,
        user_request: this.reservationWaitlistRequest,
        contact_ways: this.reservationWaitlistContactWays,
        cancel_flag: 0,
      };
      this.$axios
        .post('private/save-reservation-waitlist', postData, {
          headers: {
            Authorization: `${this.$auth.$storage.getUniversal(
              '_token.auth0',
              true
            )}`,
          },
        })
        .then((response) => {
          this.$store.dispatch('loading/post', false);
          if (response.data.statusCode == 200) {
            this.reservationWaitlistDate = '';
            this.reservationWaitlistRequest = '';
            this.reservationWaitlistContactWays = '';
            this.reservationWaitlistStatusCode = response.data.statusCode;
            this.reservationWaitlistMessageDialog = true;
            this.$emit('reloadSchedule');
          } else {
            this.reservationWaitlistMessageDialog = true;
          }
        })
        .catch((error) => {
          this.reservationWaitlistMessageDialog = true;
          this.$store.dispatch('loading/post', false);
        });
    },
    */
    reserveBtnColor: function (day, time) {
      // 到着日時 arrivalTimeValue
      let reserveBtnH = Number(time.slice(0, 2)); // 時間 0〜23
      let reserveBtnDate = moment(day);
      if (reserveBtnH < process.env.OPEN_TIME) {
        // 0時から6時の間は、日付を1日加算
        reserveBtnDate.add(1, 'days'); // 1日追加
      }
      // 予約日付と本日の日数の差を取得
      let diff = reserveBtnDate.diff(moment(), 'days') + 1;
      if (
        diff > 6 ||
        (diff === 6 && moment().hour() < Number(process.env.OPEN_TIME))
      ) {
        return 'cast-prof__guidance-area-gray-btn';
      } else {
        return 'cast-prof__guidance-area-btn';
      }
    },
    /**
     * 空き枠内に予約開始日時があるか
     * 予約の可能性のある枠情報を返す
     *  return
     *    1 ... ◯予約可
     *    2 ... △移動すれば予約可
     *    3 ... ✕予約不可
     *
     * [流れ]
     * 1.出勤なし → ☓ B1
     * 2.受付終了 → ☓ B2
     * 3.予約不可 → ☓ B3
     * 4.空き枠から明らかに外れている → ☓ B4 / ログアウトの場合: ◯
     * 5.検索されたエリアで予約可能  → ◯ M1
     * 6.仮に前のエリアで検索したとき予約可能　→ △ S1
     * 7.最後まで来た場合          → ☓ B5
     */
    checkFreeFrame: function (day, time) {
      ///////////////////////////
      // 出勤なし or 受付終了 「✕」
      ///////////////////////////
      // 1.出勤日以外はreturn 0
      if (this.isAttenance(day) === false) {
        return {
          status: 3,
          address: '',
          debugCode: 'B1',
        };
      }
      // 2.受付終了
      if (this.scheduleData[day]['reception_end_flag'] === 1) {
        return {
          status: 3,
          address: '',
          debugCode: 'B2',
        };
      }
      // 3.予約不可のとき
      if (this.ngReservableFlag === true) {
        // 自宅NG・自宅サブエリアNG・ホテルNGのとき
        return {
          status: 3,
          address: '',
          debugCode: 'B3',
        };
      }
      ///////////////////////////
      // 設定
      ///////////////////////////
      // 予約開始日時 checkDatetime
      const targetDatetime = day + ' ' + time + ':00';
      const hh = moment(targetDatetime).hour();
      const checkDatetime =
        hh < 6
          ? moment(targetDatetime).add(1, 'day').format('YYYY-MM-DD HH:mm:00')
          : targetDatetime;
      // スペシャルカード
      const specialCardMinute = this.castInfo.special_card_minute;
      // 準備時間
      const preparationMin = this.castInfo.reservation_preparation_minute;
      // 枠情報
      const frame = _.find(this.freeTimeFrames[day], (v) => {
        if (
          v.date === day &&
          v.adjust_start_datetime <= checkDatetime &&
          checkDatetime <= v.adjust_end_datetime
        ) {
          return v;
        }
      });
      ///////////////////////////
      // 4.【✕】空き枠から明らかに外れている。空き枠なし
      //   【◯】ログアウトの場合
      ///////////////////////////
      if (_.isUndefined(frame)) {
        return {
          status: this.isGuest ? 1 : 3,
          address: '',
          debugCode: 'B4',
        };
      }
      const isShopAreas =
        _.includes(
          this.shopAreaIds,
          this.$store.state.siteMemberData.area_ids
        ) === true
          ? true
          : false;
      const areaCheck =
        this.ngAreaFlag === false && isShopAreas === true ? true : false;
      ///////////////////////////
      // 5.【◯】
      ///////////////////////////
      const isReservable = this.isPossibleReservation(
        checkDatetime,
        frame.min_course_minute_shop_area,
        specialCardMinute,
        preparationMin,
        frame.start_datetime,
        frame.start_travel_minute,
        frame.start_category,
        frame.end_datetime,
        frame.end_travel_minute,
        frame.end_category,
        'debug1'
      );
      if (isReservable === true && areaCheck === true) {
        // ◯
        return {
          status: 1,
          address: '',
          debugCode: 'M1',
        };
      }
      ///////////////////////////
      // 6.【△】他エリアで予約可能
      ///////////////////////////
      const availableAreas = frame.shop_area_map
        .filter((v) => {
          const isOtherAreaReservable = this.isPossibleReservation(
            checkDatetime,
            v.min_course_minute,
            specialCardMinute,
            preparationMin,
            frame.start_datetime,
            v.start_travel_minute,
            frame.start_category,
            frame.end_datetime,
            v.end_travel_minute,
            frame.end_category,
            'debug1'
          );
          if (isOtherAreaReservable === true) {
            return v;
          }
        })
        .map((v) => {
          return v.search_area_id;
        });
      if (availableAreas.length > 0) {
        return {
          status: 2,
          address: availableAreas ? availableAreas : {},
          debugCode: 'S1',
        };
      }
      ///////////////////////////
      // 7.【☓】全てヒットしない場合
      ///////////////////////////
      return {
        status: this.isGuest ? 1 : 3,
        address: '',
        debugCode: 'B5',
      };
    },
    /**
     * 予約可能かチェック
     */
    isPossibleReservation: function (
      checkDatetime,
      minCourseMin,
      specialCardMinute,
      preparationMin,
      startDatetime,
      startTravelMin,
      startCategory,
      endDatetime,
      endTravelMin,
      endCategory,
      debugCode
    ) {
      const startFrameDatetime =
        startCategory === 'reservation'
          ? moment(startDatetime)
              .add(startTravelMin, 'minute')
              .add(preparationMin, 'minute')
              .format('YYYY-MM-DD HH:mm:00')
          : moment(startDatetime)
              .add(startTravelMin, 'minute')
              .format('YYYY-MM-DD HH:mm:00');
      const endFrameDatetime =
        endCategory === 'reservation'
          ? moment(endDatetime)
              .subtract(minCourseMin, 'minute')
              .subtract(specialCardMinute, 'minute')
              .subtract(endTravelMin, 'minute')
              .subtract(preparationMin, 'minute')
              .format('YYYY-MM-DD HH:mm:00')
          : moment(endDatetime)
              .subtract(minCourseMin, 'minute')
              .subtract(specialCardMinute, 'minute')
              .format('YYYY-MM-DD HH:mm:00');
      const isReservable = moment(checkDatetime).isBetween(
        startFrameDatetime,
        endFrameDatetime,
        null,
        []
      );
      return isReservable;
    },
    isAttenance: function (day) {
      const days = _.keys(this.scheduleData);
      const flag = _.includes(days, day);
      return flag;
    },
    isReservationWaitlist: function (day) {
      const reservationWait = _.find(
        this.reservationWaitlist,
        (v) => v.date === day
      );
      return !_.isUndefined(reservationWait);
    },
    getAvailableAreas(checkDatetime, start_datetime) {
      const diff = moment(checkDatetime).diff(start_datetime, 'minutes');
      const filtered = _.filter(this.availableAreas, function (o) {
        return o.minute <= diff;
      });
      return _.groupBy(filtered, 'prefecture_name');
    },
    getAreaName(address) {
      if (!address) return;
      const result = address.split(' ');
      return result[result.length - 1];
    },
  },
  watch: {},
  filters: {
    dayText: function (date) {
      moment.locale('en');
      // 曜日は一旦非表示
      //return moment(date).format('D(ddd)');
      return moment(date).format('M/D');
    },
    formatTime: function (datetime) {
      return moment(datetime).format('HH:mm');
    },
  },
};
</script>

<style lang="scss">
tr.disabled {
  opacity: 0.2;
}

.schedule-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;
  display: flex;
  justify-items: center;
  align-items: center;

  p {
    width: 80%;
    padding: 16px;
    background: var(--main-bg-color);
    margin: auto;
    text-align: center;
    border-radius: 4px;
    font-weight: bold;
  }
}
</style>

<style scoped>
/* 出勤スケジュール */
.prof-sche__table {
  width: 100%;
  font-size: var(--read-font-size-s);
  color: var(--read-font-color-sub);
  line-height: 1.2;
}
.prof-sche__table th,
.prof-sche__table td {
  min-height: 32px !important;
  min-width: 47px !important;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 1%;
  font-size: 3vw;
  border-right: 1px solid #f2f2f2;
}
@media screen and (min-width: 1025px) {
  .prof-sche__table th,
  .prof-sche__table td {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .prof-sche__table th,
  .prof-sche__table td {
    font-size: 2vw;
  }
}
.prof-sche__table th {
  border-bottom: solid 1px #e6e5e5;
  color: var(--read-font-color-sub) !important;
}
.v-application--is-ltr .v-data-table th {
  text-align: center;
}
.v-data-table td,
.v-data-table th {
  padding: 0.2rem 0 !important;
  text-align: center;
  color: var(--read-font-color-sub);
}
.theme--light.v-data-table tbody tr:not(:last-child) td:last-child,
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row) {
  border-bottom: 1px solid #f2f2f2;
}
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #fff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12);
  background: var(--main-bg-color);
}
.v-data-table--dense th {
  height: 32px;
  background: var(--main-bg-color);
}
.prof-sche__table >>> div.v-data-table__wrapper {
  border: 0px !important;
}
.fixed-line,
.fixed-column,
.fixed-line2,
.fixed-column2 {
  position: -webkit-sticky;
  position: sticky;
  top: 0px !important;
  left: 0px !important;
}
.tbody-fixed-line >>> .fixed-line {
  top: 32px !important;
}
.tbody-fixed-line >>> .fixed-line2 {
  top: 0px !important;
}
.fixed-line,
.fixed-line2 {
  z-index: 2;
}
.fixed-column,
.fixed-column2 {
  z-index: 2;
}
.cast-prof__guidance-area-btn {
  background: linear-gradient(#ff99a8, #ff8093) !important;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.15),
    inset 0px 0px 2px rgba(255, 255, 255, 0.5), 0px 0px 6px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}
.cast-prof__guidance-area-gray-btn {
  background: linear-gradient(#999999, #808080) !important;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.15),
    inset 0px 0px 2px rgba(255, 255, 255, 0.5), 0px 0px 6px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}
.cast-prof__reservation-waitlist-btn {
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  min-width: 0 !important;
  width: 90% !important;
  height: 98% !important;
  padding: 1px !important;
  background: linear-gradient(#ff7d99, #B22C27) !important;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.15),
    inset 0px 0px 2px rgba(255, 255, 255, 0.5), 0px 0px 6px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}
.cast-prof__reservation-waitlist-gray-btn {
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  min-width: 0 !important;
  width: 90% !important;
  height: 98% !important;
  padding: 1px !important;
  background: linear-gradient(#999999, #808080) !important;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.15),
    inset 0px 0px 2px rgba(255, 255, 255, 0.5), 0px 0px 6px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}
.reservation_wait_request_area {
  width: 98%;
}
.disabled >>> span {
  color: #fff !important;
  font-weight: normal !important;
}
.v-tooltip__content {
  border-radius: 7px !important;
  background: #000000b3 !important;
}
</style>
