<template>
  <div>
    <v-avatar 
      :width="width" 
      :height="height" 
      :min-width="minWidth" 
      :min-height="minHeight"
    >
      <v-img 
        contain
        :src="imageUrl" 
        :style="styleObject" 
      />
    </v-avatar>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 40,
    },
    height: {
      type: [Number, String],
      default: 40,
    },
    minWidth: {
      type: [Number, String],
      default: 40,
    },
    minHeight: {
      type: [Number, String],
      default: 40,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    borderWidth: {
      type: [Number, String],
      default: 2
    },
    borderColor: {
      type: String,
      default: '#858ac4'
    }
  },
  computed: {
    styleObject() {
      return {
        border: `${String(this.borderWidth)}px solid ${this.borderColor}`
      };
    }
  }
}
</script>