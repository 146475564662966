<template>
  <v-btn
    text
    icon
    v-on:click="reserve();"
    :disabled="this.$store.state.call.reserveBtnStatus"
    :class="reserveBtnColor"
  >
    <div v-if="loading">
      <v-progress-circular
        :size="20"
        color="#fff"
        indeterminate
      />
    </div>
    <div v-else>
      <v-icon color="#fff" style="padding-right:1px;">mdi-checkbox-blank-circle-outline</v-icon>
    </div>
  </v-btn>
</template>

<script>
import moment from 'moment'
export default {
  data: () => ({
    loading: false,
  }),
  props: [
    'castId',
    'corporationId',
    'day',
    'time',
  ],
  computed: {
    reserveBtnColor: function () {
      // 到着日時 arrivalTimeValue
      let reserveBtnH = Number(this.time.slice(0, 2)) // 時間 0〜23
      let reserveBtnDate = moment(this.day);

      // 予約日付と本日の日数の差を取得
      let diff = reserveBtnDate.diff(moment(), "days") + 1;
      if (moment().format("HH") < 6) {
        diff = reserveBtnDate.diff((moment().add(-1, "days")), "days") + 1;
      }
      if (diff > 6) {
        return 'cast-prof__reserve-gray-btn';
      } else {
        return 'cast-prof__reserve-btn';
      }
    },
  },
  methods: {
    reserve: function () {
      // 到着日時 arrivalTimeValue
      let dd = this.day // 日付 YYYY-MM-DD
      let h = Number(this.time.slice(0, 2)) // 時間 0〜23
      let arrivalDate = moment(this.day);

      // 予約日付と本日の日数の差を取得
      let diff = arrivalDate.diff(moment(), "days") + 1;
      if (moment().format("HH") < 6) {
        diff = arrivalDate.diff((moment().add(-1, "days")), "days") + 1;
      }
      //予約を6日前までに制限する
      if (diff > 6) {
        this.$store.dispatch('call/reserveAlertDialog', true);
        return;
      }

      if (h < process.env.OPEN_TIME) {
        // 0時から6時の間は、日付を1日加算
        arrivalDate.add(1, "days"); // 1日追加
        dd = arrivalDate.format("YYYY-MM-DD")
      }

      this.loading = true;
      // storeに保存
      let tempArrivalTimeValue = this.$store.state.call.arrivalTimeValue
      let tempArrivalTimeLabel = this.$store.state.call.arrivalTimeLabel

      this.$store.dispatch('call/reserveBtnStatus', true);
      this.$store.dispatch('call/nominateCast', this.castId);
      this.$store.dispatch('call/selectedCorporationId', this.corporationId);
      this.$store.dispatch('call/arrivalDateTimeValue', dd + ' ' + this.time + ':00');
      this.$store.dispatch('call/arrivalTimeValue', 0);
      this.$store.dispatch('call/arrivalTimeLabel', '');
      this.$store.dispatch('call/profileScheduleUseFlag', true); // プロフィールのスケジュールから日付指定したかどうか(/callでクリア))
      this.$store.dispatch('call/tempArrivalTimeValue', tempArrivalTimeValue); // 一時退避
      this.$store.dispatch('call/tempArrivalTimeLabel', tempArrivalTimeLabel); // 一時退避

      if (this.$store.state.castDialog.selectedCastProfileDialog) {
        // 予約モーダル
        this.loading = false;
        this.$store.dispatch('castDialog/selectedCastReserveDialog', true);
      } else {
        // リダイレクト
        this.$router.push(`/${this.$i18n.locale}/call/step2`);
      }
    },
  }
}
</script>

<style scoped>
.cast-prof__reserve-btn {
  background: linear-gradient(#FF7D99, #B22C27);
  box-shadow: inset 0px 0px 2px rgba(0,0,0,0.3), inset 0px 0px 2px rgba(255,255,255,1), 0px 0px 6px rgba(0,0,0,0.15);
  border-radius: 4px;
}
.cast-prof__reserve-gray-btn {
  background: linear-gradient(#999999, #808080);
  box-shadow: inset 0px 0px 2px rgba(0,0,0,0.3), inset 0px 0px 2px rgba(255,255,255,1), 0px 0px 6px rgba(0,0,0,0.15);
  border-radius: 4px;
}
</style>