import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticStyle:{"z-index":"9999"},attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"text-center"},[_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"pt-10 pb-5",attrs:{"cols":"12"}},[_c('h1',{staticClass:"h1"},[_vm._v(_vm._s(_vm.$t('age_verification')))])]),_vm._v(" "),_c(VCol,[_c('img',{attrs:{"src":require("assets/img/18kin.png"),"width":"20%","alt":""}})]),_vm._v(" "),_c(VCol,{attrs:{"cols":"12"}},[_c('h2',{staticClass:"h2 h2-text"},[_vm._v(_vm._s(_vm.$t('over18')))])]),_vm._v(" "),_c(VCol,{staticClass:"pt-5 pb-10",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"mr-1 btn-no subtitle-1",attrs:{"dark":"","rounded":"","color":"#777777","width":"40%"},on:{"click":function($event){return _vm.close()}}},[_c('b',[_vm._v(_vm._s(_vm.$t('no'))),_c('span',{staticClass:"caption"},[_vm._v("（"+_vm._s(_vm.$t('exit'))+"）")])])]),_vm._v(" "),_c(VBtn,{staticClass:"ml-1 subtitle-1 btn-enter",attrs:{"dark":"","rounded":"","width":"50%"},on:{"click":function($event){return _vm.enter()}}},[_c('b',[_vm._v(_vm._s(_vm.$t('yes'))),_c('span',{staticClass:"caption"},[_vm._v("（"+_vm._s(_vm.$t('enter'))+"）")])])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }