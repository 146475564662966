import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"d-flex align-center follow__btn",style:({
    'backgroundColor': _vm.is_follow_ ? '#B22C28' : '#fff',
    'color': !_vm.is_follow_ ? '#171C61' : '#fff',
    'borderColor': _vm.is_follow_ ? '#B22C28' : '#171C61',
    'display': _vm.isMySelf ? 'none !important' : 'block'
  }),on:{"click":function($event){$event.stopPropagation();return _vm.follow()}}},[(_vm.is_follow_ === true)?_c('span',[_vm._v("\n    フォロー中\n  ")]):_vm._e(),_vm._v(" "),(_vm.is_follow_ === false)?_c('span',[_vm._v("\n    "+_vm._s(_vm.is_followed ? 'フォローバックする' : 'フォローする')+"\n  ")]):_vm._e(),_vm._v(" "),_c('GuestAlertDialog',{ref:"guestAlert",attrs:{"message":_vm.guestAlertMessage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }