<template>
  <div>
    <div
      class="d-flex align-center justify-center cast-image-profile-container"
      style="border-radius: 10px"
    >
      <v-img
        v-if="cast"
        cover
        :height="imageHeight"
        :max-height="imageMaxHeight"
        :min-height="imageMinHeight"
        style="border-radius: 10px 10px 0 0"
        :src="
          defaultImage(
            cast.thumbnail_url,
            cast.shop_default_thumbnail_set_name,
            castRankingPublicFlag
          )
        "
        v-on:click="toCastProfile(cast)"
      >
        <v-overlay v-if="!castEnrolledFlag" opacity="0.6" absolute>
          <div class="px-1" style="font-size: 0.875rem; font-weight: bold">
            <div class="text-center">{{ $t('cast_left') }}</div>
          </div>
        </v-overlay>
        <v-row
          align="end"
          class="new-cast-list__text ma-0 px-3 pb-2"
          v-if="castRankingPublicFlag == 1"
        >
          <!-- <div
            v-if="
              (cast.debut_date &&
                debutDateWithin90DaysOr14DaysLater(cast.debut_date)) ||
              (cast.cast_evaluation_score_total.debut_date &&
                debutDateWithin90DaysOr14DaysLater(
                  cast.cast_evaluation_score_total.debut_date
                ))
            "
            class="mb-1"
            style="width: 100%"
          >
            <span
              style="
                font-size: var(--read-font-size-xs);
                padding: 0 3px;
                color: #000;
                background-color: #fff;
                border-radius: 3px;
              "
              >{{ $t('new') }}</span
            >
            &nbsp;&nbsp;&nbsp;
            <span
              v-if="cast.debut_date"
              style="font-size: var(--read-font-size-xs)"
              >{{ debutDate(cast.debut_date) }}</span
            >
            <span
              v-else-if="cast.cast_evaluation_score_total.debut_date"
              style="font-size: var(--read-font-size-xs)"
              >{{
                debutDate(cast.cast_evaluation_score_total.debut_date)
              }}</span
            >
          </div> -->
          <!-- <div
            class="d-flex aling-center"
            style="
              width: 100%;
              line-height: var(--read-font-size-m);
              letter-spacing: 0.5px;
            "
            v-if="
              cast.display_cast_schedules_count <= 10 ||
              cast.cast_schedules_count <= 10
            "
          >
            <IconBeginner class="mr-1" style="fill: #fff; width: 12px" />
            <span
              v-if="cast.display_cast_schedules_count <= 10"
              style="padding-top: 5px"
            >
              {{ $t('attendance_count')
              }}{{ cast.display_cast_schedules_count }}
            </span>
            <span
              v-else-if="cast.cast_schedules_count <= 10"
              style="padding-top: 5px"
            >
              {{ $t('attendance_count') }}{{ cast.cast_schedules_count }}
            </span>
          </div> -->
          <div style="width: 100%; line-height: 16px">
            {{ cast.site_cast_name }}&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('age') }}
            {{ cast.age }}
          </div>
          <div style="width: 100%; line-height: 16px">
            T{{ cast.height }}&nbsp;&nbsp;&nbsp;&nbsp;{{ cast.bust }}({{
              cupList[cast.cup]
            }})-{{ cast.waist }}-{{ cast.hip }}
          </div>
        </v-row>
      </v-img>
      <!-- <div
        class="cast-image-profile-bottom"
        :class="{ 'cast-image-profile-bottom-radius': !cast.point_type }"
        style="position: relative"
      > -->
      <div
        class="cast-image-profile-bottom cast-image-profile-bottom-radius"
        style="position: relative"
      >
        <div v-if="castRankingPublicFlag == 1">
          <div style="margin-top: 2px; color: var(--read-font-color-primary)">
            <!-- <div style="line-height: 12px">{{ cast.shop_name }}</div> -->
            <div style="line-height: 12px">
              {{ cast.min_course_minute }}{{ $t('min') }} &yen;{{
                cast.min_course_money.toLocaleString()
              }}～
            </div>
          </div>
          <!-- <div class="mt-2">
            <span
              style="padding: 1px 6px; color: #fff; border-radius: 3px"
              :style="{
                backgroundColor:
                  cast.business_type_id === 1
                    ? '#54d1f0'
                    : cast.business_type_id === 2
                    ? '#E4C76A'
                    : cast.business_type_id === 3
                    ? '#ff96b7'
                    : '#ccc',
              }"
            >
              {{ $t(`business_types.${cast.business_type_id}.name`) }}
            </span>
          </div> -->
          <div class="like-box">
            <CastLikeButton2
              :castId="cast.cast_id"
              :isFavorite="
                this.$store.state.siteMemberData.followCastIds.includes(
                  cast.cast_id
                )
                  ? true
                  : false
              "
              :siteMemberFollowCount="cast && cast.site_member_follow_casts_count 
                ? cast.site_member_follow_casts_count 
                : 0"
            />
          </div>
        </div>
        <div v-else class="top-secret">
          <div class="top-secret__inner pt-1 pb-1">{{ $t('top_secret') }}</div>
        </div>
      </div>
      <!-- <CastPointCard
        v-if="cast.point_type && !pointCardNotDisplay"
        :pointType="cast.point_type"
        :point="cast.point"
        :castEnrolledFlag="castEnrolledFlag"
      /> -->
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title>
            {{ $t('cannot_display_private') }}
          </v-card-title>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import CastLikeButton2 from '~/components/layouts/CastLikeButton2.vue';
// import CastPointCard from '~/components/layouts/CastPointCard.vue';
import IconBeginner from '~/assets/img/icon-beginner.svg';
import noimage_m from '~/assets/img/noimage_m.png';
import ngimage_m from '~/assets/img/ngimage_m.png';

export default {
  data: () => ({
    noimage_m: noimage_m,
    ngimage_m: ngimage_m,
    dialog: false,
  }),
  components: {
    CastLikeButton2,
    // CastPointCard,
    IconBeginner,
  },
  props: {
    cast: {
      type: Object,
      required: true,
    },
    castRankingPublicFlag: {
      type: [Boolean, Number],
      default: true,
    },
    executeParentMethod: {
      type: [Boolean, Number],
      default: false,
    },
    castEnrolledFlag: {
      type: [Boolean, Number],
      default: true,
    },
    imageHeight: {
      type: [Number, String],
      default: 'auto',
    },
    imageMinHeight: {
      type: [Number, String],
      default: 240,
    },
    imageMaxHeight: {
      type: [Number, String],
      default: 315,
    },
    pointCardNotDisplay: {
      type: [Boolean, Number],
      default: false,
    },
  },
  computed: {
    cupList: function () {
      return this.$store.state.call.cupList;
    },
  },
  mounted: function () {},
  methods: {
    toCastProfile(cast) {
      if (!this.castRankingPublicFlag) {
        this.dialog = true;
        return;
      }
      if (this.executeParentMethod) {
        this.$emit('toCastProfileDialog');
        return;
      }
      this.toCastProfileDialog(cast);
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .new-cast-list__text {
    font-size: var(--read-font-size-m) !important;
  }
}
.cast-image-profile-container {
  flex-direction: column;
  box-shadow: 0px 0px 3px 0 #00000029;
  max-width: 230px;
  ::v-deep .v-overlay--active {
    touch-action: auto !important;
  }
  .cast-image-profile-bottom-radius {
    border-radius: 0 0 10px 10px;
  }
  .cast-image-profile-bottom {
    padding: 6px 8px;
    font-size: var(--read-font-size-xs);
    font-weight: bold;
    background-color: #fff;
    width: 100%;
    position: relative;
    height: 70px;
    .like-box {
      position: absolute;
      right: 6px;
      bottom: 10px;
    }
    .top-secret {
      position: absolute;
      width: 90%;
      border: 1px solid #333333;
      padding: 2px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-5deg);
      .top-secret__inner {
        text-align: center;
        background: #333333;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}
</style>
