<template>
  <v-dialog fullscreen no-click-animation transition="fade-transition" max-width="500" v-model="persons.length > 0">
    <v-card style="border-radius:0px;">
      <v-list subheader class="pa-0" style="position:fixed;top:0;width:100%;z-index:3;line-height:2rem;box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);">
        <v-list-item class="pr-2 list_style" style="background-color:#fff;border-bottom: 1px solid #ddd;">
          <v-row class="ma-0 justify-space-between">
            <v-col cols="9" class="pa-0" style="color:var(--read-font-color-sub);">
              いいね一覧(全{{ persons.length }}件)
            </v-col>
            <v-col cols="3" class="pa-0 text-right">
              <v-btn small text v-on:click="$emit('close')">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>

      <div style="padding-top:46px;" class="mx-2">
        <div class="pa-1" v-for="(person, index) in filterdPersons" :key="index" style="border-bottom: 1px solid #eee;">

          <!-- 会員 -->
          <div v-if="person.site_member_id">
            <v-list-item v-on:click="clickSiteMember(person)" v-ripple="{ class: `red--text text--lighten-4` }" class="px-1">
              <AvatarImage
               :imageUrl="person&&person.image_filename 
                ? `${AWS_S3_URL}site_members/${person.id}/mypage/photo/${person.image_filename}`
                : profileDefaultImage"
              />
              <span v-if="!person.site_member_deleted_at && person.nickname" class="ml-2" style="color:var(--main-color);font-weight:bold;">
                {{ person.nickname }}
              </span>
              <span v-else-if="!person.site_member_deleted_at && !person.nickname" class="ml-2" style="color:var(--read-font-color-sub);font-weight:bold;">
                ニックネーム未設定
              </span>
              <span v-else class="ml-2" style="color:var(--read-font-color-sub);font-weight:bold;">
                退会済みユーザー
              </span>
            </v-list-item>
          </div>

          <!-- キャスト -->
          <div v-else-if="person.liked_talent_id">
            <v-list-item v-on:click="clickCast(person)" v-ripple="{ class: `red--text text--lighten-4` }" class="px-1">
              <v-avatar size="40">
                <v-img
                  v-if="person.thumbnailUrl&&person.shop_default_thumbnail_set&&person.shop_default_thumbnail_set.set_name"
                  :src="defaultImage(person.thumbnailUrl, person.shop_default_thumbnail_set.set_name)" v-on:error="person.thumbnailUrl=profile_image"
                />
                <v-img v-else :src="profile_image" />
              </v-avatar>
              <span v-if="person.enroll_flag == 1 && person.active_flag == 1 && person.site_public_flag == 1 && person.site_member_site_public_flag === 1 && person.site_cast_name && !person.shop" class="ml-2" style="color:var(--main-color);font-weight:bold;">
                {{ person.name }} - {{ person.site_cast_name }}
              </span>
              <span v-else-if="person.enroll_flag == 1 && person.active_flag == 1 && person.site_public_flag == 1 && person.site_member_site_public_flag === 1 && person.site_cast_name && person.shop" class="ml-2" style="color:var(--main-color);font-weight:bold;">
                {{ person.shop.name }} - {{ person.site_cast_name }}
              </span>
              <span v-else-if="person.site_cast_name" class="ml-2" style="color:var(--read-font-color-sub);font-weight:bold;">
                {{ person.site_cast_name }}
              </span>
              <span v-else-if="person.nickname" class="ml-2" style="color:var(--read-font-color-sub);font-weight:bold;">
                {{ person.nickname }}
              </span>
            </v-list-item>
          </div>

        </div>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
import profileDefaultImage from '~/assets/img/profile.jpg';
import profile_image from '~/assets/img/noimage_p.png';
import profile_ngImage from '~/assets/img/ngimage_p.png';
import AvatarImage from '~/components/pages/mypage/AvatarImage.vue';

export default {
  components: {
    AvatarImage
  },
  props: [
    'persons',
    'urlHash'
  ],
  computed:{
    filterdPersons() {
      return this.persons.filter((v) => v.site_member_id || (v.shop && v.shop.inbound_site_public_flag === 1))
    }
  },
  data() {
    return {
      AWS_S3_URL: process.env.AWS_S3_URL,
      profileDefaultImage: profileDefaultImage,
      profile_image: profile_image,
      profile_ngImage: profile_ngImage,
      castDialog: this.$store.state.castDialog.selectedCastProfileDialog,
    };
  },
  methods: {
    defaultImage(thumbnailUrl, defaultImageName) {
      if(!thumbnailUrl){
        if(defaultImageName.search('ng') == -1){
          return this.profile_ngImage;
        }else{
          return this.profile_image;
        }
      }else{
        return thumbnailUrl;
      }
    },
    clickSiteMember(person) {
      if(person.nickname && person.site_member_deleted_at === null && !this.castDialog) {
        this.openSiteMemberDialog(person.site_member_id);
      }else if(person.nickname && person.site_member_deleted_at === null && this.castDialog) {
        this.toCastProfileTransition(this.urlHash, person, 'siteMember');
      }
    },
    clickCast(person) {
      if(person.enroll_flag == 1 && person.active_flag == 1 && person.site_public_flag == 1 && person.site_member_site_public_flag === 1 && !this.castDialog) {
        this.toCastProfileDialog(person);
      }else if(person.site_member_site_public_flag === 1 && this.castDialog) {
        this.toCastProfileTransition(this.urlHash, person, 'cast');
      }
    },
  }
}
</script>
