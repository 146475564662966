<template>
  <div
    v-ripple
    class="d-flex align-center follow__btn"
    :style="{
      'backgroundColor': is_follow_ ? '#B22C28' : '#fff',
      'color': !is_follow_ ? '#171C61' : '#fff',
      'borderColor': is_follow_ ? '#B22C28' : '#171C61',
      'display': isMySelf ? 'none !important' : 'block'
    }"
    v-on:click.stop="follow()"
  >
    <span v-if="is_follow_ === true">
      フォロー中
    </span>
    <span v-if="is_follow_ === false">
      {{ is_followed ? 'フォローバックする' : 'フォローする' }}
    </span>
    <GuestAlertDialog :message="guestAlertMessage" ref="guestAlert" />
  </div>
</template>

<script>
import GuestAlertDialog from "~/components/pages/dialog/GuestAlertDialog.vue";

export default {
  components: {
    GuestAlertDialog
  },
  props: {
    site_member_id: {
      type: [Number, String],
      required: true
    },
    is_follow: {
      type: [Number, Boolean],
      default: false,
    },
    is_followed: {
      type: [Number, Boolean],
      default: false,
    }
  },
  data(){
    return {
      is_follow_: this.is_follow,
      guestAlertMessage: '',
    }
  },
  created(){
    // this.is_follow_ = this.is_follow;
  },
  methods: {
    follow: function () {
      if (this.$store.state.auth.loggedIn === false) {
        this.isGuestDialog($t('login_text_5'));
        return;
      }
      if (this.isMySelf) {
        return;
      }
      if (!this.is_follow_ && this.$store.state.siteMemberData.followSiteMemberLimit) {
        this.$store.dispatch('dialog/saveFollowSiteMemberLimitDateDialogFlag', true);
        return;
      }

      this.is_follow_ = !this.is_follow_;
      /**
       * 新着クチコミstore: bindCast.newCastReviews をアップデートする
       */
       const newCastReviews = this.updateSiteMemberFollowBindCasts(
        this.$store.state.bindCast.newCastReviews,
        this.site_member_id,
        this.is_follow_
      );
      this.$store.dispatch('bindCast/commitNewCastReviews', newCastReviews);
      // Ajax DB更新
      this.$axios.post('private/following-user', {
        'email': this.$store.state.auth.user.email,
        'followSiteMemberId': this.site_member_id,
        'isFollow': this.is_follow_,
      }, {
        headers: {
          Authorization: `${this.$auth.$storage.getUniversal('_token.auth0', true)}`
        }
      })
      .then (response => {
        if (response.data.completeFlag) {
          this.$store.dispatch("siteMemberData/saveFollowSiteMemberLimit", {
            followSiteMemberLimit: response.data.followSiteMemberLimit
          });
        } else if (response.data.res == 'no_follow'
          && response.data.followSiteMemberLimit
        ) {
          this.$store.dispatch('dialog/saveFollowSiteMemberLimitDateDialogFlag', true);
        }
      })

    },

  },
  computed: {
    isMySelf() {
      return this.site_member_id === this.$store.state.siteMemberData.id;
    }
  }
}
</script>

<style scoped>
.follow__btn {
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  height: 1.5rem;
  width: 110px;
  padding: 0 5px;
  font-size: var(--read-font-size-s);
  font-weight:bold;
  border-radius: 1.8rem;
  border: solid 1.5px var(--main-color);
}
</style>