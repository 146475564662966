<template>
  <div style="background: var(--sub-bg-color);">
    <h3 class="d-flex align-center" style="padding:15px 20px;">
      <span style="font-size: 16px;font-weight: bold;color: var(--main-color);"
        >{{ $t('text_jdc1') }}
        </span>
    </h3>

    <!-- ジャンル -->
    <!-- <div class="px-4 py-2">
      <SearchConditionBtns
        :values="genreItems"
        :selectedValues="selectedGenres"
        @action="selectGenre"
      />
    </div> -->
    <RankingSlider
      :casts="this.$store.state.ranking.rankingCast1"
      type="1"
      className="ranking-cast1"
      :loading="loading"
      :loadingComplete="loadingComplete"
      :paginationLoading="paginationLoading"
      @getMoreData="getRankings(true)"
    />
    <!-- 検索ダイアログ -->
    <!-- <v-bottom-sheet
      v-model="dialog"
      persistent
      no-click-animation
      scrollable
      style="position:relative;"
    >
      <v-card style="padding-bottom: 120px;">
        <div
          class="cross-mark cross-mark-in-modal"
          @click="dialog = false"
        ></div>
        <v-card-text class="pa-0" style="height: 100%">
          <div class="px-4 mb-6">
            <div
              class="my-3 d-flex align-center search-condition-head-text mb-5"
            >
              <v-icon size="25" color="#161B60" class="mr-2">mdi-magnify</v-icon
              >{{ $t('specify_search') }}
            </div>
            <div class="checkbox-conditions">
              <v-checkbox
                v-model="freeTimeExistsFlag"
                :disabled="isGuest"
                color="#B22C28"
                :label="$t('text_lmc8')"
              />
            </div>
          </div>
          <SearchActionNav
            @action1="clearAllConditions()"
            @action2="updateSearch()"
            :btnText2="$t('text_dfl5')"
          />
        </v-card-text>
      </v-card>
    </v-bottom-sheet> -->

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import RankingSlider from '~/components/pages/home/RankingSlider.vue';
import SearchConditionBtns from '~/components/pages/common/SearchConditionBtns.vue';
//import SearchActionNav from '~/components/pages/common/SearchActionNav.vue';

import CastRankingMixin from '~/mixins/cast-rankin-mixin';

export default {
  components: {
    RankingSlider,
    SearchConditionBtns,
    //SearchActionNav,
  },
  mixins: [CastRankingMixin],
  data() {
    return {
      loading: false,
      paginationLoading: false,
      shopLoading: false,
      dialog: false,
      selectedGenres: [],
      selectedAreas: [],
      selectedShop: null,
      freeTimeExistsFlag: false,
      shopItems: [],
      isGuest: false,
      guestAlertMessage: '',
      perPage: 10,
    };
  },
  async created() {
    this.selectedGenres = this.copyDeep(this.selectedGenresDesination);
    this.selectedAreas = this.copyDeep(this.selectedAreasDesination);
    this.selectedShop = this.copyDeep(this.selectedShopDesination);
    this.freeTimeExistsFlag = this.freeTimeExists;
    this.isGuest = !this.$store.state.auth.loggedIn;
  },
  computed: {
    ...mapState('ranking', [
      'selectedGenresDesination',
      'selectedAreasDesination',
      'selectedShopDesination',
      'freeTimeExists',
      "page1"
    ]),
    loadingComplete() {
      return this.page1 > 3;
    },
    genreItems: function() {
      return [
        { id: 1, label: this.$t('business_types.1.name') },
        { id: 2, label: this.$t('business_types.2.name') },
        { id: 3, label: this.$t('business_types.3.name') },
      ];
    },
  },
  watch: {
    // selectedGenres: function() {
    //   this.getRankings();
    // },
  },
  methods: {
    ...mapActions('ranking', [
      'saveSelectedGenresDesination',
      'saveSelectedAreasDesination',
      'saveSelectedShopDesination',
      'saveFreeTimeExists',
      "commitPage1",
      "saveRankingCast1Data",
    ]),
    getRankings(isPaginate = false) {
      if (!isPaginate) {
        this.loading = true;
        this.commitPage1(1);
        this.saveRankingCast1Data([]);
      } else {
        this.paginationLoading = true;
      }
      this.getCastRanking(1, {
        rankingType: 1,
        termType: 3,
        genreIds: this.selectedGenres,
        prefectureIds: this.selectedAreas,
        shopIds: this.pluck(this.selectedShops, 'id'),
        freeTimeExists: this.freeTimeExistsFlag,
        siteMemberId: this.$store.state.siteMemberData.id,
        siteMemberGrdadeId: this.$store.state.siteMemberData.gradeId,
        loggedIn: this.$store.state.auth.loggedIn,
        page: this.page1,
        perPage: this.perPage,
      }).then(() => {
        this.loading = false;
        this.paginationLoading = false;
      });
    },
    selectGenre(ids) {
      this.selectedGenres = this.copyDeep(ids);
      this.getShopItems();
    },
    async getShopItems() {
      this.shopLoading = true;
      this.getSelectBoxShopData(this.selectedGenres, this.selectedAreas).then(
        (results) => {
          this.shopItems = results;
          this.shopLoading = false;
        }
      );
    },
    clearAllConditions() {
      this.selectedGenres = [];
      this.selectedAreas = [];
      this.selectedShop = null;
      this.freeTimeExistsFlag = false;
      this.getShopItems();
    },
    updateSearch() {
      this.dialog = false;
      this.getRankings();
      this.saveSelectedGenresDesination(this.copyDeep(this.selectedGenres));
      this.saveSelectedAreasDesination(this.copyDeep(this.selectedAreas));
      this.saveSelectedShopDesination(this.copyDeep(this.selectedShop));
      this.saveFreeTimeExists(this.freeTimeExistsFlag);
      this.updateOrGetSearchItems(true, 'Desination');
    },
  },
};
</script>

<style lang="scss" scoped>

.checkbox-conditions {
  ::v-deep .v-messages {
    display: none !important;
  }
  ::v-deep .v-label {
    font-size: 0.875rem !important;
    font-weight: bold !important;
    color: var(--read-font-color-primary) !important;
  }
  ::v-deep .v-input--selection-controls {
    margin: 0 !important;
    padding: 0 !important;
  }
}
</style>
