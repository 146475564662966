<template>
  <div>
    <v-btn
      text
      icon
      large
      class="cast-url-copy-btn"
      @click="copyUrl()"
    >
      <IconCopy />
    </v-btn>
    <v-dialog v-model="dialog">
      <v-card class="pa-4" style="font-weight: bold">
        {{ $t('copy_to_clipboard') }}
        <div class="text-center">
          <v-btn
            @click="dialog = false"
            class="mt-6"
            color="primary"
            style="font-weight: bold"
            >{{ $t('close') }}</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import IconCopy from '~/assets/img/icon-copy.svg';

export default {
  components: {
    IconCopy,
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    copyUrl() {
      const uri = new URL(window.location.href);
      const copyUrlText = uri.origin + '/cast/' + this.$store.state.bindCast.cast.url_hash;
      navigator.clipboard.writeText(copyUrlText);
      this.dialog = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.cast-url-copy-btn {
  background: #fff;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}
</style>