<template>
  <div class="ranking-container">
    <div
      class="pa-2 pt-3"
      style="margin-bottom:2px;background:var(--sub-bg-color)"
    >
      <h2 class="mx-3 mt-3 mb-1 d-flex align-center">
        <IconCrown class="mr-2" style="fill: var(--main-color)" />
        <span
          style="font-size: 22px; font-weight: bold; color: var(--main-color)"
          >{{ $t('text_ovu4') }}</span
        >
      </h2>
    </div>
    <div>
      <DesinationRanking ref="desination" />
      <FavoriteRanking ref="favorite" />
      <!-- <PhotoDiaryRanking ref="photoDiary" /> -->
    </div>
  </div>
</template>

<script>
import DesinationRanking from '~/components/pages/home/CastRanking/DesinationRanking.vue';
import FavoriteRanking from '~/components/pages/home/CastRanking/FavoriteRanking.vue';
// import PhotoDiaryRanking from '~/components/pages/home/CastRanking/PhotoDiaryRanking.vue';
import IconCrown from '~/assets/img/icon-crown.svg';

import CastRankingMixin from '~/mixins/cast-rankin-mixin';

export default {
  components: {
    IconCrown,
    DesinationRanking,
    FavoriteRanking,
    // PhotoDiaryRanking,
  },
  data: () => ({
    readonlyGuest: false,
    initialLoading: true,
  }),
  mixins: [CastRankingMixin],
  mounted() {
    if (this.$store.state.auth.loggedIn === false) {
      this.readonlyGuest = true;
      return;
    }
  },
  methods: {
    getCastRankingData: async function() {
      const apis = [
        this.updateOrGetSearchItems(false, 'Desination'),
        this.updateOrGetSearchItems(false, 'Favorite'),
        // this.updateOrGetSearchItems(false, 'PhotoDiary'),
      ];
      await Promise.all(apis).then(() => {
        this.initialLoading = false;
      });
      this.$refs['desination'].getRankings();
      this.$refs['favorite'].getRankings();
      // this.$refs['photoDiary'].getRankings();
      this.$store.dispatch(
        'ssr/timeToGetCastRankings',
        this.$moment().format('YYYY-MM-DD HH:mm:ss')
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.ranking-container {
  ::v-deep .v-select__selection--comma {
    color: var(--main-title-color) !important;
    font-size: 13px;
  }
  ::v-deep .v-text-field__details {
    display: none !important;
  }
  ::v-deep .v-input__append-inner {
    margin-top: 6px !important;
  }
}
</style>
