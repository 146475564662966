<template>
  <!-- 写メ日記詳細ダイアログ -->
  <v-dialog v-model="articleDialog" fullscreen>
    <v-card style="border-radius: 0px" elevation="0">
      <v-card-title class="pl-2 py-2 prof-photo-diary__header">
        <v-icon
          size="1.5rem"
          color="#333"
          v-on:click="articleDialog = false"
          style="margin-top: 1px"
          >mdi-chevron-left
        </v-icon>
        <span
          class="pl-2"
          style="
            color: var(--main-color);
            font-size: var(--read-font-size-m);
            font-weight: bold;
          "
          ><span v-if="cast">{{ $t('ms') }}{{ cast.site_cast_name }} </span>{{ $t('diary') }}</span
        >
      </v-card-title>
      <div class="mx-auto" style="max-width: 450px; font-weight: bold">
        <div
          v-for="(article, index) in photoDiaryArticles"
          :key="index"
          class="pb-6"
        >
          <div :id="`photoId-${article.id}`" style="height: 46px"></div>
          <div class="image">
            <v-img
              width="100%"
              contain
              :src="article.attachment_image_url || article_image"
              @load="$emit('articleDialogImageLoad')"
            />
            <div :class="`scope ${getScopeClass(article.scope, true)}`">
              {{ $t('limited_publication') }}
              <span>{{ getScopeLabel(article.scope) }}</span>
            </div>
          </div>
          <!-- いいね -->
          <!-- <div v-if="!isOutScope(article.scope)" class="article-liked"> -->
          <!-- アイコン（タップでトグル） -->
          <!-- <v-icon
              class="pb-1"
              color="primary"
              v-if="article.like_flag"
              v-on:click="removeLikeArticle(article.id, index)"
            >
              mdi-heart
            </v-icon>
            <v-icon
              class="pb-1"
              v-else
              v-on:click="likeArticle(article.id, index)"
            >
              mdi-heart-outline
            </v-icon> -->
          <!-- 数 -->
          <!-- <span
              class="pl-1"
              style="font-weight: bold"
              v-on:click="onLikedSumClicked(article)"
            >
              {{ getLikedSum(article) }}
            </span> -->
          <!-- ニックネーム -->
          <!-- <span
              v-if="likedNicknames['articleId' + article.id]"
              class="pl-1"
              style="font-weight: bold"
            >
              <div
                v-for="(nickname, index) in likedNicknames[
                  'articleId' + article.id
                ]"
                :key="'nickname-' + index"
                style="display: inline-block"
              >
                <span
                  v-if="nickname.url_hash"
                  style="color: var(--main-color)"
                  v-on:click="
                    toCastProfileTransition(urlHash, nickname, 'cast')
                  "
                  >{{ nickname.name }}
                </span>
                <span
                  v-else-if="nickname.site_member_id"
                  style="color: var(--main-color)"
                  v-on:click="
                    toCastProfileTransition(urlHash, nickname, 'siteMember')
                  "
                  >{{ nickname.name }}
                </span>
                <span
                  v-else-if="nickname.likedPersonsDialog"
                  v-on:click="onLikedSumClicked(article)"
                  >{{ nickname.name }}</span
                >
                <span v-else>{{ nickname.name }}</span>
                <span v-if="likedNicknames['articleId' + article.id][index + 1]"
                  >、</span
                >
              </div>
            </span> -->
          <!-- </div> -->
          <!-- タイトル -->
          <!-- <div class="article-title">
            {{ article.subject }}
          </div> -->
          <!-- 本文 -->
          <!-- <div
            v-html="
              unescapeHtml(
                convertBodyContent(article.body, article.scope),
                true
              )
            "
            v-hashtag-handler="hashtagClicked"
            class="mx-4 usr-photo-diary__article-body"
            :style="{ maxHeight: article.unfolding_flag ? '100%' : '60px' }"
          />
            <div
              v-if="!article.unfolding_flag"
              v-on:click="articleUnfoldingFlag(index, 'true')"
              class="text-center"
              style="
                color: var(--main-color);
                font-size: var(--read-font-size-s);
                font-weight: bold;
              "
            >
            続きを読む
          </div> -->
          <v-row class="justify-space-between" no-gutters>
            <v-col class="pa-0 my-2"></v-col>
            <v-col class="pa-0 my-2 text-right">
              <div
                style="
                  color: var(--read-font-color-sub);
                  font-size: var(--read-font-size-s);
                  font-weight: bold;
                "
                class="mr-4"
              >
                {{ article.posted_at | photoDiaryArticleMoment(translateText) }}
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="py-10 text-center">
          <v-btn
            v-if="
              !photoDiaryArticleLoading &&
                !this.getPhotoDiaryArticleCompleteFlag
            "
            class="primary--text font-weight-bold"
            color="#fff"
            elevation="1"
            v-on:click="$emit('getPhotoDiaryArticle')"
            >{{ $t('more') }}</v-btn
          >
          <loading-image v-if="photoDiaryArticleLoading" />
        </div>
      </div>
    </v-card>
    <!-- いいねした人ダイアログ -->
    <PhotoDiaryLikedPersonsDialog
      :persons="likedPersons"
      v-on:close="likedPersons = []"
    />
    <GuestAlertDialog :message="guestAlertMessage" ref="guestAlert" />
  </v-dialog>
</template>

<script>
import article_image from '~/assets/img/photo_diary_noimage_p.jpg';
import PhotoDiaryLikedPersonsDialog from '~/components/pages/dialog/PhotoDiaryLikedPersonsDialog.vue';
import GuestAlertDialog from '~/components/pages/dialog/GuestAlertDialog.vue';
const moment = require('moment');
import _ from 'lodash';

export default {
  components: {
    PhotoDiaryLikedPersonsDialog,
    GuestAlertDialog,
  },
  props: [
    'photoDiaryArticles',
    'cast',
    'photoDiaryArticleLoading',
    'getPhotoDiaryArticleCompleteFlag',
    'hashtagCategories',
    'getScopeAnnotation',
    'urlHash',
    'likedNicknames',
  ],
  data: () => ({
    article_image: article_image,
    articleDialog: false,
    guestAlertMessage: '',
    /* いいねした人の配列 */
    likedPersons: [],
  }),
  computed: {
    translateText: function() {
      return {
        hourAgo: this.$t('hour_ago'),
        oneHourAgo: this.$t('one_hour_ago'),
      };
    },
  },
  created: function() {},
  mounted: function() {},
  methods: {
    onLikedSumClicked: function(article) {
      if (this.$store.state.auth.loggedIn === false) {
        this.isGuestDialog(this.$t('login_text_5'));
        return;
      }
      const {
        site_member_like_cast_photo_diary_article,
        talent_like_cast_photo_diary_article,
      } = article;
      const persons = []
        .concat(site_member_like_cast_photo_diary_article)
        .concat(talent_like_cast_photo_diary_article);
      if (persons.length > 0) {
        this.likedPersons = persons.sort((a, b) =>
          a.created_at > b.created_at ? 1 : -1
        );
      }
    },
  },
  filters: {
    photoDiaryArticleMoment: function(date, translateText) {
      if (
        moment(date).format('YYYY-MM-DD HH:mm:ss') >
        moment()
          .subtract(1, 'hours')
          .format('YYYY-MM-DD HH:mm:ss')
      ) {
        return translateText.oneHourAgo;
      } else if (
        moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
      ) {
        return moment().diff(date, 'hours') + ' ' + translateText.hourAgo;
      } else {
        return moment(date).format('M/D');
      }
    },
  },
};
</script>

<style>
h2 {
  color: var(--main-color);
}
.content-box-header {
  font-size: var(--read-font-size-m);
  line-height: var(--read-font-size-m);
  border-left: 0 !important;
}

/* プロフィール画像 */
.prof-photo__item {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  color: #fff;
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.02) 0%,
    rgba(0, 0, 0, 0.36) 56%,
    rgba(0, 0, 0, 0.65) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.02) 0%,
    rgba(0, 0, 0, 0.36) 56%,
    rgba(0, 0, 0, 0.65) 100%
  );
}
.prof-photo__page-back-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.prof-photo__page-back-button {
  width: 1.5rem;
  height: 1.5rem;
  background-color: rgba(100, 100, 100, 0.3);
  border-radius: 100%;
}
.prof-photo__text {
  font-size: var(--read-font-size-s);
  font-weight: bold;
}
.prof-photo__review-table {
  color: #fff;
  font-size: var(--read-font-size-s);
}
.prof-photo__review-table tr th {
  font-weight: bold;
}
.prof-photo__review-table td,
.prof-photo__review-table th {
  border: solid 1px;
  text-align: center;
  padding: 0 0.2rem;
}
.prof-photo__action-btn {
  background: #fff;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  margin: 0 0 0.5rem auto;
  position: relative;
  right: 1rem;
  position: absolute;
  right: 15px;
  bottom: 60px;
}

/* 予約ナビゲーション */
.cv-action-nav {
  padding-left: 1rem;
  padding-right: 1rem;
  position: fixed;
  bottom: 3.5rem;
  width: 100%;
  max-width: 1024px;
  background: rgba(255, 255, 255, 0.9);
  height: 4rem;
  z-index: 3;
  box-shadow: 0 -0.1rem 2px #e4e4e4;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
}
[class^='cv-action-nav__btn-'] {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  border-radius: 1.8rem;
  border: solid 1px;
  font-size: var(--read-font-size-m);
  width: 38%;
  height: 2rem;
}
[class^='cv-action-nav__btn-default'] {
  background: #fff;
  color: var(--read-font-color-primary);
}
.cv-action-nav__btn-default--fav {
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--main-color);
  border-color: var(--main-color);
  border: solid 1px;
}

/* 自己紹介 */
.prof-self-info__msg {
  font-size: var(--read-font-size-m);
  color: var(--read-font-color-primary);
  line-height: 1.6;
}

/* 基本情報 */
.prof-self-basic-info__item {
  font-size: var(--read-font-size-s);
  font-weight: bold;
}

/* おすすめポイント */
.prof-tag {
  height: 26px;
  width: 24%;
  margin: 0.5%;
  padding: 0 !important;
  font-size: 11px !important;
  font-weight: bold !important;
  display: inline-block;
  text-align: center;
  color: var(--read-font-color-sub);
}

.prof-tag-guide {
  margin: 8px auto;
  width: 70%;
  display: block;
  text-align: center;
  font-size: 10px;
}

.prof-tag-guide_border-gray {
  border-color: #ffcbd7 !important;
}

/* 写メ日記 */
.prof-photo-diary__header {
  position: fixed;
  height: 46px;
  width: 100%;
  z-index: 5;
  background-color: #fff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.prof-photo-diary__wrapper {
  border-radius: 0px;
  cursor: pointer;
}
.prof-photo-diary__subject {
  color: var(--read-font-color-sub);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.prof-photo-diary__list-btn {
  box-shadow: 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 2px 1px -2px rgba(0, 0, 0, 0.2) !important;
  color: var(--main-color);
  font-weight: bold;
}
.prof-cast-review__list-btn {
  width: 140px;
  height: 36px;
  padding: 0 16px;

  border-radius: 4px;
  box-shadow: 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 2px 1px -2px rgba(0, 0, 0, 0.2) !important;
  color: var(--main-color);
  background-color: #fff;
  font-weight: bold;
  cursor: default;
  font-size: var(--read-font-size-s);

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  /* margin-bottom: 15px; */
  letter-spacing: 0.0892857143em;
}

/* プレイデータ */
.prof-play-info__header {
  color: var(--read-font-color-primary);
  font-size: var(--read-font-size-m);
  font-weight: bold;
}
.prof-play-info__box {
  display: inline-block;
  width: 48%;
  margin: 1%;
  text-align: center;
  vertical-align: top;
  color: var(--read-font-color-sub);
}
.prof-play-info__box dt {
  font-size: var(--read-font-size-s);
  font-weight: bold;
  padding: 5px;
  border: solid 1px #d4d3cf;
  background: var(--main-bg-color);
  line-height: 1.5;
  border-radius: 7px 7px 0 0;
  color: #fff;
  background: linear-gradient(to right, #ffa4b7, #ff7796);
}
.prof-play-info__box dd {
  font-weight: bold;
  line-height: 1.5;
  padding: 5px;
  border: solid 1px #d4d3cf;
  border-top: none !important;
  background: #fff;
  border-radius: 0 0 7px 7px;
  color: var(--main-color);
}
.review_list-leave-active,
.review_list-enter-active {
  transition: opacity 0.5s, transform 0.5s ease;
}
.review_list-leave-to,
.review_list-enter {
  opacity: 0;
  transform: translateY(-50px);
}
.review_list-leave,
.review_list-enter-to {
  opacity: 1;
}
.review_list-move {
  transition: transform 0.5s;
}
.v-dialog__content--active >>> .image_view {
  margin: 0px !important;
}

/* お店詳細料金表 */
div.service_table >>> .v-data-table__wrapper {
  border: none !important;
}
div.service_table >>> table {
  border: 1px solid #ccc;
  border-collapse: separate !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-spacing: 0;
  border-color: #fff;
  overflow: hidden;
}
div.service_table >>> table td {
  font-size: 13px;
  background: #fff !important;
  color: var(--color-2) !important;
  border-bottom: 1px solid #e8e8e8 !important;
  padding: 10px;
}
div.service_table >>> table tr:first-child td {
  color: #fff;
  background: var(--main-color);
}
div.service_table >>> table tr td {
  background: #ffe6e9;
  color: var(--main-color);
}
div.service_table >>> table tr td:first-child {
  font-size: 12px;
  width: 45%;
}
div.service_table >>> table tr td:last-child {
  font-weight: bold;
}
div.service_table >>> table tr:last-child td {
  border-bottom: none;
}
div.service_table >>> table .charge-td {
  background: var(--color-1) !important;
  color: #fff !important;
  font-weight: bold !important;
}

/* 予約一覧 */
.cast-prof-reserve-list__year {
  font-size: var(--read-font-size-s);
  color: #fff;
  font-weight: bold;
  background-color: var(--main-color);
  padding: 2px;
  display: inline-block;
  border-radius: 3px;
}
.cast-prof-reserve-list__course {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* フリーメモ */
.prof-list-data >>> .cast-prof__header-edit-button {
  font-weight: bold;
  border-radius: 21px;
  color: var(--main-color);
  width: 156px;
  height: 42px;
  line-height: 42px;
  box-sizing: border-box;
  font-size: 13px;
}
.prof-list-data >>> .free_memo_area {
  white-space: pre-line;
  background: #fff;
  color: var(--read-font-color-primary);
  font-size: 14px;
  font-weight: bold;
}
.mx-auto >>> .cast-prof-memo-dialog__cancel-button {
  font-size: var(--read-font-size-s);
  font-weight: bold;
  background-color: #f5f5f5;
}
.mx-auto >>> .cast-prof-memo-dialog__save-button {
  font-size: var(--read-font-size-s);
  font-weight: bold;
  background-color: var(--main-color) !important;
}
.mx-auto >>> .cast-prof-memo-dialog__date {
  font-size: var(--read-font-size-s);
  font-weight: bold;
  color: var(--read-font-color-sub);
}
.edit_memo_area {
  width: 98%;
}
.article-liked {
  margin: 4px;
  color: var(--read-font-color-sub);
  font-weight: bold;
}
.article-liked > span > div > a {
  text-decoration: none;
}
.article-title {
  margin: 4px 8px;
  color: var(--read-font-color-primary);
  font-weight: bold;
}
.usr-photo-diary__article-body {
  overflow: hidden;
  color: var(--read-font-color-primary);
  font-size: var(--read-font-size-s);
  font-weight: bold;
  word-wrap: break-word;
  white-space: pre-line;
}
.cast-like-btn-container {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.cast-like-btn-container ::v-deep .cast-like-button {
  width: 44px !important;
  height: 44px !important;
}
.cast-like-btn-container ::v-deep .cast-like-button__icon {
  font-size: 22px !important;
  top: 4px !important;
}
.cast-like-btn-container ::v-deep .cast-like-button__liked-count {
  top: 23px !important;
}
::v-deep .v-list-group__items > div {
  padding-bottom: 12px !important;
  min-height: 100px;
}
.image {
  position: relative;
}
.scope {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  padding: 4px;
  font-size: x-large;
  font-weight: bold;
  text-align: center;
}
.scope span {
  display: block;
  font-size: xx-small;
  line-height: 1.1;
  white-space: pre-line;
}
.scope_0 {
  display: none;
}
.scope_1 {
  background-color: rgba(0, 0, 255, 0.5);
  color: #fff;
}
.scope_2 {
  background-color: rgba(255, 255, 0, 0.5);
  color: #000;
}
.scope_3 {
  background-color: rgba(0, 255, 0, 0.5);
  color: #000;
}
.scope_out {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  transform: translateZ(0); /* iOSでのパフォーマンス対策 */
}

.scope_out_big {
  font-size: 3rem;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  transform: translateZ(0); /* iOSでのパフォーマンス対策 */
}

.scope_out_big span {
  font-size: 1rem;
}
</style>
