<template>
  <v-dialog v-model="this.$store.state.castDialog.selectedCastReserveDialog" max-width="500" fullscreen persistent no-click-animation transition="fade-transition">
    <v-card>
      <div class="mx-auto" style="max-width:500px;">
        <CommonHeader style="position:sticky;top:0;z-index:10;" />
        <ReserveStep2 v-if="this.$store.state.call.nominateCast" style="height:100%;padding-bottom:0px !important;margin-bottom:0px !important;background-color:var(--main-bg-color) !important;padding-top: 40px !important;background-color:#fff !important;" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
const moment = require("moment");
import CommonHeader from '~/components/layouts/CommonHeader.vue';
import ReserveStep2 from '~/pages/call/step2.vue';
import _ from 'lodash'

export default {
  components: {
    CommonHeader,
    ReserveStep2,
  },
}
</script>
