<template>
  <div>
    <div
      class="d-flex align-center justify-center search-cast-mode-btns-container mb-3 mt-6 pt-6"
    >
      <v-btn
        width="45%"
        height="38px"
        class="search-cast-mode-btn mode-all-btn"
        :class="{ 'btn-selected': selectPlace === 'selected-hotel' }"
        @click="changeSelectPlace('selected-hotel')"
      >
        {{ $t('hotel') }}
      </v-btn>
      <div
        style="width: 45%"
        @click="$store.state.auth.loggedIn ? changeSelectPlace('selected-home') : isGuestDialog($t('login_text_5'))"
      >
        <v-btn
          width="100%"
          height="38px"
          class="search-cast-mode-btn mode-selected-btn"
          :class="{ 'btn-selected': selectPlace === 'selected-home' }"
          :disabled="guestDisabled"
        >
          {{ $t('atHome') }}
        </v-btn>
      </div>
    </div>
    <v-tabs-items v-model="selectPlace" touchless>
      <v-tab-item value="selected-home" v-if="isSelectableHome">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-btn-toggle mandatory>
                  <v-flex class="pa-1">
                    <v-btn
                      outlined
                      class="usr-call-search-cond__btn"
                      elevation="0"
                    >
                      {{ $t('atHome') }}
                    </v-btn>
                  </v-flex>
                </v-btn-toggle>
              </v-col>
              <v-col cols="8">
                <p style="font-size: var(--read-font-size-s)">
                  {{ this.$store.state.siteMemberData.home_city_text }}<br />
                  {{ this.$store.state.siteMemberData.home_address }}
                  {{ this.$store.state.siteMemberData.home_building }}
                  {{ this.$store.state.siteMemberData.home_room_number }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="selected-home" v-else>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" align="center" justify="center">
                <p class="primary--text">
                  <!-- マイページの「<nuxt-link to="/account/home-deli"
                    >ご自宅出張設定</nuxt-link
                  >」より<br />
                  ご住所を登録し有効にしてください。 -->
                  <nuxt-link :to="`/${this.$i18n.locale}/account/home-deli`"
                    >{{ $t('text_9249') }}
                  </nuxt-link>
                  <br />
                  {{ $t('text_y8v0') }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="selected-hotel">
        <v-card flat class="mx-auto">
          <v-card-text>
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" cols="12">
                <v-btn-toggle
                  v-model="searchPrefectureId"
                  mandatory
                  style="width: 100%; display: inline-block"
                >
                  <div
                    v-for="item in prefectureItems"
                    :key="item.id"
                    style="width: 25%; display: inline-block"
                    class="pa-1"
                  >
                    <v-btn
                      @click="changePrefecture(item.id)"
                      :value="item.id"
                      height="25px"
                      :disabled="
                        loadingHotel ||
                        (shopMaps && !shopMaps.prefectureIds.includes(item.id))
                          ? true
                          : false
                      "
                      outlined
                      width="100%"
                      class="search-tag-btn"
                      elevation="0"
                      :class="
                        shopMaps && !shopMaps.prefectureIds.includes(item.id)
                          ? 'refecture_disabled'
                          : ''
                      "
                      :style="{
                        color:
                          $store.state.call.searchPrefectureId === item.id
                            ? '#fff !important'
                            : (loadingHotel || (shopMaps && !shopMaps.prefectureIds.includes(item.id)))
                            ? '#fff !important'
                            : '',
                      }"
                      style="font-size: 1.2vh"
                    >
                      {{ item.label }}
                    </v-btn>
                  </div>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" class="pa-0">
                <MapTokyo
                  v-if="searchPrefectureId == 13"
                  v-model="mapAreaId"
                  @changeMapArea="changeMapArea()"
                  :mapAreaId="mapAreaId"
                  :shop-map-ids="shopMaps ? shopMaps.shopMapIds : false"
                  ref="mapTokyo"
                  class="ma-1 mt-4"
                />
                <MapSaitama
                  v-if="searchPrefectureId == 11"
                  v-model="mapAreaId"
                  @changeMapArea="changeMapArea()"
                  :mapAreaId="mapAreaId"
                  :shop-map-ids="shopMaps ? shopMaps.shopMapIds : false"
                  ref="mapSaitama"
                  class="ma-1"
                />
                <MapYokohama
                  v-if="searchPrefectureId == 14"
                  v-model="mapAreaId"
                  @changeMapArea="changeMapArea()"
                  :mapAreaId="mapAreaId"
                  :shop-map-ids="shopMaps ? shopMaps.shopMapIds : false"
                  ref="mapYokohama"
                  class="ma-1"
                />
                <MapChiba
                  v-if="searchPrefectureId == 12"
                  v-model="mapAreaId"
                  @changeMapArea="changeMapArea()"
                  :mapAreaId="mapAreaId"
                  :shop-map-ids="shopMaps ? shopMaps.shopMapIds : false"
                  ref="mapChiba"
                  class="ma-1"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="pl-0 pr-0">
            <div class="mx-4 mb-5">
              <v-btn
                @click="toggleOthreArea"
                :disabled="loadingHotel"
                outlined
                class="usr-call-search-cond__btn disabled"
                width="100%"
                >{{ $t('other_area') }}</v-btn
              >
              <v-row v-if="otherAreaPanel">
                <v-col cols="12" class="pb-0">
                  <div style="font-size: 0.7rem; line-height: 0.7rem">
                    <div class="mb-1">{{ $t('prefecture_city') }}</div>
                    <div>※{{ $t('text_a3mg') }}</div>
                  </div>
                  <v-select
                    v-model="areaValue"
                    @change="getHotelListByArea()"
                    :items="areaList"
                    :item-disabled="areaList.disabled"
                    :loading="loadingArea"
                    class="area_list mt-0 pt-0"
                    item-text="address"
                    item-value="id"
                    :placeholder="$t('select')"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0">
                <div class="search-condition-header mb-3 mx-4">
                  <IconHotel
                    class="mr-1"
                    style="fill:#4B4B4B;stroke:#4B4B4B;width:15px;height:15px;"
                  />
                  {{ $t('hotel') }}
                </div>
                <div
                  class="mx-4"
                >
                  <FilterableDropdown
                    v-model="selectedHotelId"
                    @emitChangeHotel="changeHotel(1)"
                    :items="hotelList"
                    textKey="name"
                    idKey="id"
                    valueKey="id"
                    filterTargetKey="name_en"
                    filterTargetKanaKey="name_kana"
                    :loading="loadingHotel"
                  />
                  <div v-if="selectedHotel.note">{{ selectedHotel.note }}</div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <GuestAlertDialog :message="guestAlertMessage" ref="guestAlert" />
  </div>
</template>
<script>
import _ from 'lodash';
import MapTokyo from '~/components/pages/call/map/tokyo/MapTokyo.vue';
import MapChiba from '~/components/pages/call/map/chiba/MapChiba.vue';
import MapSaitama from '~/components/pages/call/map/saitama/MapSaitama.vue';
import MapYokohama from '~/components/pages/call/map/yokohama/MapYokohama.vue';
import FilterableDropdown from '~/components/pages/call/FilterableDropdown.vue';
import GuestAlertDialog from '~/components/pages/dialog/GuestAlertDialog.vue';
// デフォルト設定
const defaultSearchPrefectureId = 13;
const defaultAreaValue = 6;
const defaultMapAreas = {
  11: 36, // 埼玉: さいたま市大宮区
  12: 28, // 千葉: 千葉市中央区
  13: 2, // 東京: 五反田/品川
  14: 12, // 神奈川: 横浜市港南区
};
export default {
  components: {
    MapTokyo,
    MapChiba,
    MapSaitama,
    MapYokohama,
    FilterableDropdown,
    GuestAlertDialog,
  },
  props: ['hiddenHomeTab', 'shopMaps', 'isSaveDB'],
  data: () => ({
    mapAreaId: '',
    areaValue: '',
    selectPlace: 'selected-hotel', // ホテル: selected-hotel, 自宅: selected-home デフォルトはホテル
    searchPrefectureId: '',
    selectedHotelId: '',
    selectedHotel: {},
    areaList: [],
    hotelList: [],
    loadingArea: false,
    otherAreaPanel: false,
    loadingHotel: false,
    castUrlHash: '',
    guestDisabled: false,
    guestAlertMessage: '',
    disabledFilterableDropdown: false,
    selectLocale: null,
  }),
  created() {
    console.log(this.shopMaps);
  },
  computed: {
    isSelectableHome() {
      // propsにhiddenHomeTabが渡されていない場合は、自宅タブを表示
      // 自宅NG
      if (this.hiddenHomeTab && this.hiddenHomeTab === 1) {
        return false;
      }
      return this.$store.state.siteMemberData.home_hope_flag ? true : false;
    },
    prefectureItems: function() {
      return [
        { id: 13, label: this.$t('tokyo') },
        { id: 14, label: this.$t('kanagawa') },
        { id: 12, label: this.$t('chiba') },
        { id: 11, label: this.$t('saitama') },
      ];
    },
  },
  created: function() {
    if (
      Object.keys(this.$route.params).length > 0 &&
      this.$route.name == 'cast-id'
    ) {
      this.castUrlHash = this.$route.params.id;
    } else {
      this.castUrlHash = this.$store.state.castDialog.selectedCastUrlHash;
    }
    this.setModels();
    this.getAreaList(this.searchPrefectureId);
    if (this.otherAreaPanel === true) {
      this.getHotelListByArea();
    } else {
      this.getHotelListByMap();
    }
  },
  mounted: function() {
    // Guest
    if (this.$store.state.auth.loggedIn === false) {
      this.guestDisabled = true;
      this.selectPlace = 'selected-hotel';
      this.disabledFilterableDropdown = true;
    }
  },
  watch: {
    areaValue: function(newValue, oldValue) {
      if (Number(newValue) !== Number(oldValue)) {
        if (oldValue !== '') {
          this.$store.dispatch('call/areaValue', newValue);
        }
      }
    },
  },
  methods: {
    /**
     * 読み込み時
     */
    setModels() {
      this.searchPrefectureId = this.$store.state.call.searchPrefectureId;
      this.areaValue = this.$store.state.call.areaValue;
      this.selectPlace = this.$store.state.call.selectPlace;
      this.otherAreaPanel = this.$store.state.siteMemberData.otherAreaPanel;
      if (this.otherAreaPanel === true) {
        this.mapAreaId = '';
      } else {
        this.mapAreaId = this.$store.state.call.mapAreaId
          ? this.$store.state.call.mapAreaId
          : defaultMapAreas[this.searchPrefectureId];
      }
      this.selectedHotelId = this.$store.state.call.selectedHotelId;
      this.selectedHotel = this.$store.state.call.selectedHotel;
    },
    /**
     * パネルを開いたとき
     * areaValue からhotelListを取得
     */
    getHotelListByArea() {
      this.loadingHotel = true;
      this.disabledFilterableDropdown = true;
      this.hotelList = [];
      this.$axios
        .post(
          `public/hotel-list-by-area`,
          {
            areaId: this.areaValue,
            urlHash: this.castUrlHash,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then(async (response) => {
          if (response.data) {
            this.loadingHotel = false;
            this.disabledFilterableDropdown = false;
            const hotels = response.data;
            hotels.forEach(
              (item) => (item.name_en = this.unescapeString(item.name_en))
            );
            this.hotelList = hotels;
            this.setSelectedHotel(hotels);
            this.changeArea();
          }
        })
        .catch((error) => {});
    },
    /**
     * パネルを閉じたとき
     * mapAreaId からhotelListを取得
     */
    getHotelListByMap() {
      this.$emit('disabledBtn', true);
      this.loadingHotel = true;
      this.hotelList = [];
      if (this.shopMaps && !this.shopMaps.shopMapIds.includes(this.mapAreaId)) {
        this.loadingHotel = false;
        return;
      }
      this.$axios
        .post(
          `public/hotel-list-by-map`,
          {
            mapAreaId: this.mapAreaId,
            urlHash: this.castUrlHash,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then(async (response) => {
          if (response.data) {
            this.loadingHotel = false;
            const hotels = response.data;
            hotels.forEach(
              (item) => (item.name = this.unescapeString(item.name))
            );
            this.hotelList = hotels;
            this.setSelectedHotel(hotels);
            this.changeArea();
          }
          this.$emit('disabledBtn', false);
        })
        .catch((error) => {});
    },
    /**
     * ホテルが選択されていないときは、「未定◯◯ホテル」を選択
     */
    setSelectedHotel(hotels) {
      const hotelId = this.selectedHotelId;
      let hotel = _.find(hotels, (v) => (v.id === hotelId ? v : ''));
      if (!hotel) {
        hotel = hotels.find((v) => v.name.match(/未定/));
      }
      if (hotel) {
        if (this.$i18n.locale === 'zh-cn') {
          this.selectLocale = 'zh_cn'
        } else if (this.$i18n.locale === 'zh-tw') {
          this.selectLocale = 'zh_tw'
        } else if (this.$i18n.locale === 'ko') {
          this.selectLocale = 'ko'
        } else {
          this.selectLocale = 'en'
        }
        this.areaValue = hotel.area_id;
        this.selectedHotelId = hotel.id;
        this.selectedHotel = hotel;
        this.$store.dispatch('call/areaValue', hotel.area_id);
        this.$store.dispatch('call/selectedHotelId', hotel.id);
        this.$store.dispatch(
          'call/selectedHotelName',
          hotel['name_'+this.selectLocale]
        );
        this.$store.dispatch('call/selectedHotel', hotel);
      }
      return hotel;
    },
    /**
     * # Store call/areaValueへの一時的な保持
     * ## ホテル・自宅共通
     *  - call/areaValue
     *  - call/selectPlace
     *  - getShopList()
     * ## ホテルの場合
     *  - prefectureValue
     *  - getHotels()
     * ## 自宅の場合
     *  - call/subAreaValue
     */
    changeArea() {
      //// areaValueを保持
      this.$store.dispatch('call/areaValue', this.areaValue);
      this.$store.dispatch('call/searchPrefectureId', this.searchPrefectureId);
      this.$store.dispatch('call/selectPlace', this.selectPlace);
      //// emit
      this.$emit('getShopList');
      //// method
      this.changeHotel(2);
    },
    /**
     * ホテル切り替わり
     */
    changeHotel(no) {
      this.setSelectedHotel(this.hotelList);
      this.$emit('emitSearch');
    },
    /**
     * SaveStore
     */
    saveStore: function() {
      const areaId =
        this.selectPlace === 'selected-hotel'
          ? this.$store.state.call.areaValue
          : this.$store.state.siteMemberData.home_area_id;
      this.$store.dispatch('call/mapAreaId', this.mapAreaId);
      this.$store.dispatch('call/selectedHotelId', this.selectedHotelId);
      this.$store.dispatch('siteMemberData/areaUpdate', {
        mapAreaId: this.mapAreaId,
        areaIds: areaId,
        selectPlace: this.selectPlace,
        searchPrefectureId: this.searchPrefectureId,
        selectedHotelId: this.selectedHotelId,
        selectedHotel: this.selectedHotel,
        otherAreaPanel: this.otherAreaPanel,
      });
      /**
       * ログイン中にselectedAddressで呼び出された時
       * エリア変更時にエリアとホテル・自宅情報のみDBに保存
       */
      if (
        Boolean(this.isSaveDB) === true &&
        this.$store.state.auth.loggedIn === true
      ) {
        const saveSelectPlace =
          this.$store.state.siteMemberData.selectPlace === 'selected-home'
            ? 1
            : 2;
        this.$store.dispatch(
          'siteMemberData/lastUsePlaceSearchTypeUpdate',
          saveSelectPlace
        );
        this.$axios
          .put(
            `private/site-members/${this.$store.state.siteMemberData.id}`,
            {
              email: this.$store.state.auth.user.email,
              areaIds: this.$store.state.siteMemberData.area_ids,
              last_use_place_search_type: saveSelectPlace,
            },
            {
              headers: {
                Authorization: `${this.$auth.$storage.getUniversal(
                  '_token.auth0',
                  true
                )}`,
              },
            }
          )
          .then((response) => {})
          .catch((error) => {});
      }
    },
    /**
     * ajax: エリア一覧取得
     */
    async getAreaList(searchPrefectureId) {
      this.loadingArea = true;
      this.areaList = [];
      this.$axios
        .post(
          'public/area-list2',
          {
            searchPrefectureId: searchPrefectureId,
            urlHash: this.castUrlHash,
          },
          {
            headers: {
              Authorization: `${this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              )}`,
            },
          }
        )
        .then((response) => {
          this.loading = false;
          this.loadingArea = false;
          if (response.data) {
            this.areaList = response.data;
            //areaListのadressを翻訳。$t('areas.${area.id}.address')に変える
            this.areaList.forEach((area) => {
              area.address = this.$t(`areas.${area.id}.address`);
            });
          }
        })
        .catch((error) => {});
    },
    toggleOthreArea: function() {
      // toggle
      this.otherAreaPanel = !this.otherAreaPanel;
      if (this.otherAreaPanel === true) {
        if (this.searchPrefectureId === 13) {
          this.$refs.mapTokyo.selectedArea = '';
        } else if (this.searchPrefectureId === 11) {
          this.$refs.mapSaitama.selectedArea = '';
        } else if (this.searchPrefectureId === 14) {
          this.$refs.mapYokohama.selectedArea = '';
        } else if (this.searchPrefectureId === 12) {
          this.$refs.mapChiba.selectedArea = '';
        }
        this.getHotelListByArea();
      } else {
        if (this.searchPrefectureId === 13) {
          this.$refs.mapTokyo.selectedArea = this.mapAreaId;
        } else if (this.searchPrefectureId === 11) {
          this.$refs.mapSaitama.selectedArea = this.mapAreaId;
        } else if (this.searchPrefectureId === 14) {
          this.$refs.mapYokohama.selectedArea = this.mapAreaId;
        } else if (this.searchPrefectureId === 12) {
          this.$refs.mapChiba.selectedArea = this.mapAreaId;
        }
        this.getHotelListByMap();
      }
    },
    changeMapArea: function() {
      this.otherAreaPanel = false;
      this.getHotelListByMap();
    },
    changePrefecture: function(prefectureId) {
      this.otherAreaPanel = false;
      this.mapAreaId = defaultMapAreas[prefectureId];
      this.getHotelListByMap();
      this.getAreaList(prefectureId);
    },
    changeSelectPlace: function(key) {
      this.selectPlace = key;
      this.$store.dispatch('call/selectPlace', key);
      this.$store.dispatch('siteMemberData/selectPlace', key);
      if (this.shopMaps && this.$store.state.siteMemberData.prefecture_id) {
        this.searchPrefectureId = this.shopMaps.prefectureIds[0];
        this.changePrefecture(this.shopMaps.prefectureIds[0]);
      }
      this.changeArea();
    },
  },
};
</script>

<style lang="scss" scoped>
.usr-call-search-cond__btn {
  border: #5d5d5d 1px solid;
  border-radius: 5px;
  background: #f0f3f4 !important;
  font-size: 14px;
  font-weight: bold;
  color: #5d5d5d !important;
}
.v-btn--active {
  background: var(--sub-color) !important;
  color: #fff !important;
  border: none !important;
}
.v-btn-toggle >>> .usr-call-search-cond__btn.disabled {
  color: #f8f8ff !important;
  font-weight: normal;
  background-color: rgba(0, 0, 0, 0.56) !important;
  border-color: rgba(0, 0, 0, 0.02) !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -2px rgba(0, 0, 0, 0.4) !important;
}
.usr-call-search-cond__header {
  display: inline-block;
  font-weight: bold;
}
.usr-call-search-cond__map-select-btn {
  max-width: 200px;
  text-align: center;
  border: 1px solid #ddd;
  display: inline-block;
  font-weight: bold;
  font-size: var(--read-font-size-s);
  line-height: 1.2em;
}
.refecture_disabled {
  background: -moz-linear-gradient(top, #696969, #808080);
  background: -webkit-linear-gradient(top, #696969, #808080);
  background: linear-gradient(to bottom, #696969, #808080);
  font-weight: normal !important;
}
.v-select-list >>> div[aria-disabled='true'] {
  background: #696969;
  color: #fff;
}
.search-cast-mode-btns-container {
  .search-cast-mode-btn {
    font-size: 0.75rem;
    font-weight: bold;
    color: #fff;
    background: #bdbdbd !important;
  }
  .mode-all-btn {
    border-radius: 19px 0px 0px 19px;
  }
  .mode-selected-btn {
    border-radius: 0px 19px 19px 0px;
  }
  .btn-selected {
    background: var(--sub-color) !important;
  }
}
::v-deep .v-select__selections {
  min-height: 25px !important;
  input::placeholder {
    color: #4b4b4b;
    font-size: 0.875em;
    font-weight: bold;
    font-weight: bold;
  }
}
::v-deep .v-input__append-inner {
  .v-icon {
    color: var(--read-font-color-primary) !important;
  }
}
</style>
