<template>
   <div class="grade-icon d-flex align-center justify-center">
     <GradeBronz v-if="Number(grade) === 1" :style="styleObject" />
     <GradeSilver v-if="Number(grade) === 2" :style="styleObject" />
     <GradeGold v-if="Number(grade) === 3" :style="styleObject" />
     <GradePlutinum v-if="Number(grade) === 4" :style="styleObject" />
     <GradeDiamond v-if="Number(grade) === 5" :style="styleObject" />
   </div>
 </template>
 
<script>
import GradeBronz from '~/assets/img/grade-bronz.svg';
import GradeSilver from '~/assets/img/grade-silver.svg';
import GradeGold from '~/assets/img/grade-gold.svg';
import GradePlutinum from '~/assets/img/grade-plutinum.svg';
import GradeDiamond from '~/assets/img/grade-diamond.svg';

export default {
  components: {
    GradeBronz,
    GradeSilver,
    GradeGold,
    GradePlutinum,
    GradeDiamond,
  },
  props: {
    grade: {
      props: [Number, String],
      default: 1
    },
    size: {
      props: [Number, String],
      default: 90,
    }
  },
  computed: {
    styleObject() {
      return {
        width: `${String(this.size)}px`
      };
    }
  }
}
</script>