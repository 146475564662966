<template>
  <div class="mt-6"
    style="text-align: left;"
  >
    <div class="mb-4">
      <IconWechat/>
      <span class="chat-id-title">
        {{ $t('chat_text_2') }}
      </span>
      <div class="id-copy-area mt-2">
        <span class="id-copy-area-text">
          {{ $t('chat_id') }}
        </span>
      <v-btn
        style="bottom:15%; margin-left: 5%;"
        text
        icon
        large
        @click.stop="copyId('wechat')"
      >
      <IconCopyFile />
      </v-btn>
        <span class="copy-id-text">{{ $t('chat_copy_id_text') }}</span>
      </div>
    </div>

    <div class="mb-4">
      <IconKakao/>
      <span class="chat-id-title">
        {{ $t('chat_text_3') }}
      </span>
      <div class="id-copy-area mt-2">
        <span class="id-copy-area-text">
          {{ $t('chat_id') }}
        </span>
      <v-btn
        style="bottom:15%; margin-left: 5%;"
        text
        icon
        large
        @click.stop="copyId('kakao')"
      >
      <IconCopyFile />
      </v-btn>
        <span class="copy-id-text">{{ $t('chat_copy_id_text') }}</span>
      </div>
    </div>

    <v-overlay :value="wechatCopyDialog" @click="wechatCopyDialog = false" />
    <v-dialog v-model="wechatCopyDialog">
      <v-card class="pa-4" style="font-weight: bold;color: var(--sub-color);">
        {{ $t('chat_text_4') }}
      </v-card>
    </v-dialog>

    <v-overlay :value="kakaoCopyDialog" @click="kakaoCopyDialog = false" />
    <v-dialog v-model="kakaoCopyDialog">
      <v-card class="pa-4" style="font-weight: bold;color: var(--sub-color);">
        {{ $t('chat_text_5') }}
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import IconKakao from '~/assets/img/icon-kakao.svg';
import IconWechat from '~/assets/img/icon-wechat.svg';
import IconCopyFile from '~/assets/img/icon-copyfile.svg';

export default {
  components: {
    IconKakao,
    IconWechat,
    IconCopyFile,
  },
  data() {
    return {
      wechatCopyDialog: false,
      kakaoCopyDialog: false,
    }
  },
  methods: {
    copyId(dialog) {
      navigator.clipboard.writeText(this.$t('chat_id'));
      if (dialog == 'wechat') this.wechatCopyDialog = true;
      if (dialog == 'kakao') this.kakaoCopyDialog = true;
    },
  },
}
</script>

<style lang="scss" scoped>
.chat-id-title {
  font-size: var(--read-font-size-m);
  vertical-align:10%;
}
.id-copy-area {
  border: 1px solid #bbb;
  width:100%;
  height: 35px;
  background-color: #fff;
  border-radius: 10px;
  text-align:  center;
}

.id-copy-area-text {
  font-size: 16px;
  vertical-align:20%;
  padding-right: 20px;
}

.copy-id-text {
  font-size: 12px;
  vertical-align:20%;
}
.copy-dialog_close_btn {
  font-weight: bold;
  color: #FFF; 
  background: var(--main-color);
  border-radius: 4px;
  width: 25%;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
}
</style>