<template>
  <v-dialog v-model="dialog" persistent max-width="600px" style="z-index: 9999">
    <v-card class="text-center">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pt-10 pb-5">
            <h1 class="h1">{{ $t('age_verification') }}</h1>
          </v-col>
          <v-col>
            <img src="~/assets/img/18kin.png" width="20%" alt="" />
          </v-col>
          <v-col cols="12">
            <h2 class="h2 h2-text">{{ $t('over18') }}</h2>
          </v-col>
          <v-col cols="12" class="pt-5 pb-10">
            <v-btn
              dark
              rounded
              color="#777777"
              width="40%"
              class="mr-1 btn-no subtitle-1"
              v-on:click="close()"
              ><b
                >{{ $t('no')
                }}<span class="caption">（{{ $t('exit') }}）</span></b
              ></v-btn
            >
            <v-btn
              dark
              rounded
              width="50%"
              v-on:click="enter()"
              class="ml-1 subtitle-1 btn-enter"
              ><b
                >{{ $t('yes')
                }}<span class="caption">（{{ $t('enter') }}）</span></b
              ></v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: [],
  data: () => ({
    dialog: false,
  }),
  computed: {},
  created: function () {},
  mounted: function () {
    if (this.$store.state.auth.loggedIn === true) {
      // ログインしている場合はダイアログを出さない
      this.$store.dispatch('browserUI/commitProhibitedUnder18Dialog', false);
    }
    this.dialog = this.$store.state.browserUI.prohibitedUnder18Dialog;
  },
  methods: {
    close: function () {
      window.location.href = 'https://www.google.co.jp/';
    },
    enter: function () {
      this.dialog = false;
      // はい（入場）をクリックした以降はダイアログを出さない
      this.$store.dispatch('browserUI/commitProhibitedUnder18Dialog', false);
    },
  },
};
</script>

<style type="text/css">
.h1 {
  color: var(--color-1);
  font-weight: bold;
  font-size: 4vw;
}
.h2 {
  color: var(--main-color);
  font-weight: bold;
  font-size: 6vw;
}
.cast-prof__reserve-btn {
  background: linear-gradient(#ff7d99, #b22c27);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.3),
    inset 0px 0px 2px rgba(255, 255, 255, 1), 0px 0px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.cast-prof__reserve-gray-btn {
  background: linear-gradient(#999999, #808080);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.3),
    inset 0px 0px 2px rgba(255, 255, 255, 1), 0px 0px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.btn-enter {
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  padding: 0.84rem 2.14rem;
  font-size: 0.91rem;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  border-radius: 1em;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  background: linear-gradient(40deg, #b22c27, #fe1f5f) !important;
  color: #fff;
  font-weight: bold;
  position: relative;
}
/* ウィンドウ幅が 768px 以上の場合 */
@media (min-width: 768px) {
  .h2-text {
    font-size: 44px;
  }
}
</style>
