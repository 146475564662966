<template>
  <v-container class="pa-0" style="margin-bottom: 2px">
    <v-card style="border-radius: 0px; background: var(--sub-bg-color)">
      <div v-if="loading || castLoading" class="pr-4">
        <!-- UI Component: LoadingImage -->
        <loading-image />
      </div>
      <div v-else :class="className" class="scroll-container">
        <div
          v-for="(cast, index) of casts"
          :key="cast.id"
          style="margin: 5px 10px 5px 5px"
          :style="{'marginLeft': (index==0) ? '20px':'0'}"
        >
          <div class="py-2 ranking-cast-wrapper">
            <div
              v-if="getRanking(cast) == 1"
              :class="rankingNumClassObject"
              style="position: absolute; top: -1px; left: 1px"
            >
              <crownImage1 class="mr-3" style="fill: #e4c73f" />
              <span class="mr-3 ranking-name">{{ getRankingName() }}</span>
              <span class="ranking-num mr-10">{{ getRankingNum(cast) }}</span>
            </div>
            <div
              v-if="getRanking(cast) == 2"
              :class="rankingNumClassObject"
              style="position: absolute; top: 0; left: 1px"
            >
              <crownImage2 class="mr-3" style="fill: #bdbdbd" />
              <span class="mr-3 ranking-name">{{ getRankingName() }}</span>
              <span class="ranking-num mr-10">{{ getRankingNum(cast) }}</span>
            </div>
            <div
              v-if="getRanking(cast) == 3"
              :class="rankingNumClassObject"
              style="position: absolute; top: -2px; left: 1px"
            >
              <crownImage3 class="mr-3" style="fill: #e49d3f" />
              <span class="mr-3 ranking-name">{{ getRankingName() }}</span>
              <span class="ranking-num mr-10">{{ getRankingNum(cast) }}</span>
            </div>
            <div
              v-if="getRanking(cast) > 3"
              :class="rankingNumClassObject"
              style="position: absolute; top: 0; left: 1px"
            >
              <v-avatar size="22" color="primary" class="mr-3">
                <span
                  class="white--text"
                  style="font-size: var(--read-font-size-s); font-weight: bold"
                  >{{ getRanking(cast) }}</span
                >
              </v-avatar>
              <span class="mr-3 ranking-name">{{ getRankingName() }}</span>
              <span class="ranking-num mr-10">{{ getRankingNum(cast) }}</span>
            </div>
            <div
              class="mt-8 cast-info-box"
              style="width: 210px; border-radius: 10px"
            >
              <!-- キャスト画像プロフィール -->
              <CastImageCard
                @toCastProfileDialog="toCastProfile(type == 1 ? cast.inboundCast : cast)"
                :cast="type == 1 ? cast.inboundCast : cast"
                :executeParentMethod="true"
                :castRankingPublicFlag="cast.cast_ranking_public_flag"
                :imageMaxHeight="315"
                :imageMinHeight="315"
                :pointCardNotDisplay="true"
              />
              <!-- キャストスケジュール -->
              <div class="pa-2 cast-schedules">
                <div v-for="(time, key) in cast.times" :key="key">
                  <span class="caption">
                    {{
                      // $moment(time.day).format('MM/DD (ddd)') //TODO 曜日を表示する場合はこちらを使用
                      $moment(time.day).format('MM/DD')
                    }}
                  </span>
                  <v-chip
                    color="#BDBDBD"
                    text-color="white"
                    x-small
                    label
                    class="caption px-1"
                    v-if="!cast.cast_ranking_public_flag"
                    >{{ $t('private') }}</v-chip
                  >
                  <v-chip
                    color="primary"
                    text-color="white"
                    x-small
                    label
                    class="caption px-1"
                    v-else-if="
                      time.schedule &&
                      time.is_free &&
                      !time.schedule.reception_end_flag
                    "
                    >{{ $t('available') }}</v-chip
                  >
                  <v-chip
                    color="#BDBDBD"
                    text-color="white"
                    x-small
                    label
                    class="caption px-1"
                    v-else-if="
                      time.schedule &&
                      (!time.is_free || time.schedule.reception_end_flag)
                    "
                    >{{ $t('full') }}</v-chip
                  >
                  <v-chip
                    color="#BDBDBD"
                    text-color="white"
                    x-small
                    label
                    class="caption px-1"
                    v-else
                    >{{ $t('day_off') }}</v-chip
                  >
                  <span
                    class="caption"
                    v-if="time.schedule && cast.cast_ranking_public_flag"
                  >
                    {{ $moment(time.schedule.start_datetime).format('HH:mm') }}
                    ~ {{ $moment(time.schedule.end_datetime).format('HH:mm') }}
                  </span>
                </div>
              </div>
              <!-- <CastPointCard
                  v-if="cast.point_type"
                  :pointType="cast.point_type"
                  :point="cast.point"
                /> -->
            </div>
          </div>
        </div>
        <SeeMoreBtn
            v-if="!loadingComplete && casts.length > 0"
            class="v-slide-group__content mr-3"
            :loadingComplete="loadingComplete"
            :loading="paginationLoading"
            btnName="btnMore"
            @getMoreData="$emit('getMoreData')"
          />
        </div>
      <div class="mt-3" v-if="!loading && castLoading && !casts.length">
        {{ $t('no_match_found') }}
      </div>
    </v-card>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title>
          {{ $t('cannot_display_private') }}
        </v-card-title>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import crownImage1 from '~/assets/img/icon-crown-1.svg';
import crownImage2 from '~/assets/img/icon-crown-2.svg';
import crownImage3 from '~/assets/img/icon-crown-3.svg';
import CastImageCard from '~/components/organisms/castImageCard/CastImageCard.vue';
// import CastPointCard from '~/components/layouts/CastPointCard.vue';
import IconReserve from '~/assets/img/icon-reserve.svg';
import IconCrown from '~/assets/img/icon-crown.svg';

export default {
  components: {
    CastImageCard,
    // CastPointCard,
    IconReserve,
    IconCrown,
    crownImage1,
    crownImage2,
    crownImage3,
  },
  props: ['casts', 'title', 'type', 'className', 'loading', 'loadingComplete', 'paginationLoading'],
  data: () => ({
    dialog: false,
    castLoading: false,
    dialog: false,
  }),
  mounted: function () {
    this.castLoading = true;
    // 遅延対策のためバインドしたキャスト情報を後から読み込む
    setTimeout(() => {
      this.castLoading = false;
    }, 300);
  },
  methods: {
    getRankingName() {
      if (this.type == 2) return this.$t('text_w6ie');
      if (this.type == 3 || this.type == 5) return this.$t('text_1q3d');
    },
    getRankingNum(cast) {
      if (this.type == 2) return cast.favorite_add_number;
      if (this.type == 3) return cast.photo_diary_favorite_add_number;
      if (this.type == 5)
        return cast.site_member_like_cast_photo_diary_articles_count;
    },
    toCastProfile(cast) {
      const target = document.getElementsByClassName(this.className)[0]
        .childNodes[0];
      this.$store.dispatch('castDialog/sliderScrollX', target.scrollLeft);
      this.$store.dispatch('castDialog/sliderScrollXTargetId', this.className);
      this.toCastProfileDialog(cast);
    },
    getRanking(cast) {
      switch (this.type) {
        case '1':
          return (
            this.casts.filter(
              (c) =>
                Number(c.calculated_the_designation) >
                Number(cast.calculated_the_designation)
            ).length + 1
          );
        case '2':
          return (
            this.casts.filter(
              (c) => c.favorite_add_number > cast.favorite_add_number
            ).length + 1
          );
        case '3':
          return (
            this.casts.filter(
              (c) =>
                c.photo_diary_favorite_add_number >
                cast.photo_diary_favorite_add_number
            ).length + 1
          );
        case '4':
          return (
            this.casts.filter(
              (c) =>
                c.site_member_follow_casts_count >
                cast.site_member_follow_casts_count
            ).length + 1
          );
        case '5':
          return (
            this.casts.filter(
              (c) =>
                c.site_member_like_cast_photo_diary_articles_count >
                cast.site_member_like_cast_photo_diary_articles_count
            ).length + 1
          );
        default:
          return 0;
      }
    },
  },
  computed: {
    rankingNumClassObject: function () {
      return {
        'ranking-num-wrapper':
          this.type == 2 || this.type == 3 || this.type == 5,
      };
    },
    openDialog() {
      return this.dialog && this.dialogText;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  box-shadow: none !important;
}
.v-card > h3 {
  border-left: 0;
}
.v-input,
.v-card p {
  font-size: 14px;
}
.ranking-cast-wrapper {
  position: relative;
  cursor: pointer;
  .cast-info-box {
    box-shadow: 0px 0px 3px 0 #00000029;
    border-radius: 0 0 5px 5px;
    white-space: nowrap;
    .cast-schedules {
      background: #fff;
      box-sizing: border-box;
      height: 190px;
      border-radius: 0 0 10px 10px;
      .caption {
        font-family: "YumeotoFont" !important;
        font-size: 11px !important;
        font-weight: bold;
        color: var(--main-title-color);
        width: 62px;
        margin: 0px 5px 0px 0px;
        ::v-deep .v-chip__content {
          width: 100%;
          justify-content: center;
        }
      }
    }
    .cast-reservation-box {
      background: #fff;
      border-radius: 0 0 10px 10px;
      height: 54px;
      .reservation-btn {
        border-radius: 19px;
        box-sizing: border-box;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        height: 30px;
        margin: auto;
        line-height: 30px;
        text-align: center;
        width: 90%;
        max-width: 300px;
        box-shadow: 0px 1px 3px 0 #00000029;
        position: relative;
        .reservation-icon {
          position: absolute;
          left: 10%;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  ::v-deep .cast-image-profile-bottom {
    border-radius: 0 !important;
    border-bottom: 1px solid #e6e5e5;
  }
  ::v-deep .cast-image-profile-container {
    box-shadow: 0px 0px 0px 0 #fff;
  }
}
.ranking-cast-wrapper .avatar {
  border: 5px solid #B22C27;
  text-align: left;
  cursor: pointer;
}
.rankingSchedule {
  float: left;
}
.reservationExpiration {
  float: right;
  text-align: center;
  width: 35px;
  padding: 0;
}
.business-type-name {
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  font-size: 10px;
  padding: 1px 6px;
}
.entry-date-new {
  border: solid 1px #B22C27;
  border-radius: 3px;
  padding: 0 3px;
}
.ranking-num-wrapper {
  width: 210px;
  background: #fff;
  border-radius: 18px;
  height: 35px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-around;
  padding: 5px;
  .ranking-name {
    font-size: 12px;
    font-weight: bold;
    color: var(--main-title-color);
  }
  .ranking-num {
    font-size: 16px;
    font-weight: bold;
    color: var(--main-color);
  }
}

.scroll-container {
  display: flex;
  overflow-x: scroll;
  width: 100%;
}

.scroll-item {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  /* Chrome, Safari and Opera */
  .scroll-container::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  .scroll-container {
    scrollbar-width: none;
  }

  /* Internet Explorer and Edge */
  .scroll-container {
    -ms-overflow-style: none;
  }
}
</style>
