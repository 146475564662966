<template>
  <div class="text-center mx-auto fetch-btn">
    {{ msg }}
  </div>
</template>

<script>
export default {
  props: {
    msg: String,
    required: true
  }
}
</script>

<style lang="scss" scoped>
.fetch-btn {
  background: var(--sub-color);
  border-radius: 21px;
  width: 180px;
  height: 43px;
  box-sizing: border-box;
  line-height: 43px;
  font-size: 13px;
  font-weight: bold;
  color: #FFF;
  box-shadow: 0px 2px 5px 0 #00000029;
  cursor: pointer;
}
</style>