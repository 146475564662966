<template>
  <div class="dispatchable-areas-container px-3 py-5">
    <!-- タイトルなど -->
    <div class="header mb-3">
      <div class="d-flex align-center title mb-3">
        <IconArea style="fill:#B22C27;" class="mr-2" />{{ $t('dispatchable_area') }}
      </div>
      <!-- 店舗毎の派遣可能エリアの説明 -->
      <div class="description mb-5">
        <div v-html="dispatchAreaDescriptionHtml(dispatchAreaDescription)"></div>
      </div>
    </div>
    <!-- 都道府県毎の派遣可能エリア -->
    <!-- 東京都、神奈川県、埼玉県、千葉県の順　ソートができないため直書き -->
    <div v-if="dispatchableAreas['東京都']" class="mb-3 areas">
      <div class="mb-2 prefecture-name">{{ $t('tokyo') }}</div>
      <div
        v-for="area in dispatchableAreas['東京都']"
        :key="area.id"
        class="tag mr-2 mb-2"
        depressed
      >
        {{ areaName($t('areas.' + area.id + '.address')) }}
      </div>
    </div>
    <div v-if="dispatchableAreas['神奈川県']" class="mb-3 areas">
      <div class="mb-2 prefecture-name">{{ $t('kanagawa') }}</div>
      <div
        v-for="area in dispatchableAreas['神奈川県']"
        :key="area.id"
        class="tag mr-2 mb-2"
        depressed
      >
        {{ areaName($t('areas.' + area.id + '.address')) }}
      </div>
    </div>
    <div v-if="dispatchableAreas['埼玉県']" class="mb-3 areas">
      <div class="mb-2 prefecture-name">{{ $t('saitama') }}</div>
      <div
        v-for="area in dispatchableAreas['埼玉県']"
        :key="area.id"
        class="tag mr-2 mb-2"
        depressed
      >
        {{ areaName($t('areas.' + area.id + '.address')) }}
      </div>
    </div>
    <div v-if="dispatchableAreas['千葉県']" class="mb-3 areas">
      <div class="mb-2 prefecture-name">{{ $t('chiba') }}</div>
      <div
        v-for="area in dispatchableAreas['千葉県']"
        :key="area.id"
        class="tag mr-2 mb-2"
        depressed
      >
        {{ areaName($t('areas.' + area.id + '.address')) }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import IconArea from '~/assets/img/icon-area.svg';

export default {
  components: {
    IconArea,
  },
  props: {
    dispatchableAreas: {
      type: Object, // 都道府県名 => 派遣可能エリアの配列
      required: true,
    },
    dispatchAreaDescription: {
      type: String,
    },
  },
  methods: {
    // 「市区町村」部分のみ表示
    areaName(address) {
      const result = address.split(' ');
      return result[result.length - 1];
    },
    // 派遣可能エリアが含まれているか
    includeDipatchableAreas(areas) {
      const result = _.filter(areas, (area) => {
        return area.shop_sub_areas_count;
      });
      return result.length;
    },
    dispatchAreaDescriptionHtml(text) {
      return text.replace(/\n/g, '<br>');
    },
  },
};
</script>

<style lang="scss" scoped>
.dispatchable-areas-container {
  background: var(--main-bg-color);
  .header {
    font-weight: bold;
    color: var(--main-color);
    .title {
      font-size: 1rem;
    }
    .description {
      font-size: 0.75rem;
    }
  }
  .areas {
    .prefecture-name {
      color: #000000;
      font-size: 13px;
      font-weight: bold;
    }
    .tag {
      display: inline-block;
      text-align: center;
      border-radius: 30px;
      border: 1px solid #5d5d5d;
      background: #fff !important;
      font-size: 0.75rem;
      line-height: 30px;
      width: 100px;
      height: 30px;
    }
  }
}
</style>
