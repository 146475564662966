<template>
  <v-dialog
    v-model="dialog"
    persistent
    @click:outside="toggleDialog"
    height="150"
    max-width="370"
    style="z-index: 9999;"
  >
    <v-card class="pa-4" v-on:click="toggleDialog">
      <span v-html="$t('text_3g7y')"></span><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ReserveAlertDialog',
  props: ['reserveUrl'],
  methods: {
    /* 
        モーダル外クリック時のイベント。persistentでデフォルトの処理を停止してこのメソッドを呼ぶ
        ダイアログのv-modelにVuexのstateを利用しているためこの処理が必要
    */
    toggleDialog() {
      this.dialog = false;
    },
  },
  computed: {
    dialog: {
      get() {
        return this.$store.state.call.reserveAlertDialog;
      },
      set(v) {
        this.$store.dispatch('call/reserveAlertDialog', v);
      },
    },
  },
};
</script>

<style scoped></style>
