<template>
  <!-- ユーザーランキングにランクインしている場合目立たせる -->
  <div class="d-flex align-center pl-5 pb-1 user-ranking-highlight-box">
    <IconMedal style="z-index: 3;" class="mr-3" />
    <div style="padding-top:3px;">ユーザーランキング（参考になった数）{{ rank }}位</div>
  </div>
</template>

<script>
import IconMedal from '~/assets/img/icon-medal3.svg';
export default {
  props: {
    rank: Number,
    required: true
  },
  components: {
    IconMedal
  },
}
</script>

<style scoped>
.user-ranking-highlight-box {
  background: #FFF9E5;
  font-size: 12px;
  font-weight: bold;
  color: #B22C27;
  width: 100%;
}
</style>