<template>
  <div style="background: var(--sub-bg-color);">
    <h3 class="d-flex align-center" style="padding:15px 20px;">
      <span style="font-size: 16px;font-weight: bold;color: var(--main-color);">
        {{ $t('text_d3vr') }}
      </span>
    </h3>
    <!-- ジャンル -->
    <!-- <div class="px-4 py-2">
        <SearchConditionBtns
          :values="genreItems"
          :selectedValues="selectedGenres"
          @action="selectGenre"
        />
    </div> -->
    <!-- <SearchConditionTags
      class="mb-3 px-5"
      :selectedTermType="selectedTermTypeFavorite"
      :loading="loading"
      @selectTerm="selectTerm"
      @updateSearch="updateSearch"
    /> -->
    <RankingSlider
      v-if="!isTotalRanking"
      :casts="this.$store.state.ranking.rankingCast2"
      type="2"
      className="ranking-cast2"
      :loading="loading"
      :loadingComplete="loadingComplete"
      :paginationLoading="paginationLoading"
      @getMoreData="getRankings(true)"
    />
    <RankingSlider
      v-else
      :casts="this.$store.state.ranking.rankingCast4"
      type="4"
      className="ranking-cast4"
      :loading="loading"
      :loadingComplete="loadingComplete"
      :paginationLoading="paginationLoading"
      @getMoreData="getRankings(true)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RankingSlider from "~/components/pages/home/RankingSlider.vue";
import SearchConditionBtns from "~/components/pages/common/SearchConditionBtns.vue";
import SearchConditionTags from "~/components/pages/home/CastRanking/SearchConditionTags.vue";
// import GuestAlertDialog from "~/components/pages/dialog/GuestAlertDialog.vue";

import CastRankingMixin from "~/mixins/cast-rankin-mixin";

export default {
  components: {
    RankingSlider,
    SearchConditionBtns,
    SearchConditionTags,
    // GuestAlertDialog,
  },
  mixins: [CastRankingMixin],
  data() {
    return {
      loading: false,
      paginationLoading: false,
      shopLoading: false,
      dialog: false,
      questionDialog: false,
      selectedTermType: 4,
      selectedGenres: [],
      selectedAreas:  [],
      selectedShop: null,
      shopItems: [],
      isGuest: false,
      guestAlertMessage: "",
      perPage: 10,
    }
  },
  created() {
    //this.selectedTermType = this.selectedTermTypeFavorite;
    this.isGuest = !this.$store.state.auth.loggedIn;
  },
  computed: {
    ...mapState("ranking", [
      "selectedTermTypeFavorite",
      "page2",
      "page4"
    ]),
    isTotalRanking() {
      return this.selectedTermType === 4;
    },
    loadingComplete() {
      // return this.isTotalRanking ? this.page4 > 3 : this.page2 > 3;
      return this.page4 > 3
    },
    genreItems: function() {
      return [
        { id: 1, label: this.$t('business_types.1.name') },
        { id: 2, label: this.$t('business_types.2.name') },
        { id: 3, label: this.$t('business_types.3.name') },
      ];
    },
  },
  watch: {
    selectedTermTypeFavorite(newVal) {
      this.selectedTermType = 4;
        // this.selectedTermType = newVal;
    }
  },
  methods: {
    ...mapActions("ranking", [
      "saveSelectedTermTypeFavorite",
      "saveSelectedGenresFavorite",
      "saveRankingCast2Data",
      "saveRankingCast4Data",
      "commitPage2",
      "commitPage4"
    ]),
    getRankings(isPaginate = false) {
      if (!isPaginate) {
        this.loading = true;
        this.saveRankingCast2Data([]);
        this.saveRankingCast4Data([]);
        this.commitPage2(1);
        this.commitPage4(1);
      } else {
        this.paginationLoading = true;
      }
      const params = {
        // rankingType: !this.isTotalRanking ? 2 : 4,
        rankingType: 4,
        termType: this.selectedTermType,
        genreIds: this.selectedGenres,
        siteMemberId: this.$store.state.siteMemberData.id,
        siteMemberGrdadeId: this.$store.state.siteMemberData.gradeId,
        loggedIn: this.$store.state.auth.loggedIn,
        // page: this.isTotalRanking ? this.page4 : this.page2,
        page: this.page4,
        perPage: this.perPage,
      };
      // 累計ランキングに暫く統一します
      // INBOUND-115  [home][Favorite Ranking] デフォルトをWeekly (7 days prior to the day before) ではなくCumulative Total にしてください
      this.getTotalCastRanking(4, params).then(() => {
          this.loading = false;
          this.paginationLoading = false;
      });
      // 期間指定ランキングか、累計ランキングか
      // if (!this.isTotalRanking) {
      //   this.getCastRanking(2, params).then(() => {
      //     this.loading = false;
      //     this.paginationLoading = false;
      //   });
      // } else {
      //   this.getTotalCastRanking(4, params).then(() => {
      //     this.loading = false;
      //     this.paginationLoading = false;
      //   });
      // }
    },
    selectTerm(term) {
      this.selectedTermType = term;
    },
    selectGenre(ids) {
      this.selectedGenres = this.copyDeep(ids);
      this.getShopItems(); 
    },
    clearAllConditions() {
      this.selectedTermType = 4;
    },
    updateSearch() {
      this.getRankings();
      this.saveSelectedTermTypeFavorite(this.selectedTermType);
      this.saveSelectedGenresFavorite(this.copyDeep(this.selectedGenres));
      this.updateOrGetSearchItems(true, 'Favorite');
    },
  }
}
</script>


<style lang="scss" scoped>
::v-deep .v-list-item__action {
  display: none !important;
}
</style>