<template>
  <v-btn
    height="30"
    block
    :loading="loading"
    class="reserve-btn"
    @click="selectedCast(castId, corporationId)"
    :disabled="this.$store.state.call.reserveBtnStatus"
  ><IconReserve class="reserve-icon" style="fill:#FFF;" />ご予約</v-btn>
</template>

<script>
import IconReserve from '~/assets/img/icon-reserve.svg';
export default {
  components: {
    IconReserve
  },
  props: [
    'castId',
    'corporationId',
  ],
  data: () => ({
    loading: false,
  }),
  methods: {
    selectedCast(castId, corporationId) {
      this.loading = true
      this.$store.dispatch('call/reserveBtnStatus', true);
      this.$store.dispatch('call/nominateCast', castId);
      this.$store.dispatch('call/selectedCorporationId', corporationId);

      // 予約モーダル
      this.loading = false;
      this.$store.dispatch('castDialog/selectedCastReserveDialog', true);
      //this.$router.push('/call/step2')
    }
  },
}
</script>

<style lang="scss" scoped>
.reserve-btn {
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  padding: .84rem 2.14rem;
  font-size: .91rem;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  border-radius: 10em;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  background: linear-gradient(40deg, #B22C27, #fc6262) !important;
  color: #fff;
  font-weight: bold;
  position: relative;
  .reserve-icon {
    position: absolute;
    top: 50%;
    left: 6%;
    transform: translateY(-50%);
  }
}
</style>

