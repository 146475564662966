<template>
    <v-dialog v-model="this.$store.state.dialog.helpFullReviewLimitDialog" max-width="400" transition="dialog-bottom-transition" style="z-index: 9999" persistent>
      <v-card class="pa-3 text-center" style="font-weight: bold">
        <div class="pa-3 text-left">
          {{ $t('text_z3dq') }}{{ this.$store.state.siteMemberData.maxHelpFullReview }}{{ $t('text_b1dq') }}<br/>
          {{ $t('reset_at_24_hours') }}<br/><br/>
        </div>
        <div class="px-3">
          <v-btn
            block
            small
            elevation="0"
            v-on:click="$store.dispatch('dialog/saveHelpFullReviewLimitDialogFlag', false)"
            style="color: var(--main-color); font-weight: bold"
            >{{ $t('close') }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
	props: [
  ],
  data: () => ({
  }),
}
</script>
