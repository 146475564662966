import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"px-5 mb-3"},[_c('div',{staticClass:"mb-3"},[_c('span',[_c(VIcon,[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(_vm.areaLabel))],1)]),_vm._v(" "),_c('div',{class:[
        'area-detail',
        'text-center',
        { 'mb-3': _vm.areaDetailNote === '' } ]},[_c('span',[_vm._v(_vm._s(_vm.areaDetail))])]),_vm._v(" "),(_vm.areaDetailNote)?_c('div',{staticClass:"area-detail-note mt-1 mb-3"},[_c('span',[_vm._v(_vm._s(_vm.areaDetailNote))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"btn",attrs:{"color":"#FFE9EE","elevation":"0"},on:{"click":function($event){_vm.areaSelectDialog = true}}},[_vm._v(_vm._s(_vm.$t('change')))])],1)]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-space-between align-center px-5 pt-2"},[_c('span',{staticStyle:{"font-size":"var(--read-font-size-s)","color":"var(--main-color)","font-weight":"bold"}},[_c(VIcon,{attrs:{"color":"#B22C27","small":""}},[_vm._v("mdi-circle-outline")]),_vm._v(_vm._s(_vm.$t('area_available'))),_c('br'),_vm._v(" "),_c(VIcon,{attrs:{"color":"#B22C27","small":""}},[_vm._v("mdi-triangle-outline")]),_vm._v(_vm._s(_vm.$t('change_available'))),_c('br'),_vm._v(" "),_c(VIcon,{attrs:{"color":"#B22C27","small":""}},[_vm._v("mdi-window-close")]),_vm._v(_vm._s(_vm.$t('not_available'))),_c('br')],1)]),_vm._v(" "),_c(VDialog,{attrs:{"content-class":"mx-2"},model:{value:(_vm.areaSelectDialog),callback:function ($$v) {_vm.areaSelectDialog=$$v},expression:"areaSelectDialog"}},[_c(VCard,[_c('AreaForm',{ref:"area",attrs:{"hotels":_vm.hotels,"hidden-home-tab":_vm.ngHomeBusinessTripFlag,"shop-maps":_vm.shopMaps,"isSaveDB":"true"},on:{"disabledBtn":_vm.disabledBtn}}),_vm._v(" "),_c(VBtn,{staticClass:"btn btn_center py-5",attrs:{"color":"#FFE9EE","elevation":"0","disabled":_vm.disabledSaveButton},on:{"click":_vm.changePlace}},[_vm._v(_vm._s(_vm.$t('change')))]),_vm._v(" "),_c('div',{staticStyle:{"height":"20px"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }