<template>
  <div class="today-cast-container">
    <div class="pa-2">
      <h2 class="primary--text d-flex align-center ma-3">
        <v-icon size="2rem" color="#B22C28" class="cast-like-button__icon"
          >mdi-calendar-today</v-icon
        >
        <span
          style="font-size: 16px; font-weight: bold; color: var(--main-color)"
          >{{ $t('text_4qpi') }}</span
        >
      </h2>
    </div>
    <div>
      <div v-if="todayCastLoading === true">
        <loading-image />
      </div>
      <div
        v-else-if="
          todayCastLoading === false &&
            this.$store.state.bindCast.todayCasts.length < 1
        "
        class="text-center"
        style="font-size: var(--read-font-size-s); font-weight: bold"
      >
        <span>{{ $t('text_m07y') }}</span>
      </div>
      <div class="scroll-container today-cast-slider-wrapper" v-else>
        <div
          v-for="cast in this.$store.state.bindCast.todayCasts"
          :key="cast.cast_id"
          style="margin: 5px 10px 5px 5px"
        >
          <div class="today-cast-wrapper" :class="'today-cast' + cast.cast_id">
            <TodayCastImageCard
              @toCastProfileDialog="toCastProfile(cast)"
              :cast="cast"
              :executeParentMethod="true"
              :imageMaxHeight="240"
            />
          </div>
        </div>
        <div
          v-if="nextPage"
          class="v-slide-group__content see_more"
          @click="getTodayCastsData(true)"
        >
          <btnMore />
        </div>
        <p
          v-else-if="todayCastPaginatorIsLast"
          class="v-slide-group__content last_card"
        >
          <btnEnd />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import TodayCastImageCard from '~/components/organisms/castImageCard/TodayCastImageCard.vue';
import UpdateBtn from '~/components/pages/home/UpdateBtn.vue';
import btnMore from '~/assets/img/btn-more.svg';
import btnEnd from '~/assets/img/btn-end.svg';

export default {
  components: {
    TodayCastImageCard,
    UpdateBtn,
    btnMore,
    btnEnd,
  },
  mounted() {},
  data: () => ({
    todayCastLoading: false,
    todayCastPaginator: null,
  }),
  computed: {
    nextPage() {
      if (!this.todayCastPaginator || this.todayCastPaginatorIsLast) {
        return null;
      }
      if (
        this.todayCastPaginator.current_page < this.todayCastPaginator.last_page
      ) {
        return this.todayCastPaginator.current_page + 1;
      }
    },
    todayCastPaginatorIsLast() {
      if (this.todayCastPaginator == null) {
        return false;
      }
      return (
        this.todayCastPaginator.current_page ==
        this.todayCastPaginator.last_page
      );
    },
  },
  methods: {
    toCastProfile(cast) {
      const target = document.getElementsByClassName(
        'today-cast-slider-wrapper'
      )[0].childNodes[0];
      this.$store.dispatch('castDialog/sliderScrollX', target.scrollLeft);
      this.$store.dispatch(
        'castDialog/sliderScrollXTargetId',
        'today-cast-slider-wrapper'
      );
      this.toCastProfileDialog(cast);
    },
    getTodayCastsData: function(isPaginate) {
      if (!isPaginate) {
        this.todayCastLoading = true;
        this.$store.dispatch('bindCast/saveTodayCastsData', []);
        // フォロワーカウント
        this.$store.dispatch(
          'followerCountData/saveTodayCastsFollowerCountData',
          []
        );
      }
      const params = {
        siteMemberId: this.$store.state.siteMemberData.id,
        page: isPaginate ? this.nextPage : null,
      };
      this.$axios
        .post('public/today-casts', params, {})
        .then((response) => {
          this.todayCastPaginator = response.data;
          this.$store.dispatch(
            'bindCast/saveTodayCastsData',
            this.$store.state.bindCast.todayCasts.concat(response.data.data)
          );
          // フォロワーカウント整形
          const followerCount = this.pickFollowerCountData(response.data.data);
          // フォロワーカウント
          this.$store.dispatch(
            'followerCountData/saveTodayCastsFollowerCountData',
            this.$store.state.followerCountData.todayCasts.concat(followerCount)
          );
        })
        .finally(() => {
          this.todayCastLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.today-cast-container {
  .today-cast-wrapper {
    height: 380px;
    width: 160px;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
  }
  .select-box-label {
    font-size: 13px;
    font-weight: bold;
    color: var(--main-title-color);
  }
  .reason-items {
    ::v-deep .v-label {
      color: var(--main-title-color) !important;
      font-size: 13px;
      font-weight: bold;
    }
  }
  .last_card {
    margin: auto 10px;
  }
  .see_more {
    cursor: pointer;
    margin: auto 10px;
  }
  ::v-deep .v-input__append-inner {
    margin: 0 !important;
    margin-top: 4px !important;
  }
  ::v-deep .v-input__control {
    height: 42px !important;
  }
  ::v-deep .v-select__selection--comma {
    color: var(--main-title-color) !important;
    font-size: 13px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .today-cast-wrapper {
    height: 300px;
    width: 163px;
  }
}

.scroll-container {
  display: flex;
  overflow-x: scroll;
  width: 100%;
}

.scroll-item {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {
/* Chrome, Safari and Opera */
.scroll-container::-webkit-scrollbar {
  display: none;
}

/* Firefox */
.scroll-container {
  scrollbar-width: none;
}

/* Internet Explorer and Edge */
.scroll-container {
  -ms-overflow-style: none;
}
}
</style>
