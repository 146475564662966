import _ from 'lodash';

export default {
  data() {
    return {
      accessToken: '',
    }
  },
  methods: {
    async getCastRanking(rankingType, params) {
      const accessToken = this.$auth.$storage.getUniversal(
        "_token.auth0",
        true
      );
      await this.$axios.post("public/ranking-casts", params, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        this.setRankingData(rankingType, response)
        this.setPage(rankingType, response, params);
      })
      .catch((error) => {
        console.log(error);
      });
    },
    async getTotalCastRanking(rankingType, params) {
      await this.$axios.post("public/total-ranking-casts", params, {
        headers: {
          Authorization: this.accessToken,
        },
      })
      .then((response) => {
        this.setRankingData(rankingType, response)
        this.setPage(rankingType, response, params);
      })
      .catch((error) => {
        console.log(error);
      });
    },
    setPage(rankingType, response, params) {
      // 3ページまで読み込み
      const page = response.data.length ? params.page + 1 : 4;
      this.$store.dispatch(`ranking/commitPage${rankingType}`, page);
    },
    setRankingData(rankingType, response) {
      this.$store.dispatch(`ranking/saveRankingCast${rankingType}Data`, response.data);
      // フォロワーカウント整形
      const followerCount = this.pickFollowerCountDataForRanking(response.data);
      // フォロワーカウント
      this.$store.dispatch(
        `followerCountData/saveRankingCast${rankingType}FollowerCountData`,
        followerCount
      );
    },
    getSelectBoxShopData: async function (genreIds, prefectureIds) {
      let results = [];
      const params = {
        genreIds: genreIds,
        prefectureIds: prefectureIds,
      };
      await this.$axios
        .post("public/get-select-box-shop-list", params, {
          headers: {
            Authorization: this.$auth.$storage.getUniversal(
              "_token.auth0",
              true
            ),
          },
        })
        .then((response) => {
          results = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      return results;
    },
    /**
     * 検索条件保存
     */
    async updateOrGetSearchItems(updateFlag, rankingName) {
      if (!this.$store.state.auth.loggedIn) {
        return;
      }
      const params = {
        genreIds: this.$store.state.ranking[`selectedGenres${rankingName}`],
        areaIds: this.$store.state.ranking[`selectedAreas${rankingName}`],
        shopId: this.$store.state.ranking[`selectedShop${rankingName}`],
        updateFlag: updateFlag,
        uniqueKey: rankingName,
        siteMemberId: this.$store.state.siteMemberData.id,
      }
      if (rankingName === 'Desination') {
        params['freeTimeExists'] = this.$store.state.ranking.freeTimeExists;
      }
      if (rankingName === 'Favorite' || rankingName === 'PhotoDiary') {
        params['termTypeId'] = this.$store.state.ranking[`selectedTermType${rankingName}`];
      }
      const accessToken = this.$auth.$storage.getUniversal('_token.auth0', true);
      await this.$axios.post('private/update-or-get-search-conditions', params, {
        headers: {
          Authorization: `${accessToken}`
        },
      }).then((response) => {
        this.setSearchItems(response.data);
      }).catch(error => {
        console.log(error);
      });
    },
    setSearchItems(items) {
      const rankingName = items.uniqueKey;
      if (rankingName === 'Desination') {
        this.$store.dispatch('ranking/saveFreeTimeExists', items.freeTimeExists);
      }
      if (rankingName === 'Favorite' || rankingName === 'PhotoDiary') {
        this.$store.dispatch(`ranking/saveSelectedTermType${rankingName}`, items.termTypeId);
      }
      this.$store.dispatch(`ranking/saveSelectedGenres${rankingName}`, items.genreIds);
      this.$store.dispatch(`ranking/saveSelectedAreas${rankingName}`, items.areaIds);
      this.$store.dispatch(`ranking/saveSelectedShop${rankingName}`, items.shopId);
    },
    /**
     * values array object
     */
    copyDeep(values) {
      return _.cloneDeep(values)
    },
    /**
     * values [{id: 1, name: 'aaa'}]
     * 指定したkeyのみを取り出して配列で返す
     */
    pluck(values, key) {
      return _.map(values, key);
    }
  }
}