import {
  mapState
} from "vuex";
export default {
  computed: {
    ...mapState("call", [
      "genreValue",
      "budgetValue",
      "search_cast_budget_amount_min",
      "search_cast_budget_amount_max",
      "selectedShopIds",
      "selectedShopObj",
      "selectedCastIds",
      "selectedCastObj",
      "onlyFavoriteCasts",
      "castAgeValue",
      "search_cast_age_min",
      "search_cast_age_max",
      "castHeightValue",
      "search_cast_height_min",
      "search_cast_height_max",
      "castCupValue",
      "search_cast_cup_min",
      "search_cast_cup_max",
      "castSmValue",
      "search_cast_s_degree",
      "search_cast_m_degree",
      "ngSmokeValue",
      "ngTattooValue",
      "ngBodyPiercingFlag",
      "searchUnderHairType",
      "favaliteTagIds",
      "newCastFlag",
      "searchDrinkType",
      "profilePlayOptionCategoryIds",
      "selectPlace",
      "areaValue",
      "searchPrefectureId",
      "sortType",
      "mapAreaId",
      "selectedHotelId",
      "selectedHotel",
    ]),
  },
  methods: {
    /**
     * 探すの検索条件をlaravel側のcache(redis)に保存
     */
    async updateOrGetSearchItems(updateFlag) {
      const params = {
        updateFlag: updateFlag,
        siteMemberId: this.$store.state.siteMemberData.id,
        genreIds: this.genreValue,
        budgetValue: this.budgetValue,
        budgetAmountMin: this.search_cast_budget_amount_min,
        budgetAmountMax: this.search_cast_budget_amount_max,
        shopIds: this.selectedShopIds,
        selectedShops: this.selectedShopObj,
        castIds: this.selectedCastIds,
        selectedCasts: this.selectedCastObj,
        onlyFavoriteCasts: this.onlyFavoriteCasts,
        ageValue: this.castAgeValue,
        ageMin: this.search_cast_age_min,
        ageMax: this.search_cast_age_max,
        heightValue: this.castHeightValue,
        heightMin: this.search_cast_height_min,
        heightMax: this.search_cast_height_max,
        cupValue: this.castCupValue,
        cupMin: this.search_cast_cup_min,
        cupMax: this.search_cast_cup_max,
        smValue: this.castSmValue,
        smMin: this.search_cast_s_degree,
        smMax: this.search_cast_m_degree,
        favoriteTagIds: this.favaliteTagIds,
        ngSmokeValue: this.ngSmokeValue,
        ngTattooValue: this.ngTattooValue,
        ngBodyPiercingFlag: this.ngBodyPiercingFlag,
        newCastFlag: this.newCastFlag,
        underHairType: this.searchUnderHairType,
        drinkType: this.searchDrinkType,
        optionCategoryIds: this.profilePlayOptionCategoryIds,
        mapAreaId: this.mapAreaId,
        selectedHotelId: this.selectedHotelId,
        selectedHotel: this.selectedHotel,
        sortType: this.sortType,
        selectPlace: this.selectPlace,
        areaValue: this.areaValue,
        prefectureId: this.searchPrefectureId,
      }
      const accessToken = this.$auth.$storage.getUniversal('_token.auth0', true);
      await this.$axios.post('private/update-or-get-call-search-items', params, {
        headers: {
          Authorization: `${accessToken}`
        },
      }).then((response) => {
        this.setSearchItems(response.data);
      }).catch(error => {});
    },
    /**
     * callのstoreに検索条件をセット
     */
    setSearchItems(searchItems) {
      this.$store.dispatch('call/genreValue', searchItems.genreIds);
      this.$store.dispatch('call/castBudgetUpdate', {
        search_cast_budget_amount_min: searchItems.budgetAmountMin,
        search_cast_budget_amount_max: searchItems.budgetAmountMax,
        budgetValue: Object.assign([], searchItems.budgetValue),
      });
      this.$store.dispatch("call/selectedShopIds", searchItems.shopIds);
      this.$store.dispatch("call/selectedShopObj", searchItems.selectedShops);
      this.$store.dispatch("call/selectedCastUpdate", {
        selectedCastIds: searchItems.castIds,
        selectedCastObj: searchItems.selectedCasts
      });
      this.$store.dispatch(
        'call/onlyFavoriteCasts',
        searchItems.onlyFavoriteCasts
      );
      this.$store.dispatch('call/castAttributeUpdate', {
        search_cast_age_min: searchItems.ageMin,
        search_cast_age_max: searchItems.ageMax,
        search_cast_height_min: searchItems.heightMin,
        search_cast_height_max: searchItems.heightMax,
        search_cast_cup_min: searchItems.cupMin,
        search_cast_cup_max: searchItems.cupMax,
        search_cast_s_degree: searchItems.smMin,
        search_cast_m_degree: searchItems.smMax,
        castAgeValue: searchItems.ageValue,
        castHeightValue: searchItems.heightValue,
        castCupValue: searchItems.cupValue,
        castSmValue: searchItems.smValue,
      });
      this.$store.dispatch("call/favaliteTagIds", searchItems.favoriteTagIds);
      this.$store.dispatch("call/ngSmokeValue", searchItems.ngSmokeValue);
      this.$store.dispatch("call/ngTattooValue", searchItems.ngTattooValue);
      this.$store.dispatch("call/ngBodyPiercingFlag", searchItems.ngBodyPiercingFlag);
      this.$store.dispatch("call/newCastFlag", searchItems.newCastFlag);
      this.$store.dispatch("call/searchUnderHairType", searchItems.underHairType);
      this.$store.dispatch("call/searchDrinkType", searchItems.drinkType);
      this.$store.dispatch("call/commitProfilePlayOptionCategoryIds", searchItems.optionCategoryIds);
      // 新規で保存させたいデータは↓のようにチェックを入れる
      if (searchItems.hasOwnProperty('selectedHotelId')) {
        this.$store.dispatch("call/selectedHotelId", searchItems.selectedHotelId);
      }
      if (searchItems.hasOwnProperty('selectedHotel')) {
        this.$store.dispatch("call/selectedHotel", searchItems.selectedHotel);
      }
      if (searchItems.hasOwnProperty('mapAreaId')) {
        this.$store.dispatch("call/mapAreaId", searchItems.mapAreaId);
      }
      if (searchItems.hasOwnProperty('sortType')) {
        this.$store.dispatch("call/sortType", searchItems.sortType);
      }
      if (searchItems.hasOwnProperty('selectPlace')) {
        this.$store.dispatch("call/selectPlace", searchItems.selectPlace);
      }
      if (searchItems.hasOwnProperty('areaValue')) {
        this.$store.dispatch("call/areaValue", searchItems.areaValue);
      }
      if (searchItems.hasOwnProperty('prefectureId')) {
        this.$store.dispatch("call/searchPrefectureId", searchItems.prefectureId);
      }
    }
  }
}