<template>
  <div>
    <div class="px-5 mb-3">
      <div class="mb-3">
        <span><v-icon>mdi-map-marker</v-icon> {{ areaLabel }}</span>
      </div>
      <div
        :class="[
          'area-detail',
          'text-center',
          { 'mb-3': areaDetailNote === '' },
        ]"
      >
        <span>{{ areaDetail }}</span>
      </div>
      <div v-if="areaDetailNote" class="area-detail-note mt-1 mb-3">
        <span>{{ areaDetailNote }}</span>
      </div>
      <div class="text-center">
        <v-btn
          class="btn"
          color="#FFE9EE"
          elevation="0"
          @click="areaSelectDialog = true"
          >{{ $t('change') }}</v-btn
        >
      </div>
    </div>
    <div class="d-flex justify-space-between align-center px-5 pt-2">
      <span
        style="font-size: var(--read-font-size-s);color: var(--main-color);font-weight: bold;"
      >
        <v-icon color="#B22C27" small>mdi-circle-outline</v-icon
        >{{ $t('area_available') }}<br />
        <v-icon color="#B22C27" small>mdi-triangle-outline</v-icon
        >{{ $t('change_available') }}<br />
        <v-icon color="#B22C27" small>mdi-window-close</v-icon
        >{{ $t('not_available') }}<br />
      </span>
    </div>
    <!-- エリア変更モーダル -->
    <v-dialog v-model="areaSelectDialog" content-class="mx-2">
      <v-card>
        <AreaForm
          ref="area"
          :hotels="hotels"
          :hidden-home-tab="ngHomeBusinessTripFlag"
          :shop-maps="shopMaps"
          @disabledBtn="disabledBtn"
          isSaveDB="true"
        />
        <v-btn
          class="btn btn_center py-5"
          color="#FFE9EE"
          elevation="0"
          @click="changePlace"
          :disabled="disabledSaveButton"
          >{{ $t('change') }}</v-btn
        >
        <div style="height: 20px" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AreaForm from '~/components/pages/call/AreaForm.vue';
import Mixin from '~/pages/call/mixin';
import _ from 'lodash';

export default {
  name: 'SelectedAddress',
  props: ['isLoadingSchedule', 'shopMaps'],
  components: {
    AreaForm,
  },
  mixins: [Mixin],
  data() {
    return {
      hotels: [],
      areaSelectDialog: false,
      disabledSaveButton: false,
      areaDetailNote: '',
      selectLocale: null,
    };
  },
  async created() {
    await this.updateOrGetSearchItems(false);
    this.getAreaHotels();
  },
  computed: {
    areaLabel: function() {
      if (
        this.$store.state.call.selectPlace === 'selected-home' &&
        this.$store.state.siteMemberData.home_hope_flag === 1
      ) {
        return this.$t('atHome');
      } else {
        return this.$t('hotel');
      }
    },
    areaDetail() {
      if (this.$i18n.locale === 'zh-cn') {
        this.selectLocale = 'zh_cn'
      } else if (this.$i18n.locale === 'zh-tw') {
        this.selectLocale = 'zh_tw'
      } else if (this.$i18n.locale === 'ko') {
        this.selectLocale = 'ko'
      } else {
        this.selectLocale = 'en'
      }
      // 選択されているのが自宅なら住所、ホテルならホテル名返す
      if (
        this.$store.state.call.selectPlace === 'selected-home' &&
        this.$store.state.siteMemberData.home_hope_flag === 1
      ) {
        return (
          this.$store.state.siteMemberData.home_city_text +
          ' ' +
          this.$store.state.siteMemberData.home_address +
          ' ' +
          this.$store.state.siteMemberData.home_building +
          ' ' +
          this.$store.state.siteMemberData.home_room_number
        );
      } else if (this.$store.state.call.selectPlace === 'selected-hotel') {
        if (this.$store.state.call.selectedHotel) {
          const selectedHotel =
            Object.keys(this.$store.state.call.selectedHotel).length > 0
              ? this.$store.state.call.selectedHotel
              : this.hotels.find((v) => v.name.match(/未定/));
          this.areaDetailNote =
            selectedHotel && selectedHotel.note ? selectedHotel.note : ''; //選択したホテルに注意事項があれば取得
          return selectedHotel
            ? selectedHotel['name_' + this.selectLocale] ||
                selectedHotel.name ||
                '(' + this.$t('undecided') + ')'
            : '(' + this.$t('undecided') + ')';
        } else {
          return '(' + this.$t('undecided') + ')';
        }
      }
    },
    ngHomeBusinessTripFlag() {
      return _.get(
        this.$store.state.bindCast,
        'cast.talent.ng_home_business_trip_flag'
      );
    },
  },
  methods: {
    none() {},
    getAreaHotels() {
      const areaId = this.$store.state.siteMemberData.area_ids || 6;
      this.$axios
        .post(
          `public/hotel-areas`,
          {
            areaId: areaId,
          },
          {}
        )
        .then(async (response) => {
          if (response.data) {
            this.hotels = response.data.hotel_areas;
          }
        })
        .catch((error) => {});
    },
    async changePlace() {
      // スケジュール読込中なら処理中断
      if (this.isLoadingSchedule) {
        return;
      }
      // 選択した自宅・ホテル情報をストアに保存
      this.$refs.area.saveStore();
      this.updateOrGetSearchItems(true);
      //スケジュールAPIの取得し直し
      this.$emit('reloadSchedule');
      //ダイアログを閉じる
      this.areaSelectDialog = false;
    },
    disabledBtn: function(event) {
      if (
        this.$store.state.siteMemberData.selectPlace === 'selected-home' &&
        this.$store.state.siteMemberData.home_hope_flag === 0
      ) {
        this.disabledSaveButton = true;
      } else {
        this.disabledSaveButton = event;
      }
    },
    hotelNameTranslate(item) {
      return item[`name` + this.$i18n.locale] || item.name;
    },
  },
};
</script>
<style scoped>
#flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.label {
  font-size: var(--read-font-size-xs);
  color: var(--read-font-color-sub);
}
.dialog {
  width: 50vw;
}

.btn {
  width: 156px;
  height: 42px;
  box-sizing: border-box;
  line-height: 42px;
  padding: 0 16px;
  border-radius: 21px;
  color: var(--main-color);
  font-weight: bold;
  cursor: default;
  font-size: var(--read-font-size-s);
}

.btn_center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.area-detail {
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  color: var(--read-font-color-primary);
  background-color: var(--main-bg-color);
  font-size: 16px;
  border-radius: 7px;
  font-weight: bold;
}

.area-detail-note {
  line-height: 20px;
  color: var(--read-font-color-primary);
  font-size: var(--read-font-size-s);
}
</style>
