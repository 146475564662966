<template>
  <div>
    <div
      class="text-center mb-2"
      style="font-size: 14px; font-weight: bold; color: #fff"
    >
      {{ $t('available_areas') }}
    </div>
    <!-- 東京都、神奈川県、埼玉県、千葉県の順　ソートができないため直書き -->
    <div class="mb-3" v-if="areas[$t('tokyo')]">
      <div
        class="mb-1 text-center"
        style="font-size: 14px; font-weight: bold; color: #fff"
      >
        {{ $t('tokyo') }}
      </div>
      <span
        v-for="(city, i) in areas[$t('tokyo')]"
        :key="i"
        class="mr-2 ma-0 pa-0"
        style="font-size: 12px; font-weight: bold; color: #fff"
      >
        {{ city }}
      </span>
    </div>
    <div class="mb-3" v-if="areas[$t('kanagawa')]">
      <div
        class="mb-1 text-center"
        style="font-size: 14px; font-weight: bold; color: #fff"
      >
        {{ $t('kanagawa') }}
      </div>
      <span
        v-for="(city, i) in areas[$t('kanagawa')]"
        :key="i"
        class="mr-2 ma-0 pa-0"
        style="font-size: 12px; font-weight: bold; color: #fff"
      >
        {{ city }}
      </span>
    </div>
    <div class="mb-3" v-if="areas[$t('saitama')]">
      <div
        class="mb-1 text-center"
        style="font-size: 14px; font-weight: bold; color: #fff"
      >
        {{ $t('saitama') }}
      </div>
      <span
        v-for="(city, i) in areas[$t('saitama')]"
        :key="i"
        class="mr-2 ma-0 pa-0"
        style="font-size: 12px; font-weight: bold; color: #fff"
      >
        {{ city }}
      </span>
    </div>
    <div class="mb-3" v-if="areas[$t('chiba')]">
      <div
        class="mb-1 text-center"
        style="font-size: 14px; font-weight: bold; color: #fff"
      >
        {{ $t('chiba') }}
      </div>
      <span
        v-for="(city, i) in areas[$t('chiba')]"
        :key="i"
        class="mr-2 ma-0 pa-0"
        style="font-size: 12px; font-weight: bold; color: #fff"
      >
        {{ city }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getAreaName(address) {
      if (!address) return;
      const result = address.split(' ');
      return result[result.length - 1];
    },
  },
  props: {
    dispatchableAreas: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    areas: [],
  }),
  mounted: function() {
    this.areas = this.formatData(this.dispatchableAreas);
  },
  methods: {
    formatData: function(arr) {
      const obj = {};
      arr.forEach((item) => {
        item = this.$t('areas.' + item + '.address');
        item = item.replace(/,/, '');
        const [prefecture, ...city] = item.split(' ');
        if (!obj[prefecture]) {
          obj[prefecture] = [];
        }
        obj[prefecture].push(city.join(' '));
      });
      return obj;
    },
  },
};
</script>
