<template>
  <v-main style="background: var(--new-main-bg-color) !important">
    <div class="mb-3 pb-3" style="background: var(--sub-bg-color)">
      <!-- ユーザー情報 -->
      <!-- <v-container class="pa-0">
        <AboutSiteMemberInfo ref="aboutSiteMemberInfo" />
      </v-container> -->
      <!-- オススメキャスト -->
      <v-container class="pa-0">
        <RecommendedCast ref="recommendedCast" />
      </v-container>
      <!-- ゲストバナー -->
      <!-- <v-container
        v-show="$store.state.auth.loggedIn === false && $ua.isFromPc() === true"
        class="pa-1 ma-0"
      >
        <v-card elevation="0">
          <a href="https://lp.tokyoescort-otome.com/sp/lp/" target="_blank"
            ><img width="100%" :src="guestBunnerPc" alt=""
          /></a>
        </v-card>
      </v-container> -->
      <!-- <v-container
        v-show="
          $store.state.auth.loggedIn === false && $ua.isFromPc() === false
        "
        class="pa-1 ma-0"
      >
        <v-card elevation="0">
          <a href="https://lp.tokyoescort-otome.com/sp/lp/" target="_blank"
            ><img width="100%" :src="guestBunnerSp" alt=""
          /></a>
        </v-card>
      </v-container> -->
      <!-- 新人キャスト本日出勤&出勤3回以内 -->
      <v-container class="pa-0">
        <TodayCast ref="todayCast" />
      </v-container>
    </div>
    <!-- 新人キャスト -->
    <!-- <v-container class="pa-0 mb-3 py-3" style="background: var(--sub-bg-color)">
      <NewCast ref="newCast" />
    </v-container> -->
    <!-- 新着クチコミ -->
    <!-- <v-container class="pa-0 mb-3 py-3" style="background: var(--sub-bg-color)">
      <NewCastReview ref="newCastReview" />
    </v-container> -->
    <!-- ランキング -->
    <v-container class="pa-0 mb-3">
      <Ranking ref="ranking" />
    </v-container>
    <!-- Tokyo Escort OTOME会員ランキング -->
    <!-- <v-container class="pa-0">
      <SiteMemberRanking ref="siteMemberRanking" />
    </v-container> -->
    <!-- <div
      class="pt-2"
      style="
        width: 100%;
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;
        background-color: var(--sub-bg-color);
      "
    >
      <a href="https://lp.tokyoescort-otome.com/sp/how/" target="_blank"
        ><img width="100%" src="~/assets/img/bn.png" alt=""
      /></a>
    </div> -->
    <!-- Error Modal -->
    <v-dialog
      v-model="errorDialog"
      max-width="350"
      persistent
      no-click-animation
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card
        class="pa-4"
        color="primary"
        style="color: #fff; font-size: var(--read-font-size-m)"
      >
        <v-row>
          <v-col class="pa-0" cols="2">
            <v-layout column justify-center fill-height>
              <v-container class="pa-0 text-center">
                <v-icon color="white">mdi-alert</v-icon>
              </v-container>
            </v-layout>
          </v-col>
          <v-col class="pa-1" cols="10">
            <div>{{ $t('login_failed') }}<br />{{ $t('start_over') }}</div>
          </v-col>
        </v-row>
        <div class="text-right">
          <v-btn small class="mt-4"
            ><a :href="logoutUrl" style="text-decoration: none">{{
              $t('back_to_top')
            }}</a></v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <!-- nicknameErrorMessageDialog -->
    <v-dialog
      v-model="nicknameErrorMessageDialog"
      max-width="400"
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card class="pa-3 text-center" style="font-weight: bold">
        <div class="pa-3 primary--text text-left">
          {{ $t('change_nickname') }}
        </div>
        <div class="pa-3 text-left">{{ $t('text_ev2w') }}<br /><br /></div>
        <div class="px-3">
          <v-btn
            block
            small
            elevation="0"
            :to="`/${$i18n.locale}/account/profile-edit`"
            v-on:click="
              nicknameErrorMessageDialog = !nicknameErrorMessageDialog
            "
            style="color: var(--main-color); font-weight: bold"
            >{{ $t('close') }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <!-- givingPointDialog -->
    <v-dialog
      v-model="this.$store.state.dialog.givingPointDialog"
      max-width="400"
      transition="dialog-bottom-transition"
      style="z-index: 9999"
      content-class="register-point__dialog"
      no-click-animation
      persistent
    >
      <div>
        <div class="text-right">
          <v-icon
            size="1.5rem"
            color="#fff"
            v-on:click="seveGivingPointConfirmed('close')"
          >
            mdi-window-close
          </v-icon>
        </div>
        <img
          :src="emitPointBn.img"
          v-on:error="pointBn1000"
          v-on:click="seveGivingPointConfirmed('succession')"
          style="width: 100%"
          contain
        />
      </div>
    </v-dialog>
    <!-- キャスト評価ダイアログ -->
    <cast-evaluation-dialog />
    <hotel-alert-dialog />
    <CastProfileDialog />
    <CastReserveDialog />
    <!-- <HelpFullReviewLimitDialog /> -->
    <!-- <HelpFullReviewLimitDateDialog /> -->
    <ProhibitedUnder18Dialog />

    <v-overlay :value="this.$store.state.dialog.helpFullButtonOverlay">
      <loading-image />
    </v-overlay>
    <div v-if="this.$store.state.auth.loggedIn === false">
      <p class="vanila-ad">
        <a rel="nofollow" href="https://kanto.qzin.jp/tokyoescort/?v=official" target="_blank">
          <img src="https://ad.qzin.jp/img/vanilla468-60.gif" style="width: 100%;" border="0" alt="Tokyo Escort OTOME(ユメオト)で稼ぐならバニラ求人">
        </a>
      </p>
    </div>
  </v-main>
</template>

<script>
import RankingSlider from '~/components/pages/home/RankingSlider.vue';
import CastProfileDialog from '~/components/pages/dialog/CastProfileDialog.vue';
import CastReserveDialog from '~/components/pages/dialog/CastReserveDialog.vue';
// import AboutSiteMemberInfo from '~/components/pages/home/AboutSiteMemberInfo.vue';
import RecommendedCast from '~/components/pages/home/RecommendedCast.vue';
import NewCast from '~/components/pages/home/NewCast.vue';
import TodayCast from '~/components/pages/home/TodayCast.vue';
// import NewCastReview from '~/components/pages/home/NewCastReview.vue';
import Ranking from '~/components/pages/home/Ranking.vue';
// import HelpFullReviewLimitDialog from '~/components/pages/dialog/HelpFullReviewLimitDialog.vue';
// import HelpFullReviewLimitDateDialog from '~/components/pages/dialog/HelpFullReviewLimitDateDialog.vue';
import ProhibitedUnder18Dialog from '~/components/pages/dialog/ProhibitedUnder18Dialog.vue';
// import SiteMemberRanking from '~/components/pages/home/SiteMemberRanking.vue';
import noimage_m from '~/assets/img/noimage_m.png';
import noimage_p from '~/assets/img/noimage_p.png';
import ngimage_m from '~/assets/img/ngimage_m.png';
import ngimage_p from '~/assets/img/ngimage_p.png';
import pointBn1000 from '~/assets/img/point-bn-1000.png';
import pointBn2000 from '~/assets/img/point-bn-2000.png';
import pointBn3000 from '~/assets/img/point-bn-3000.png';
import pointBn4000 from '~/assets/img/point-bn-4000.png';
import pointBn5000 from '~/assets/img/point-bn-5000.png';
import pointBn6000 from '~/assets/img/point-bn-6000.png';
import pointBn7000 from '~/assets/img/point-bn-7000.png';
import guestBunnerPc from '~/assets/img/guest-banner-pc.png';
import guestBunnerSp from '~/assets/img/guest-banner-sp.png';
import Mixin from '~/pages/call/mixin';
import _ from 'lodash';

const moment = require('moment');

export default {
  auth: false,
  components: {
    RankingSlider,
    CastProfileDialog,
    CastReserveDialog,
    // AboutSiteMemberInfo,
    RecommendedCast,
    NewCast,
    TodayCast,
    // NewCastReview,
    Ranking,
    // HelpFullReviewLimitDialog,
    // HelpFullReviewLimitDateDialog,
    ProhibitedUnder18Dialog,
    // SiteMemberRanking,
  },
  head() {
    return {
      title: this.$t('text_r0ii'),
      titleTemplate: '',
      meta: [
        { hid: 'og:title', property: 'og:title', content: this.$t('text_r0ii') },
        { hid: 'description', name: 'description', content: this.$t('text_dk7s') },
        { hid: 'og:description', property: 'og:description', content: this.$t('text_dk7s') }
      ],
    }
  },
  mixins: [Mixin],
  created: async function () {},
  mounted: async function () {
    // siteMember
    if (
      !this.$store.state.siteMemberData.id &&
      this.$store.state.auth.loggedIn === true
    ) {
      /**
       * 会員情報取得
       */
      await this.getSiteMemberData();
      /**
       * ログアウト時のラストURLを引き継いでリダイレクト
       * / のときは除外
       */
      const uri = this.$store.state.browserUI.logoutLastUri.replace(/^\/(en|zh-cn|zh-tw|ko)/, '');
      if (uri) {
        // ラストURLを取得
        const logoutLastUri = this.$store.state.browserUI.logoutLastUri;
        // ラストURLをクリア
        this.$store.dispatch('browserUI/commitLogoutLastUri', '');
        // ラストURLにリダイレクト
        this.$router.push(logoutLastUri);
        return;
      }
    }
    /**
     * ajax
     */
    this.getAjaxData();
  },
  data() {
    return {
      recommendSettingDialog: false,
      errorDialog: false,
      newCastMessageDialog: false,
      nicknameErrorMessageDialog: false,
      noimage_m: noimage_m,
      noimage_p: noimage_p,
      ngimage_m: ngimage_m,
      ngimage_p: ngimage_p,
      pointBn1000: pointBn1000,
      pointBnList: [
        { point: 1000, img: pointBn1000 },
        { point: 2000, img: pointBn2000 },
        { point: 3000, img: pointBn3000 },
        { point: 4000, img: pointBn4000 },
        { point: 5000, img: pointBn5000 },
        { point: 6000, img: pointBn6000 },
        { point: 7000, img: pointBn7000 },
      ],
      guestBunnerPc: guestBunnerPc,
      guestBunnerSp: guestBunnerSp,
      emitPointBn: [],
      logoutUrl: `https://${process.env.AUTH0_DOMAIN}/v2/logout?returnTo=${process.env.APP_URL}/${this.$i18n.defaultLocale}/logout&client_id=${process.env.AUTH0_CLIENT_ID}`,
    };
  },
  computed: {
    cupList: function () {
      return this.$store.state.call.cupList;
    },
  },
  methods: {
    /**
     * Ajax
     */
    getAjaxData: async function () {
      // 毎回読み込む（エリア変更などが反映されるようにするため）
      // グレード・スター・所持ポイントの情報
      if (this.$store.state.auth.loggedIn === true) {
        this.getSiteMemberPoint();
        // this.getFollowSiteMemberLimit();
        // 探すで保存した条件をcallにセット
        await this.updateOrGetSearchItems(false);
      }
      // TOP女性表示
      this.searchCastTop();
      // 本日出勤キャスト３回以内表示
      this.getTodayCasts();
      // 新人キャスト表示　ページネーションの処理を正しく動作させるために毎回読み込む
      // this.getNewCasts();
      // 初回ロード時、またはロード後5分経過した場合に実行
      if (
        this.$store.state.ssr.firstLoadTime === '' ||
        this.$store.state.ssr.firstLoadTime <
          this.$moment().subtract(20, 'minutes').format('YYYY-MM-DD HH:mm:ss')
      ) {
        // ランキング
        this.getCastRankings();
        // クチコミ取得
        //this.getCastReviews();
        // Tokyo Escort OTOME会員ランキング
        //this.getSiteMemberRankings();
        if (this.$store.state.auth.loggedIn) {
          // クチコミに対する新着参考になった、新着フォロワー数を取得
          // this.getHelpfullAndFollowerUnconfirmedCount();
          // // お知らせ通知取得
          // this.getAjaxNoticeNotification();
          // ブラック情報を更新
          this.getAjaxBlackInfo();
          // 予約通知情報を更新
          this.getAjaxReserveNotification();
          // チャット通知情報を更新
          this.getAjaxChatNotification();
        }
        this.$store.dispatch(
          'ssr/firstLoadTime',
          this.$moment().format('YYYY-MM-DD HH:mm:ss')
        );
      }
    },
    /**
     * クチコミ取得
     */
    // getCastReviews: async function() {
    //   this.$refs['newCastReview'].getCastReviewData();
    // },
    /**
     * Tokyo Escort OTOME会員ランキング
     */
    // getSiteMemberRankings: async function() {
    //   this.$refs['siteMemberRanking'].getSiteMemberRankings();
    // },
    /**
     * 会員情報をStoreにセットする
     */
    getSiteMemberData: async function () {
      this.$store.dispatch('loading/login', true);
      await this.$axios
        .post(
          `private/site-member`,
          {
            email: `${this.$store.state.auth.user.email}`,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode == 400) {
            // ユーザー情報が存在しない時はリダイレクト
            this.$router.push(`/${this.$i18n.locale}/register`);
          }
          const data = {
            id: response.data.id,
            uuid: response.data.uuid,
            tel: response.data.tel,
            nickname: response.data.nickname,
            selfIntroduction: response.data.self_introduction,
            imageFilename: response.data.image_filename,
            profileImageCheckStatus: response.data.profile_image_check_status,
            point: response.data.point,
            area_ids: response.data.area_ids,
            reservationNgFlag: response.data.reservation_ng_flag,
            noUseFlag: response.data.no_use_flag,
            areaId: response.data.area_ids,
            subAreaId: response.data.sub_area_id,
            search_cast_age_min: response.data.search_cast_age_min,
            search_cast_age_max: response.data.search_cast_age_max,
            search_cast_height_min: response.data.search_cast_height_min,
            search_cast_height_max: response.data.search_cast_height_max,
            search_cast_cup_min: response.data.search_cast_cup_min,
            search_cast_cup_max: response.data.search_cast_cup_max,
            search_cast_s_degree: response.data.search_cast_s_degree,
            search_cast_m_degree: response.data.search_cast_m_degree,
            home_hope_flag: response.data.home_hope_flag,
            prefecture_id: response.data.prefecture_id,
            home_area_id: response.data.home_area_id,
            home_city_text: response.data.home_city_text,
            home_sub_area_id: response.data.home_sub_area_id,
            home_address: response.data.home_address,
            home_building: response.data.home_building,
            home_room_number: response.data.home_room_number,
            last_use_place_search_type:
              response.data.last_use_place_search_type,
            talent_ng_site_members: response.data.talent_ng_site_members,
            favaliteTagIds: this.$store.state.siteMemberData.favaliteTagIds
              ? this.$store.state.siteMemberData.favaliteTagIds
              : [],
            ngSmokeValue: this.$store.state.siteMemberData.ngSmokeValue
              ? this.$store.state.siteMemberData.ngSmokeValue
              : '',
            ngTattooValue: this.$store.state.siteMemberData.ngTattooValue
              ? this.$store.state.siteMemberData.ngTattooValue
              : '',
            ngBodyPiercingFlag: this.$store.state.siteMemberData
              .ngBodyPiercingFlag
              ? this.$store.state.siteMemberData.ngBodyPiercingFlag
              : 0,
            searchUnderHairType: this.$store.state.siteMemberData
              .searchUnderHairType
              ? this.$store.state.siteMemberData.searchUnderHairType
              : '',
            searchDrinkType: this.$store.state.siteMemberData.searchDrinkType
              ? this.$store.state.siteMemberData.searchDrinkType
              : '',
            recommend_site_member_important_cast_features:
              response.data.recommend_site_member_important_cast_features,
            followCastIds: response.data.follow_cast_ids,
            created_at: response.data.created_at,
          };
          // Store更新
          this.$store.dispatch('notification/hotelAlert', []);
          this.$store.dispatch('siteMemberData/saveSiteMemberData', data);
          this.$store.dispatch('siteMemberData/genreUpdate', {
            search_cast_genre_ids: this.$store.state.siteMemberData
              .search_cast_genre_ids
              ? this.$store.state.siteMemberData.search_cast_genre_ids
              : [1, 2, 3],
          });
          this.$store.dispatch(
            'call/regionValue',
            response.data.region_id
              ? response.data.region_id
              : this.$store.state.call.regionValue
          );
          // 自宅 or ホテル
          if (
            response.data.home_hope_flag === 1 &&
            response.data.last_use_place_search_type === 1
          ) {
            // 自宅希望かつ前回の利用場所検索タイプが自宅の場合のエリア設定
            this.$store.dispatch(
              'siteMemberData/areaIds',
              response.data.home_area_id
            );
            this.$store.dispatch(
              'siteMemberData/subAreaIds',
              response.data.home_sub_area_id
            );
            this.$store.dispatch('siteMemberData/selectPlace', 'selected-home');
            // 自宅希望以外のエリア設定
          } else {
            this.$store.dispatch(
              'siteMemberData/areaIds',
              JSON.parse(response.data.area_ids)
            );
            this.$store.dispatch(
              'siteMemberData/subAreaIds',
              response.data.sub_area_id
            );
            this.$store.dispatch(
              'siteMemberData/selectPlace',
              'selected-hotel'
            );
          }
          // ポイントダイアログ
          if (response.data.givingRegisterPoint > 0) {
            this.openGivingPointDialog(response.data.givingRegisterPoint);
          } else if (response.data.givingLastUsePoint > 0) {
            this.openGivingPointDialog(response.data.givingLastUsePoint);
          }
          // ニックネームダイアログ
          this.nicknameDialog();
          return response.data;
        })
        .catch((error) => {
          this.errorDialog = true;
        })
        .finally(() => {
          this.$store.dispatch('loading/login', false);
        });
    },
    /**
     * 本日出勤・入店３日
     */
    getTodayCasts: function () {
      this.$refs['todayCast'].getTodayCastsData();
    },
    /**
     * 新人
     */
    getNewCasts: function () {
      this.$refs['newCast'].getNewCastData();
    },
    closeRecommendSettingDialog: function () {
      this.recommendSettingDialog = false;
    },
    // /**
    //  * お知らせ通知取得
    //  */
    // getAjaxNoticeNotification: function() {
    //   // this.getNoticeNotification();
    // },
    getSiteMemberPoint: function () {
      //this.$refs['aboutSiteMemberInfo'].getAboutSiteMemberInfo();
    },
    searchCastTop: function () {
      this.$refs['recommendedCast'].getSearchCastTop();
    },
    getCastRankings: function () {
      this.$refs['ranking'].getCastRankingData();
    },
    /**
     *　ポイントダイアログ表示
     */
    openGivingPointDialog: function (setPoint) {
      this.emitPointBn = this.pointBnList.find((v) => v.point == setPoint)
        ? this.pointBnList.find((v) => v.point == setPoint)
        : { point: 0, img: this.pointBn1000 };

      if (this.emitPointBn.point != 0) {
        this.$store.dispatch('dialog/saveGivingPointDialogFlag', true);
      }
    },
    seveGivingPointConfirmed: function (type) {
      if (type == 'succession') {
        this.$store.dispatch('dialog/saveGivingPointDialogFlag', false);
        this.$router.push(`/${$i18n.locale}/mypage`);
      } else {
        this.$store.dispatch('dialog/saveGivingPointDialogFlag', false);
      }

      const accessToken = this.$auth.$storage.getUniversal(
        '_token.auth0',
        true
      );
      const params = {
        email: `${this.$store.state.auth.user.email}`,
      };
      this.$axios
        .post('private/seve-giving-point-confirmed', params, {
          headers: { Authorization: `${accessToken}` },
        })
        .then((response) => {
          //
        })
        .finally(() => {
          //
        });
    },
    /**
     * ニックネーム　ダイアログ
     */
    nicknameDialog: function () {
      // ニックネーム変更モーダル
      if (
        !this.$store.state.siteMemberData.nickname.match(
          /[a-zA-Z0-9ぁ-んーァ-ヴー]/
        )
      ) {
        this.nicknameErrorMessageDialog = true;
      }
    },
  },
};
</script>

<style scoped>
.vanila-ad {
  text-align: center; 
  padding-bottom: 25px; 
  margin: 0;
  margin-left: 10%;
  margin-right: 10%;
}
.content-box-header {
  font-size: 0.9rem;
  line-height: var(--read-font-size-m);
}
@media screen and (min-width: 320px) and (max-width: 1024px) {
  .cast-list__text {
    font-size: var(--read-font-size-m) !important;
  }
  .new-cast-list__text {
    font-size: var(--read-font-size-m) !important;
  }
  .content-box-header {
    font-size: var(--read-font-size-l);
    line-height: var(--read-font-size-l);
    border-left: 0.2rem solid;
    padding-left: 0.6rem;
  }
  .vanila-ad {
    text-align: center; 
    padding-bottom: 30px; 
    margin: 0;
  }
}
.mdi-circle {
  font-size: 10px !important;
}
.v-text-field__details {
  display: none;
}
.v-dialog__content >>> .register-point__dialog {
  box-shadow: none !important;
}
</style>
